import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { push } from 'connected-react-router'
import ProtectedAreaPage from './protectedArea/ProtectedAreaPage'
import Layout from './utils/Layout'
import UserJobRequestSavedList from './userPostSaved/UserJobRequestSavedList'
import UserJobOfferSavedList from './userPostSaved/UserJobOfferSavedList'
import { makeSelectCollection } from '../modules/userSavedSearch/selectors'
import SearchSavedList from '../components/SearchSaved/SearchSavedList'

const ProtectedAreaSavedPost = ({ changeURLParams, searches, match, ...props }) => {
  console.log(searches)
  return (
    <ProtectedAreaPage
      match={match}
      title="Mes recherches sauvegardées"
      breadcrumbsMisc={[
        { label: "Mes recherches sauvegardées" },
      ]}
    >
      <Layout
        title="Mes recherches sauvegardées"
      >
        <div className="columns is-multiline">
          <div className="column is-12">
            <h4 className="is-size-4">Recherches de jobs</h4>
          </div>
          <div className="column is-12">
            <SearchSavedList researches={searches.filter(item => item.type === 'JOB_FILTER_OFFER')} />
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-12">
            <h4 className="is-size-4">Recherches de candidatures</h4>
          </div>
          <div className="column is-12">
            <SearchSavedList researches={searches.filter(item => item.type === 'JOB_FILTER_REQUEST')} />
          </div>
        </div>
      </Layout>
    </ProtectedAreaPage>
  )
}

const mapStateToProps = (state, props) => ({
  searches: makeSelectCollection()(state, props)
})

const mapDispatchToProps = dispatch => ({
  changeURLParams: (params) => dispatch(push({ search: params }))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaSavedPost))
