// protected area
import { createSelector } from 'reselect'

import { makeSelectProtectedAreaLinksConfig } from '../config/selectors'
import { makeSelectUserRoles } from '../user/selectors'

export const makeSelectUserProtectedAreaLinksConfig = () =>
  createSelector(
    [makeSelectProtectedAreaLinksConfig(), makeSelectUserRoles()],
    (protectedAreaConfig, userRoles) =>
      protectedAreaConfig.filter(({ roles = [] }) => roles.reduce((result, requiredRole) => {
        return userRoles.includes(requiredRole) && result
      }, true))
  )
