import React from 'react'
import { Router } from 'react-router-dom'
import { connect } from 'react-redux'

import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'

import CategorySiteSwitch from './CategorySiteSwitch'
import MainSiteSwitch from './MainSiteSwitch'
import { makeSelectIsMain } from './modules/domain/selectors'

import './App.scss'
import 'react-toastify/dist/ReactToastify.css'

// (() => {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/service-worker.js')
//     navigator.serviceWorker.addEventListener('message', (event) => {
//       console.log(event)
//     })
//   }
// })()

const App = ({ history, isMainDomain = true }) => (
  <>
    <Helmet
      titleTemplate='%s - CRIJ Portail'
      defaultTitle='CRIJ Portail'
    >
      <meta name='description' content='CRIJ Portail Application' />
    </Helmet>
    <ToastContainer autoClose={3000} />
    <Router history={history}>
      {isMainDomain ? <MainSiteSwitch /> : <CategorySiteSwitch />}
    </Router>
  </>
)

const mapStateToProps = (state, props) => ({
  isMainDomain: makeSelectIsMain()(state, props)
})

export default connect(mapStateToProps)(App)
