import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Socials from '../../components/Socials/Socials'
import './footer/Footer.scss'
import ControlledLoadableLink from '../../app/utils/ControlledLoadableLink'
import routes from '../../routes.json'
import { makeSelectFooterCollection as makeSelectPartnerFooterCollection } from '../../modules/partner/selectors'
import Image from '../../app/utils/Image'
import Button from '../../components/Button/Button'

const Footer = ({ partners = [], match }) => {
  return (
    <div id={'footer'}>
      {partners && match.path !== routes.PARTNERS && (
        <div className='partners'>
          {partners.map((partner, index) => (
            <a key={index} target={partner.external ? '_blank' : '_self'} href={partner.link}>
              <Image image={partner.logo} />
            </a>
          ))}
          <ControlledLoadableLink
            routeName={routes.PARTNERS}
            to={routes.PARTNERS}
          >
            <Button action={e => e.preventDefault()}>Autres partenaires</Button>
          </ControlledLoadableLink>
        </div>
      )}
      <ul className='links'>
        <li>
          <ControlledLoadableLink
            routeName={routes.LEGAL_NOTICE}
            to={routes.LEGAL_NOTICE}
          >
            Mentions légales
          </ControlledLoadableLink>
        </li>
        <li>
          <ControlledLoadableLink
            routeName={routes.CONTACT}
            to={routes.CONTACT}
          >
            Nous contacter
          </ControlledLoadableLink>
        </li>
      </ul>
      <Socials footer />
      <div id={'rgpd'}>
        <Link to={routes.LEGAL_NOTICE}><p>Mon joli texte qui explique les RGPD et renvoie sur les mentions légales où tout est expliqué.</p></Link>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    partners: makeSelectPartnerFooterCollection()(state, props)
  }
}

export default connect(mapStateToProps)(Footer)
