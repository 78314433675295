import React, { useState } from 'react'
import SearchSavedCard from './SearchSavedCard'

const maxItems = 3

export default ({ researches = [] }) => {
  const [nbItems, setNbItems] = useState({
    max: maxItems
  })
  return (
    <div className={'searchSavedList'}>
      <div className={'research-list'} >
        {
          researches.sort((a, b) => b.id - a.id).slice(0, nbItems.max).map(searchSaved => (
            <SearchSavedCard key={searchSaved.id} searchSaved={searchSaved} />
          ))
        }   
      </div>
      {(nbItems.max < researches.length) && (
        <button className='button is-link is-fullwidth' onClick={() => setNbItems({ ...nbItems, max: nbItems.max + maxItems })}>
          voir plus
        </button>
      )}
    </div>

  )
}
