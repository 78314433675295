import React from 'react'
import queryString from 'qs'

import { push } from 'connected-react-router'
import routes from '../../routes'
import { connect } from 'react-redux'
import { setRedirectTarget } from '../../modules/authenticationRedirect/actions'
import { makeSelectCreating } from '../../modules/userSavedSearch/selectors'
import { createSearch } from '../../modules/userSavedSearch/actions'

const ControlledSaveSearch = ({ location, type, currentUser = null, creating = false,
  redirectToLogin,
  handleSaveSearch
}) => {
  const buildSearch = (search) => {
    const filters = queryString.parse(location.search.substr(1))

    const category = filters.category
      ? `${filters.category.category} ${filters.category.subCategory ? `| ${filters.category.subCategory}` : ''}`
      : 'Offre de job'

    handleSaveSearch({
      type: type,
      category: category,
      filter: JSON.stringify(queryString.parse(location.search.substr(1))),
      isAlert: search.isAlert
    })
  }

  if (!currentUser) {
    return (
      <>
        <a onClick={() => redirectToLogin()}>Créer une alerte</a> |
        <a onClick={() => redirectToLogin()}> Sauvegarder la recherche</a>
      </>
    )
  }
  if (creating) {
    return (
      <>
        <a>Créer une alerte</a> |
        <a> Sauvegarder la recherche</a>
        <span>
          <i className="fa fa-cog fa-spin fa-fw" />
        </span>
      </>
    )
  }

  return (
    <p>
      <a onClick={() => buildSearch({ isAlert: true })}>Créer une alerte</a> |
      <a onClick={() => buildSearch({ isAlert: false })}> Sauvegarder la recherche</a>
    </p>
  )
}

const mapStateToProps = (state, props) => ({
  currentUser: state.user.item,
  creating: makeSelectCreating()(state, props)
})

const mapDispatchToProps = dispatch => ({
  redirectToLogin: (pathname) => {
    dispatch(setRedirectTarget(pathname))
    dispatch(push(routes.LOGIN))
  },
  handleSaveSearch: (search) => dispatch(createSearch(search))
})

const mergeProps = (stateProps, { redirectToLogin, ...dispatchProps }, ownProps) => ({
  ...stateProps, ...dispatchProps, ...ownProps,
  redirectToLogin: () => redirectToLogin(ownProps.location.pathname + ownProps.location.search)
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ControlledSaveSearch)
