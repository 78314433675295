export const STATE_KEY = 'userOrganism'

export const USER_ORGANISM = {
  fetching: `${STATE_KEY}/FETCHING`,
  fetched: `${STATE_KEY}/FETCHED`,
  fetching_error: `${STATE_KEY}/FETCHING_ERROR`,

  loading: `${STATE_KEY}/LOADING`,
  loaded: `${STATE_KEY}/LOADED`,
  loading_error: `${STATE_KEY}/LOADING_ERROR`,

  creating: `${STATE_KEY}/CREATING`,
  created: `${STATE_KEY}/CREATED`,
  creating_error: `${STATE_KEY}/CREATING_ERROR`
}

export const USER_ORGANISM_COLLABORATORS = {
  deleting: `${STATE_KEY}/DELETING`,
  deleted: `${STATE_KEY}/DELETED`,
  deleting_error: `${STATE_KEY}/DELETING_ERROR`,

  promoting: `${STATE_KEY}/PROMOTING`,
  promoted: `${STATE_KEY}/PROMOTED`,
  promoting_error: `${STATE_KEY}/PROMOTING_ERROR`,

  invite_loading: `${STATE_KEY}/INVITE_LOADING`,
  invite_loaded: `${STATE_KEY}/INVITE_LOADED`,
  invite_loading_error: `${STATE_KEY}/INVITE_LOADING_ERROR`
}
