import React from 'react'
import './ChooseAccountType.scss'

export default class ChooseAccountType extends React.Component {
  constructor () {
    super()
    this.state = {}
  }
  componentWillMount () {
    window.scrollTo(0, 0)
  }
  render () {
    return (
      <div id={'chooseAccountType'}>
        <div className={'buttoncontainer'}>
          <a onClick={() => this.props.click('particular')}><button className={'purplebutton'}>Je suis un particulier</button></a>
        </div>
        <div className={'buttoncontainer'}>
          <a onClick={() => this.props.click('pro')}><button className={'purplebutton'}>Je suis un professionnel</button></a>
        </div>
      </div>
    )
  }
}
