import preloadManager from '../../preload'
import { push } from 'connected-react-router'

import { ROUTER } from './constants'

const locationPreLoad = (routeName, dispatch, args) => {
  const { preLoad = () => new Promise(resolve => resolve()) } = preloadManager.find(({ path }) => routeName === path) || {}
  return preLoad(dispatch, args)
}

export function changeLocation (routeName, targetPath, args = {}, onCompleteCallback = () => {}, onErrorCallback = (e) => { console.error(e) }) {
  return dispatch => {
    dispatch({ type: ROUTER.locationChange, targetPath })
    locationPreLoad(routeName, dispatch, args)
      .then(() => {
        dispatch(push(targetPath))
        dispatch({ type: ROUTER.locationChanged })
        onCompleteCallback()
      })
      .catch(onErrorCallback)
  }
}
