import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'

export default ({published, archived = null}) => (
  <ul className="dates">
    {published && <li>
      <span>Publiée le :</span>
      {moment(published).format('Do MMMM YYYY')}
    </li>
    }
    {archived && <li>
      <span>Valable jusqu'au :</span>
      {moment(archived).format('Do MMMM YYYY')}
    </li>}
  </ul>
)