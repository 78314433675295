import React from 'react'
import { Helmet } from 'react-helmet'
import Page from '../Page'

export default ({ match, title = '', breadcrumbsMisc = [], withNavbar = true, children, ...rest}) => (
  <Page className='account' match={match} {...rest}>
    <Helmet>
      <title>ADMIN</title>
    </Helmet>

    {children}
  </Page>
)
