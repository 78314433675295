import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SetOwnerProcess from '../userOrganism/SetOwnerProcess'
import RemoveCollaboratorProcess from '../userOrganism/RemoveCollaboratorProcess'
import { deleteUserToOrganism, setUserOwnerToOrganism, inviteUserToOrganism } from '../../modules/userOrganism/actions'
import InviteCollaboratorProcess from '../userOrganism/InviteCollaboratorProcess'
import Image from '../utils/Image'

const OrganismDetail = ({ organism, currentUser, handleDeleteCollaborator, handleSetOwner, handleInviteCollaborator }) => {
  const isOwner = currentUser.isOrganismAdmin
  return (
    <div className={'columns'}>
      <div id={'contact'} className={'contactInfos column is-one-quarter-desktop'}>
        <div className={'contactHeader'}>Informations :</div>
        <div className={'image is-128x128'}>
          <Image image={organism.logo} className={'is-rounded'} />
        </div>
        <div className={'contactInfo'}><i className={'fas fa-building'} /> {organism.name}</div>
        {organism.webSite && <div className={'contactInfo'}><i className={'fas fa-globe'} /> {organism.webSite}</div>}
        <div className={'contactInfo'}><i className={'fas fa-map-marker-alt'} /> {organism.address} {organism.city} {organism.zipcode}</div>
      </div>
      <div className={`${'offerInfos'} column is-three-quarters-desktop`}>
        <div className={'contentHeader'}>Membres :</div>
        <div id={'offerContent'}>
          <div id={'intro'}>
            <div className="section">
              <div className="column">
                {
                  organism.users.map(collaborator => (
                    <div key={collaborator.id} className="box">
                      <div className="columns">
                        <div className="column is-2">
                          <span>{collaborator.firstName} {collaborator.lastName}</span>

                        </div>
                        <div className="column is-2 is-offset-1">
                          <span>{collaborator.email}</span>

                        </div>
                        {
                          collaborator.isOrganismAdmin
                            ? (
                              <div className="column is-3 is-offset-2">
                                <button className="button is-primary">
                                  <span>Administrateur</span>
                                  <span className="icon is-small">
                                    <i className="fas fa-user-tie" />
                                  </span>
                                </button>
                              </div>
                            )
                            : (
                              <div className="column is-3 is-offset-2">
                                <p className="control">
                                  {
                                    isOwner && currentUser.id !== collaborator.id && (
                                      <SetOwnerProcess
                                        {...{ organism, collaborator, handleSetOwner }}
                                        className="button is-warning"
                                        actionerLabel={<span style={{ paddingLeft: '0.5rem' }}>Rendre propriétaire</span>}
                                      />
                                    )
                                  }
                                </p>
                              </div>
                            )
                        }
                        <div className="column is-2">
                          <p className="control">
                            {
                              isOwner && currentUser.id !== collaborator.id && (
                                <RemoveCollaboratorProcess
                                  {...{ organism, collaborator, handleDeleteCollaborator }}
                                  className="button is-danger"
                                  actionerLabel={<span style={{ paddingLeft: '0.5rem' }}>Supprimer</span>}
                                />
                              )
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }
                {
                  isOwner && (
                    <InviteCollaboratorProcess
                      {...{ organism, handleInviteCollaborator }}
                      className="button is-success"
                      actionerLabel={<span style={{ paddingLeft: '0.5rem' }}>Inviter un collaborateur</span>}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  currentUser: state.user.item,
  organism: state.userOrganism.item
})

const mapDispatchToProps = dispatch => ({
  handleDeleteCollaborator: (collaborator) => dispatch(deleteUserToOrganism(collaborator)),
  handleSetOwner: (collaborator) => dispatch(setUserOwnerToOrganism(collaborator)),
  handleInviteCollaborator: (invitation) => dispatch(inviteUserToOrganism(invitation))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganismDetail))
