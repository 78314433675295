import React from 'react'

import routes from '../../routes.json'

import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import { getArticlesUri } from '../../router'
import Image from '../utils/Image.js'

export default ({ category, article, children, ...props }) => (
  <ControlledLoadableLink
    routeName={routes.ARTICLES}
    to={getArticlesUri(category)}
    {...props}
  >
    <Image image={{ publicPath: '/assets/news-icon.png', altText: 'Actualités' }} className='icon' />
    Actualités
  </ControlledLoadableLink>
)
