import React from 'react'
import { connect } from 'react-redux'
import ModalHandler from '../utils/ModalHandler'
import NewsletterModal from './NewsletterModal'
import { postNewsletter } from '../../actions/newsletter'

const NewsletterRegister = ({handleSubscribe}) => (
  <ModalHandler
    actioner={({ onOpen }) => (
      <a onClick={onOpen}>
        <i className='far fa-envelope' />
        <br/>
        Newsletter
      </a>
    )}
    modal={props => <NewsletterModal {...props} onConfirmation={handleSubscribe} />}
  />
)

const mapDispatchToProps = (dispatch) => ({
  handleSubscribe: (newsletterForm, onClose) => dispatch(postNewsletter(newsletterForm, onClose)),
})

export default connect(() => ({}), mapDispatchToProps)(NewsletterRegister)
