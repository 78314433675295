import React from 'react'
import { Helmet } from 'react-helmet'
import Page from '../Page'
import ControlledBreadcrumbs from '../utils/ControlledBreadcrumbs'
import routes from '../../routes'
import ProtectedAreaNavBar from './ProtectedAreaNavBar'

export default ({ match, title = 'Espace perso', breadcrumbsMisc = [], withNavbar = true, children, ...rest}) => (
  <Page className='account' match={match} {...rest} isPersonalSpace={true}>
    <Helmet>
      <title>{title}</title>
    </Helmet>

    {withNavbar && <ProtectedAreaNavBar match={match} />}
    <ControlledBreadcrumbs {...{misc: [
        { label: 'Espace perso', routeName: routes.PROTECTED_AREA, to: routes.PROTECTED_AREA },
        ...breadcrumbsMisc
      ]}} woSearchBar />

    {children}
  </Page>
)
