import React from 'react'
import * as yup from 'yup'
import WithConsentTextField from '../protectedAreaProfile/protectedAreaProfileForm/WithConsentTextField'
import LogoInput from '../protectedAreaProfile/protectedAreaProfileForm/LogoInput'
import ArticleCategoryField from './articleFilter/ArticleCategoryField'

export default (categoryList, sectionList) => [
  {
    group: 'date',
    label: 'Date de début',
    name: 'publicationStartAt',
    type: 'date',
    className: 'is-6',
    validation: {
      required: true
    }
  },
  {
    group: 'date',
    label: 'Date de fin',
    name: 'publicationEndAt',
    type: 'date',
    className: 'is-6',
    validation: {
      required: true
    }
  },
  // {
  //   group: 'default',
  //   label: 'Catégorie',
  //   name: 'category',
  //   type: 'choice',
  //   choices: categoryList.map(({ id, title }) => ({ value: id, label: title })),
  //   validation: {
  //     type: yup.string(),
  //     required: true
  //   }
  // },
  // {
  //   group: 'default',
  //   label: 'Rubrique',
  //   name: 'section',
  //   type: 'choice',
  //   choices: sectionList.map(({ id, title }) => ({ value: id, label: title })),
  //   validation: {
  //     type: yup.string(),
  //     required: true
  //   }
  // },
  {
    group: 'default',
    name: 'custom',
    label: 'Catégorie',
    type: 'custom',
    required: true,
    sectionList: sectionList,
    categoryList: categoryList,
    Component: ArticleCategoryField,
    validation: {
      type: yup.object().shape({
        category: yup.string().required(),
        subCategory: yup.string()
      }),
    },
    callback: (filterValue, result) => {
      console.log(filterValue)
      console.log(result)
      if(filterValue.category) {
        if(filterValue.category !== result.category) {
          return false;
        }
        if(filterValue.subCategory && filterValue.subCategory !== result.subCategory) {
          return false;
        }
      }

      return true;
    }
  },
  {
    group: 'default',
    label: 'Titre',
    name: 'title',
    validation: {
      type: yup.string(),
      required: true
    }
  },
  {
    group: 'default',
    label: 'Introduction',
    name: 'introduction',
    type: 'textarea',
    validation: {
      type: yup.string(),
      required: true
    }
  },
  {
    group: 'default',
    label: 'Texte',
    name: 'content',
    type: 'textarea',
    validation: {
      type: yup.string(),
      required: true
    }
  },

]
