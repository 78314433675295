import React from 'react'
import { connect } from 'react-redux'
import { makeSelectResourceFromContent } from '../../modules/section/selectors.js'
import ContentLink from './ContentLink.js'
import './Content.scss'
import backgroundImageHelper from '../utils/backgroundImageHelper.js'

const ContentItem = ({ content, section, ...props }) => (
  <article className='contentItem'>
    <ContentLink {...{ content }}>
      <h4 className='section' style={section.color ? { backgroundColor: section.color } : {}}>
        {section.title}
      </h4>
    </ContentLink>
    <div className='thumb'>
      <ContentLink {...{ content }}>
        <div className='image' style={backgroundImageHelper(section.thumbnail)} />
      </ContentLink>
    </div>
    <ContentLink {...{ content }}>
      <h2>{content.title}</h2>
    </ContentLink>
  </article>
)

const mapStateToProps = (state, props) => {
  return {
    section: makeSelectResourceFromContent()(state, props)
  }
}

export default connect(mapStateToProps)(ContentItem)
