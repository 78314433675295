import { USER_ARTICLE, USER_ARTICLES } from './constants'

// initial state
export const initialState = {
  loading: true,
  updating: false,
  creating: false,
  error: false,
  items: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_ARTICLES.fetching:
      return {
        ...state,
        loading: true,
        error: false
      }
    case USER_ARTICLES.fetched:
      return {
        ...state,
        loading: false,
        error: false,
        items: action.data
      }
    case USER_ARTICLES.fetching_error:
      return {
        ...state,
        loading: false,
        error: true
      }
    case USER_ARTICLE.archiving:
      return {
        ...state,
        loading: true,
        error: false
      }
    case USER_ARTICLE.archived:
      return {
        ...state,
        loading: false,
        error: false,
        items: state.items.filter(({ id }) => id !== action.data.id)
      }
    case USER_ARTICLE.archiving_error:
      return {
        ...state,
        loading: false,
        error: true
      }

    case USER_ARTICLE.publishing:
      return {
        ...state,
        loading: true,
        error: false
      }
    case USER_ARTICLE.published:
      return {
        ...state,
        loading: false,
        error: false,
        items: state.items.map(item => item.id === action.data.id
          ? action.data : item)
      }
    case USER_ARTICLE.publishing_error:
      return {
        ...state,
        loading: false,
        error: true
      }

    case USER_ARTICLE.updating:
      return {
        ...state,
        updating: true,
        error: false
      }
    case USER_ARTICLE.updated:
      return {
        ...state,
        updating: false,
        error: false,
        items: state.items.map(item => item.id === action.data.id
          ? action.data : item)
      }
    case USER_ARTICLE.updating_error:
      return {
        ...state,
        updating: false,
        error: true
      }
    case USER_ARTICLE.creating:
      return {
        ...state,
        creating: true,
        error: false
      }
    case USER_ARTICLE.created:
      return {
        ...state,
        creating: false,
        error: false,
        items: state.items.concat([action.data])
      }
    case USER_ARTICLE.creating_error:
      return {
        ...state,
        creating: false,
        error: true
      }
  }

  return state
}
