import React from 'react'

import ConfirmationModalHandler from '../utils/ConfirmationModalHandler'
import ModalPortal from '../utils/modal/ModalPortal'
import Modal from '../utils/modal/Modal'

const RemoveCollaboratorModal = ({ onConfirmation, onClose, children }) => (
  <ModalPortal>
    <Modal onClose={onClose}>
      <div className='box has-text-centered'>
        {children}
        <br />
        <div className='buttons is-centered are-medium'>
          <button
            className='button is-danger'
            type='button'
            onClick={() => {
              onConfirmation()
              onClose()
            }}
          >
            <span>Supprimer</span>
          </button>
          <button className='button' type='button' onClick={onClose}>
            <span>Annuler</span>
          </button>
        </div>
      </div>
    </Modal>
  </ModalPortal>
)

/**
 * Display multi modal if published offer, confirmation only otherwise
 */
export default ({ collaborator, handleDeleteCollaborator, className = 'tag is-danger', actionerLabel = '' }) => (
  <MyConfirmationModalHandler {...{
    collaborator,
    className,
    actionerLabel,
    onConfirmation: () => handleDeleteCollaborator(collaborator)
  }} />
)

const MyConfirmationModalHandler = ({ collaborator: { firstName, lastName }, onConfirmation, className = 'tag is-success', actionerLabel = '' }) => (
  <ConfirmationModalHandler
    {...{ actionerLabel, className, onConfirmation }}
    Modal={RemoveCollaboratorModal}
    modalChildren={(
      <>
        <h5 className='is-size-4'>
          Êtes-vous sûr de vouloir supprimer {firstName} {lastName} de votre organisme ?
        </h5>
      </>
    )}
  />
)
