import React from 'react'
import moment from 'moment'

import routes from '../../routes.json'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import ModalHandler from '../utils/ModalHandler'
import RemoveModal from './UserPostJobCard/RemoveModal'
import FollowUpModal from './UserPostJobCard/FollowUpModal'
import { getUserPostJobOfferEditUri } from '../../router'
import DeleteUserPostProcess from './DeleteUserPostProcess'

const StatusTag = ({status}) => {
  switch (status) {
    case 'published':
      return <span className="tag is-success">Publiée</span>;
    case 'waiting':
      return <span className="tag is-info">En attente</span>;
  }
  return '';
}

export default ({handleArchivePost, post}) => (
  <div className="actions buttons">
    <div className="tags are-medium has-addons">
      <StatusTag {...post} />
      {/* edit button */}
      {post.status === 'drafted' && (<>
          <ControlledLoadableLink
            className="tag is-warning"
            routeName={routes.PROTECTED_AREA_JOB_OFFER_EDIT}
            to={getUserPostJobOfferEditUri(post.id)}
          >
            <span style={{paddingRight: '0.5rem'}}>Brouillon</span>
            <i className='far fa-edit' />
          </ControlledLoadableLink>
        </>
      )}

      {/* delete modal */}
      <DeleteUserPostProcess {...{post, handleArchivePost}} />
    </div>
  </div>
)