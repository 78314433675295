import React from 'react'

import ControlledLoadableLink from './ControlledLoadableLink'
import '../breadcrumbs/Breadcrumbs.scss'
import classnames from 'classnames'

export default ({ items = [], woSearchBar = false }) => (
  <div className={classnames('breadcrumbs', {['wo-searchBar']: woSearchBar})}>
    <div>
      {items.map(({ to = '', routeName, label = '', ...props }, index) => {
        // last el is always span
        if (index === (items.length - 1) || to.length === 0) {
          return <span key={label}>{label}</span>
        }
        return (
          <ControlledLoadableLink key={to} {...{ to, routeName, label, ...props }}>
            {label}
          </ControlledLoadableLink>
        )
      })}
    </div>
  </div>
)
