import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr'

import { Helmet } from 'react-helmet'
import { makeSelectResource as makeSelectCategoryResource } from '../modules/category/selectors'
import { makeSelectResourceForArticle as makeSelectSectionResourceForArticle } from '../modules/section/selectors'
import { makeSelectResource as makeSelectArticleResource } from '../modules/article/selectors'

import './article/Article.scss'
import ControlledBreadcrumbs from './utils/ControlledBreadcrumbs'
import ControlledNavBar from './utils/ControlledNavBar'
import { getArticlesUri } from '../router'

import routes from '../routes.json'
import Page from './Page'
import ContentText from './contents/ContentText'
import SearchBar from './utils/SearchBar'
import Layout from './utils/Layout'
import InfoUrl from '../components/InfoUrl/InfoUrl'

const ArticleParentInfo = ({ category, section }) => {
  const parent = section || category
  let text = 'Catégorie : '
  if (section) {
    text = 'Rubrique : '
  }
  return (
    <div className='parent_badge' style={parent.color ? { backgroundColor: parent.color } : {}}>
      {text} <strong>{parent.title}</strong>
    </div>
  )
}

export const ArticlePage = ({ match, category, section = null, article }) => (
  <Page match={match} className='page page-content'>
    <Helmet>
      <title>Articles - {category.title}</title>
    </Helmet>

    <ControlledNavBar selectedCategory={category} selectedSection={{ slug: 'actualites' }} match={match} />
    <ControlledBreadcrumbs {...{ category, misc: [{ label: 'Actualités', routeName: routes.ARTICLES, to: getArticlesUri(category) }, { label: article.title }] }} />
    <SearchBar />

    <Layout title={article.title} background={article.background}>
      <p dangerouslySetInnerHTML={{ __html: article.introduction }} />
      <ContentText title={''} text={article.content} />

      <div className='publicationData'>
        <div>
          {article.author && <>
            <h4 className='author'>
              Par : <strong>{article.author.name}</strong>
            </h4>
            <br />
          </>}
          <span className='publicationDate'>Publié le : {moment(article.created).format('Do MMMM YYYY')}</span>
          {moment(article.created) !== moment(article.updated) && <>
            <br />
            <span className='updateDate'>
              Dernière modification le : {moment(article.updated).format('Do MMMM YYYY')}
            </span>
          </>}
        </div>
        <ArticleParentInfo category={category} section={section} />
      </div>
      {/* <div className={styles.btnRetour}>
        <GoBack style={{ color: colorStyle }}>Retour</GoBack>
      </div> */}
    </Layout>
    <InfoUrl info={category.footer} />
  </Page>
)

const mapStateToProps = (state, props) => ({
  category: makeSelectCategoryResource()(state, props),
  section: makeSelectSectionResourceForArticle()(state, props),
  article: { ...makeSelectArticleResource()(state, props), author: { name: 'foo' } }
})

export default connect(mapStateToProps)(ArticlePage)
