import React, { useState } from 'react'
import { connect } from 'react-redux'
import { reduce } from 'lodash'

import ProtectedAreaPage from '../protectedArea/ProtectedAreaPage'
import Layout from '../utils/Layout'
import buildForm from '../form/buildForm'
import formSchema from './formSchema'
import { makeSelectCollection as makeSelectCollectionCategory } from '../../modules/category/selectors'
import { makeSelectCollection as makeSelectCollectionSection } from '../../modules/section/selectors'
import getFormHandler from '../../modules/formHelper'
import moment from 'moment'
import { createUserArticle } from '../../modules/userArticle/actions'

const ProtectedAreaArticleCreatePage = ({ user, categoryList, sectionList, match, handleSubmit, ...props }) => {
  const formSchemaRole = formSchema(categoryList, sectionList).filter(({ roles }) => roles ? user.roles.some(r => roles.includes(r)) : true)
  console.log(formSchemaRole)
  const formHandler = getFormHandler(formSchemaRole)
  const [inputs, setInputs] = useState(formHandler.initializeInputs({
    publicationStartAt: moment(),
    publicationEndAt: moment().add(1, 'months')
  }))
  const hasErrors = reduce(inputs, (all, { errors }) => [...all, ...errors], []).length
  const [isDirty, setDirty] = useState(false);
  const setInput = (name, value) => {
    setDirty(false);
    formHandler.setInput(setInputs, name, value);
  }

 
  const beforeSubmit = (article) => {
    if (article.custom.value.sectionTitle) {
      const sectionId = sectionList.find(item => item.title === article.custom.value.sectionTitle).id
      const categoryId = categoryList.find(item => item.title === article.custom.value.category).id
      article.section = {
        value: sectionId,
        errors: []
      }
      article.category = {
        value: categoryId,
        errors: []
      }
    }
    else {
      const categoryId = categoryList.find(item => item.title === article.custom.value.category).id
      article.category = {
        value: categoryId,
        errors: []
      }
      article.section = {
        value: null,
        errors: []
      }
    }
    handleSubmit(article)
  }

  return (
    <ProtectedAreaPage
      match={match}
      title='Déposer une actualité'
      breadcrumbsMisc={[
        { label: 'Déposer une actualité' }
      ]}
    >
      <Layout
        title='Déposer une actualité'
      >
        <div className='profile-page columns is-multiline'>
          <form className="columns is-multiline is-variable is-1">
            <div className="column is-12">
              <div className="columns is-multiline is-variable is-1">
                {buildForm(
                  formSchema(categoryList, sectionList)
                    .filter(({group}) => group === 'date')
                    .map(fieldConfig => ({className: 'is-12', ...fieldConfig})),
                  inputs,
                  setInput,
                  isDirty
                )}
              </div>
              <div className="columns is-multiline is-variable is-1">
                {buildForm(
                  formSchema(categoryList, sectionList)
                    .filter(({group}) => group === 'default')
                    .map(fieldConfig => ({className: 'is-12', ...fieldConfig})),
                  inputs,
                  setInput,
                  isDirty
                )}
              </div>
            </div>
            <div className="field column is-12">
              <div className="control">
                <button
                  disabled={hasErrors}
                  className="button is-primary is-fullwidth"
                  onClick={e => {
                    e.preventDefault()
                    beforeSubmit(inputs)
                  }}>
                  {'Enregistrer'}
                </button>
                <label>{hasErrors ? 'Veuillez remplir tous les champs obligatoires marqués d\'un «*»' : '' }</label>
              </div>
            </div>
          </form>
        </div>
      </Layout>
    </ProtectedAreaPage>
  )
}

const mapStateToProps = (state, props) => ({
  user: state.user.item,
  categoryList: makeSelectCollectionCategory()(state, props),
  sectionList: makeSelectCollectionSection()(state, props)
})

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleSubmit: (article) => dispatch(createUserArticle(article))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaArticleCreatePage)
