// import { DOMAIN } from './constants'

// initial state
export const initialState = {
  isMain: false
}

export default function (state = initialState, action) {
  return state
}
