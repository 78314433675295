import { requestWithToken } from '../apiHelper'
import { toast } from 'react-toastify'
import { USER_ARTICLES, USER_ARTICLE } from './constants'
import routes from '../../routes'
import { push } from "connected-react-router"
import { getUser } from '../user/actions'
import { getArticleUri } from '../../router'
import { redirect, setRedirectTarget } from '../authenticationRedirect/actions'
import { makeSelectRedirectionTarget } from '../authenticationRedirect/selectors'

const endpoint = 'user/articles';

export const getUserArticles = () =>
  (dispatch, getState) => {
    dispatch({ type: USER_ARTICLES.fetching })
    return requestWithToken({ endpoint }, getState)
      .then(res => res.data)
      .then(({ data }) => {
        dispatch({ type: USER_ARTICLES.fetched, data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_ARTICLES.fetching_error, error: error })
      })
  }

export const archiveUserArticle = (article) =>
  (dispatch, getState) => {
    dispatch({ type: USER_ARTICLE.archiving })
    return requestWithToken({
      endpoint: `${endpoint}/${article.id}/archive`,
      method: 'PATCH'
    }, getState)
      .then(res => res.data)
      .then(({ data }) => {
        toast.success(`L'article a été supprimée`)
        dispatch(push(routes.PROTECTED_AREA_ARTICLE_LIST))
        dispatch({ type: USER_ARTICLE.archived, data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_ARTICLE.archiving_error, error: error })
      })
  }

export const publishUserArticle = ({ id }) =>
  (dispatch, getState) => {
    dispatch({ type: USER_ARTICLE.publishing })
    return requestWithToken({
      endpoint: `${endpoint}/${id}/publish`,
      method: 'PATCH'
    }, getState)
      .then(res => res.data)
      .then(({ data }) => {
        toast.success(`L'article est en attente de validation`)
        dispatch(push(routes.PROTECTED_AREA_ARTICLE_LIST))
        dispatch({ type: USER_ARTICLE.published, data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_ARTICLE.publishing_error, error: error })
      })
  }

export const updateUserArticle = (article) =>
  (dispatch, getState) => {
    dispatch({ type: USER_ARTICLE.updating })
    return requestWithToken({
      endpoint: `${endpoint}/${article.id}`,
      method: 'PUT',
      data: article
    }, getState)
      .then(res => res.data)
      .then((data) => {
        toast.success(`L'article a été modifiée`)
        dispatch({ type: USER_ARTICLE.updated, data })
        // redirect to preview
        dispatch(push(routes.PROTECTED_AREA_ARTICLE_LIST))
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_ARTICLE.updating_error, error: error })
      })
  }

export const createUserArticle = (article) =>
  (dispatch, getState) => {
    dispatch({ type: USER_ARTICLE.creating })
    console.log(article)
    return requestWithToken({
      endpoint: `${endpoint}`,
      method: 'POST',
      data: article
    }, getState)
      .then(res => res.data)
      .then((data) => {
        console.log(article)
        console.log(data)
        toast.success(`Votre article à bien été crée`)
        dispatch({ type: USER_ARTICLE.created, data })
        dispatch(push(routes.PROTECTED_AREA_ARTICLE_LIST))
        // redirect to preview
        // dispatch(push(getUserArticlePreviewUri(data.id)))
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_ARTICLE.creating_error, error: error })
      })
  }
