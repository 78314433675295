import { USER_POST_JOB_SAVED_REQUESTS, USER_POST_JOB_SAVED_REQUEST } from './constants'

export const initialState = {
  loading: true,
  saving: false,
  error: false,
  items: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_POST_JOB_SAVED_REQUESTS.fetching:
      return {
        ...state,
        loading: true,
        error: false
      }
    case USER_POST_JOB_SAVED_REQUESTS.fetched:
      return {
        ...state,
        items: action.data,
        loading: false,
        error: false
      }
    case USER_POST_JOB_SAVED_REQUESTS.fetching_error:
      return {
        ...state,
        loading: false,
        error: true
      }

    case USER_POST_JOB_SAVED_REQUEST.save:
      return {
        ...state,
        items: [...state.items, action.payload]
      }
    case USER_POST_JOB_SAVED_REQUEST.lose:
      return {
        ...state,
        items: state.items.filter(item => item !== action.payload)
      }
    case USER_POST_JOB_SAVED_REQUEST.saving:
      return {
        ...state,
        saving: true
      }
    case USER_POST_JOB_SAVED_REQUEST.saved:
      return {
        ...state,
        saving: false
      }
    case USER_POST_JOB_SAVED_REQUEST.saving_error:
      return {
        ...state,
        saving: false,
        error: true
      }
  }
  return state
}
