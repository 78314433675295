import React from 'react'
import TextInput from '../../form/input/TextInput'
import FieldWrapper from '../../form/FieldWrapper'
import CheckboxInput from '../../form/input/CheckboxInput'

export default ({
  name,
  errors,
  setInput = (name, value) => {},
  selectedValue: {value, consent},
  className,
  label,
  consentLabel,
  required,
  hiddenConsent = false,
  ...props
}) => (
    <FieldWrapper {...{
      key: name,
      id: 'input-'+ name,
      className,
      label,
      required,
      errors
    }}>
      <TextInput {...props} {...{
        name,
        errors,
        value,
        id: 'input-'+ name,
        className: errors.length ? 'is-danger' : '',
        onChange: (e) => setInput(name, {consent, value: e.target.value}),
      }} />
      <div className="control is-expanded" style={{display: hiddenConsent ? 'none' : 'block'}}>
        <CheckboxInput
          {...{
            name: 'consent'+ name,
            id: 'input-consent'+ name,
            label: consentLabel,
            value: !!consent,
            setInput: (inputName, inputValue) => setInput(name, {consent: !!inputValue, value})
          }}
        />
      </div>
    </FieldWrapper>
  )