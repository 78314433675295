import request from '../modules/apiHelper'
import { toast } from 'react-toastify'

export const POST_NEWSLETTER = 'POST_NEWSLETTER'
export const POST_NEWSLETTER_SUCCESS = 'POST_NEWSLETTER_SUCCESS'
export const POST_NEWSLETTER_ERROR = 'POST_NEWSLETTER_ERROR'

const endpoint = 'newslettersubscriptions';

export function postNewsletter (newsletterForm, onSuccess = () => {}) {
  return dispatch => {
    dispatch({ type: 'POST_NEWSLETTER' })
    return request({endpoint, method: 'POST', data: newsletterForm})
      .then(res => res.data)
      .then(res => {
        dispatch({ type: 'POST_NEWSLETTER_SUCCESS', data: res.data })
        toast.success('Vous êtes inscrits à notre newsletter')
        onSuccess()
      })
      .catch(error => {
        dispatch({ type: 'POST_NEWSLETTER_ERROR', data: error })
        toast.error(error.response.data.invalidParams[0].error, { toastId: 'Newsletter.error' })
      })
  }
}
