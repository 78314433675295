import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { makeSelectCollection as makeSelectUserOfferCollection } from '../modules/userPostJobOffers/selectors'

import PostList from './post/PostList'
import routes from '../routes'
import { getUserPostJobOfferPreviewUri } from '../router'
import UserPostJobCard from './userPostJob/UserPostJobCard'

import ProtectedAreaPostJobListPage from './userPostJob/ProtectedAreaPostJobListPage'
import { archivePostOffer } from '../modules/userPostJobOffers/actions'

const ProtectedAreaPostJobOfferListPage = ({ match, results = [], handleArchivePost }) => {
  return (
    <ProtectedAreaPostJobListPage
      match={match}
    >
      {!results.length
        ? (
          <>
            <h4>Vous n'avez aucune annonce.</h4>
          </>
        ) : (
          <>
            <PostList
              posts={results}
              CardComponent={UserPostJobCard}
              getCardLinkProps={
                (post) => ({
                  routeName: routes.PROTECTED_AREA_JOB_OFFER_PREVIEW,
                  to: getUserPostJobOfferPreviewUri(post.id)
                })
              }
              handleArchivePost={handleArchivePost}
            />
          </>
        )
      }
    </ProtectedAreaPostJobListPage>
  )
}

const mapStateToProps = (state, props) => ({
  results: makeSelectUserOfferCollection()(state, props),
})

const mapDispatchToProps = (dispatch) => ({
  handleArchivePost: (post, archivingData) => dispatch(archivePostOffer(post, archivingData))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaPostJobOfferListPage))
