import React, { useState } from 'react'
import { connect } from 'react-redux'
import { reduce } from 'lodash'

import ProtectedAreaPage from '../protectedArea/ProtectedAreaPage'
import Layout from '../utils/Layout'
import buildForm from '../form/buildForm'
import formSchema from './formSchema'
import { makeSelectCollection as makeSelectCollectionCategory } from '../../modules/category/selectors'
import { makeSelectCollection as makeSelectCollectionSection } from '../../modules/section/selectors'
import getFormHandler from '../../modules/formHelper'

const ProtectedAreaArticlePreviewPage = ({ user, categoryList, sectionList, match, handleSubmit, ...props }) => {
  const formSchemaRole = formSchema(categoryList, sectionList).filter(({ roles }) => roles ? user.roles.some(r => roles.includes(r)) : true)
  const formHandler = getFormHandler(formSchemaRole)
  const [inputs, setInputs] = useState(formHandler.initializeInputs(user))
  const hasErrors = reduce(inputs, (all, { errors }) => [...all, ...errors], []).length
  const setInput = (name, value) => formHandler.setInput(setInputs, name, value)

  return (
    <ProtectedAreaPage
      match={match}
      title='Déposer une actualité'
      breadcrumbsMisc={[
        { label: 'Déposer une actualité' }
      ]}
    >
      <Layout
        title='Déposer une actualité'
      >
      </Layout>
    </ProtectedAreaPage>
  )
}

const mapStateToProps = (state, props) => ({
  user: state.user.item,
  categoryList: makeSelectCollectionCategory()(state, props),
  sectionList: makeSelectCollectionSection()(state, props)
})

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleSubmit: (article) => console.log(article)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaArticlePreviewPage)
