import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import routes from '../../routes'
import Tabs from '../utils/Tabs'
import React from 'react'

export default ({match}) => (
  <>
    <div className="column is-8">
      <Tabs items={[
        {
          isActive: match.path.substr(0, routes.PROTECTED_AREA_ARTICLE_LIST.length) === routes.PROTECTED_AREA_ARTICLE_LIST,
          item: <a>Mes Articles</a>
        }
      ]} />
    </div>
    <div className="column is-4 has-text-right" style={{marginTop: '0.7rem'}}>
      <ControlledLoadableLink
        routeName={routes.PROTECTED_AREA_ARTICLE_CREATE}
        to={routes.PROTECTED_AREA_ARTICLE_CREATE}
        className="button is-primary is-fullwidth"
      >
        +
        Déposer votre article
      </ControlledLoadableLink>
    </div>
  </>
)