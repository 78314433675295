import React from 'react'
import './regionMap/RegionMap.scss'

export const departmentList = {
  21: 'Côte-d\'Or',
  25: 'Doubs',
  70: 'Haute-Saône',
  39: 'Jura',
  58: 'Nièvre',
  71: 'Saône-et-Loire',
  90: 'Territoire de Belfort',
  89: 'Yonne',
  0: 'Hors région'
};

export default ({ onClick = selectedDepartment => {} }) => (
  <div className='region-map'> 
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 574.99 435.51'>
      <defs />
      <g id='MapPart8' onClick={() => onClick(58)}>
        <polygon id='Area58' className='cls-1' points='39.84 317.77 39.84 325.16 60.84 339.15 72.89 332.55 82.62 337.99 90 333.32 98.94 342.26 103.99 339.15 118.77 325.16 131.21 316.6 145.21 323.6 163.87 316.6 173.59 306.88 171.26 297.94 174.75 292.89 168.93 257.89 175.93 243.51 187.59 230.68 188.74 222.9 177.48 217.46 173.59 202.69 173.59 199.57 166.99 199.57 159.98 207.74 154.92 202.69 154.15 194.91 150.27 192.96 144.43 194.52 140.54 189.08 122.67 194.52 93.89 171.97 87.66 171.19 66.67 184.02 22.73 162.25 6.41 171.19 7.96 176.24 13.02 185.96 10.69 209.29 24.29 227.18 36.74 283.17 39.84 310.77 39.84 317.77'
        />
        <polygon className='cls-2' points='55.42 274.67 50.79 274.67 50.79 279.31 55.42 279.31 55.42 274.67 55.42 274.67'
        />
        <path d='M68.27,288.31v-5.39H69l1.73,2.73a15.92,15.92,0,0,1,1,1.75h0c-.06-.72-.08-1.38-.08-2.22v-2.25h.66v5.39h-.7l-1.72-2.74a18.08,18.08,0,0,1-1-1.79h0c0,.68.06,1.33.06,2.23v2.3Z'
          transform='translate(-10.45 -9)' />
        <path d='M76.33,285.78h-2.1v1.94h2.34v.59h-3v-5.39h2.91v.58H74.23v1.71h2.1Z'
          transform='translate(-10.45 -9)' />
        <path d='M78.73,288.31,77,282.92h.75l.84,2.65c.23.73.43,1.39.58,2h0c.15-.63.38-1.3.62-2l.91-2.66h.74l-1.92,5.39Z'
          transform='translate(-10.45 -9)' />
        <path d='M84.8,285.78H82.71v1.94H85v.59H82v-5.39h2.91v.58H82.71v1.71H84.8Z'
          transform='translate(-10.45 -9)' />
        <path d='M86,283a7.36,7.36,0,0,1,1.33-.11,2.24,2.24,0,0,1,1.56.44,1.35,1.35,0,0,1,.42,1,1.41,1.41,0,0,1-1,1.38v0a1.37,1.37,0,0,1,.79,1.09,8.21,8.21,0,0,0,.42,1.48h-.72a6.7,6.7,0,0,1-.36-1.29c-.16-.74-.45-1-1.08-1h-.66v2.34H86Zm.69,2.46h.71c.75,0,1.22-.41,1.22-1s-.51-1-1.24-1a3,3,0,0,0-.69.06Z'
          transform='translate(-10.45 -9)' />
        <path d='M90.15,287.46a2.49,2.49,0,0,0,1.25.35c.71,0,1.13-.37,1.13-.92s-.29-.79-1-1.07c-.88-.31-1.42-.77-1.42-1.53a1.56,1.56,0,0,1,1.74-1.46,2.39,2.39,0,0,1,1.19.27l-.19.56a2.18,2.18,0,0,0-1-.25c-.74,0-1,.44-1,.8,0,.51.33.76,1.07,1,.92.36,1.38.8,1.38,1.59s-.62,1.55-1.89,1.55A2.68,2.68,0,0,1,90,288Z'
          transform='translate(-10.45 -9)' />
      </g>
      <g id='MapPart7' onClick={() => onClick(71)}>
        <polygon id='Area71' className='cls-1' points='333.39 257.89 321.34 260.23 314.73 262.17 305.01 260.62 294.5 266.83 284.79 268.01 256.4 280.83 241.64 264.51 242.02 260.62 238.52 258.67 226.47 257.89 196.14 238.45 188.74 239.23 179.42 244.67 175.93 243.51 168.93 257.89 174.75 292.89 171.26 297.94 173.59 306.88 163.87 316.6 145.21 323.6 131.21 316.6 118.77 325.16 133.15 345.76 143.66 362.87 165.43 374.14 168.93 399.81 163.09 411.08 165.43 418.85 164.65 424.3 171.26 426.64 175.93 434.02 189.92 428.58 208.59 429.75 213.25 434.8 230.35 418.85 228.79 413.81 226.47 405.64 232.3 399.81 241.64 407.19 245.12 399.81 250.58 398.64 253.3 404.08 257.19 404.86 260.68 395.14 269.63 396.69 272.74 401.36 271.18 404.86 275.08 409.92 274.29 414.58 282.07 424.3 302.67 355.09 325.61 350.43 327.94 348.87 350.5 364.42 351.27 358.59 362.93 352.76 361.38 348.09 355.15 345.76 353.99 337.99 360.21 333.32 358.65 310.77 355.55 309.22 348.94 304.16 351.27 299.11 345.83 293.66 345.83 289.38 360.21 280.83 358.65 276.56 356.72 277.33 349.72 272.67 344.27 269.56 335.33 265.28 333.39 257.89'
        />
        <polygon className='cls-2' points='285.42 389.34 280.79 389.34 280.79 393.98 285.42 393.98 285.42 389.34 285.42 389.34'
        />
        <path d='M267.09,398.94c0-.75-.09-1.65-.08-2.32h0c-.18.63-.4,1.3-.68,2l-.95,2.62h-.52l-.88-2.57c-.25-.76-.47-1.46-.62-2.09h0c0,.67-.05,1.57-.1,2.38l-.14,2.31h-.67l.38-5.39h.89l.91,2.61c.23.66.41,1.25.55,1.81h0c.14-.54.33-1.13.57-1.81l1-2.61h.89l.33,5.39h-.68Z'
          transform='translate(-10.45 -9)' />
        <path d='M269.77,399.61l-.56,1.7h-.72l1.83-5.39h.84l1.84,5.39h-.74l-.58-1.7Zm1.26-4.89.84.91h-.59l-.5-.55h0l-.51.55h-.57l.82-.91Zm.51,4.35-.53-1.55c-.12-.35-.2-.67-.28-1h0c-.08.32-.17.64-.27,1l-.53,1.56Z'
          transform='translate(-10.45 -9)' />
        <path d='M277.44,401.13a3.42,3.42,0,0,1-1.42.26,2.52,2.52,0,0,1-2.67-2.73,2.68,2.68,0,0,1,2.82-2.83,3,3,0,0,1,1.28.24l-.17.57a2.5,2.5,0,0,0-1.09-.22,2,2,0,0,0-2.1,2.22,2,2,0,0,0,2.07,2.16,2.76,2.76,0,0,0,1.14-.22Z'
          transform='translate(-10.45 -9)' />
        <path d='M282.75,398.56a2.53,2.53,0,0,1-2.5,2.84,2.48,2.48,0,0,1-2.42-2.74,2.56,2.56,0,0,1,2.5-2.83A2.45,2.45,0,0,1,282.75,398.56Zm-4.18.09c0,1.15.62,2.18,1.72,2.18s1.73-1,1.73-2.24c0-1.07-.56-2.19-1.72-2.19S278.57,397.46,278.57,398.65Z'
          transform='translate(-10.45 -9)' />
        <path d='M283.66,401.31v-5.39h.76l1.73,2.73a15.73,15.73,0,0,1,1,1.75h0a22.13,22.13,0,0,1-.08-2.22v-2.25h.65v5.39H287l-1.71-2.74a16.38,16.38,0,0,1-1-1.79h0c0,.68.05,1.33.05,2.23v2.3Z'
          transform='translate(-10.45 -9)' />
      </g>
      <g id='MapPart6' onClick={() => onClick(39)}>
        <polygon id='Area39' className='cls-1' points='386.25 198.02 390.93 207.35 402.6 211.63 407.26 217.46 395.99 241.95 401.43 245.06 409.59 243.12 418.54 248.95 427.49 246.62 430.2 248.17 429.8 256.34 440.69 263.72 443.03 274.23 462.47 285.5 458.59 294.44 450.41 305.33 454.31 315.44 446.14 330.99 455.09 341.87 452.75 348.09 453.53 355.49 425.15 383.09 408.82 387.75 404.93 382.7 394.43 381.15 385.49 391.25 377.32 393.98 370.71 383.87 366.05 383.87 350.5 364.42 351.27 358.59 362.93 352.76 361.38 348.09 355.15 345.76 353.99 337.99 360.21 333.32 358.65 310.77 355.55 309.22 348.94 304.16 351.27 299.11 345.83 293.66 345.83 289.38 360.21 280.83 358.65 276.56 356.72 277.33 349.72 272.67 344.27 269.56 335.33 265.28 333.39 257.89 339.22 250.9 335.33 247.4 339.22 240.79 348.94 238.45 350.5 229.51 356.72 222.9 355.55 214.74 360.21 209.29 358.65 200.35 364.11 194.91 367.6 197.24 386.25 198.02'
        />
        <polygon className='cls-2' points='382.17 311.09 377.54 311.09 377.54 315.73 382.17 315.73 382.17 311.09 382.17 311.09'
        />
        <path d='M364.71,311.42h.69v4.8h2.31v.59h-3Z' transform='translate(-10.45 -9)'
        />
        <path d='M372.78,314.06a2.53,2.53,0,0,1-2.51,2.84,2.48,2.48,0,0,1-2.42-2.74,2.56,2.56,0,0,1,2.5-2.83A2.46,2.46,0,0,1,372.78,314.06Zm-4.19.09c0,1.15.63,2.18,1.72,2.18s1.73-1,1.73-2.24c0-1.07-.56-2.19-1.72-2.19S368.59,313,368.59,314.15Z'
          transform='translate(-10.45 -9)' />
        <path d='M373.68,316.81v-5.39h.76l1.73,2.73a15.92,15.92,0,0,1,1,1.75h0c-.06-.72-.08-1.38-.08-2.22v-2.25h.66v5.39H377l-1.71-2.74a18.08,18.08,0,0,1-1-1.79h0c0,.68.06,1.33.06,2.23v2.3Z'
          transform='translate(-10.45 -9)' />
        <path d='M378.85,316a2.49,2.49,0,0,0,1.25.35c.71,0,1.13-.37,1.13-.92s-.29-.79-1-1.07-1.42-.77-1.42-1.53a1.55,1.55,0,0,1,1.74-1.46,2.39,2.39,0,0,1,1.19.27l-.19.56a2.26,2.26,0,0,0-1-.25c-.74,0-1,.44-1,.8,0,.51.33.76,1.07,1,.91.36,1.38.8,1.38,1.59s-.62,1.55-1.89,1.55a2.72,2.72,0,0,1-1.38-.35Z'
          transform='translate(-10.45 -9)' />
        <path d='M384.54,314.39v.52h-2v-.52Z' transform='translate(-10.45 -9)'
        />
        <path d='M385.39,311.42h.69v4.8h2.3v.59h-3Z' transform='translate(-10.45 -9)'
        />
        <path d='M392,314.28h-2.09v1.94h2.33v.59h-3v-5.39h2.91V312h-2.21v1.71H392Z'
          transform='translate(-10.45 -9)' />
        <path d='M394.71,314.39v.52h-2v-.52Z' transform='translate(-10.45 -9)'
        />
        <path d='M395.46,316a2.49,2.49,0,0,0,1.25.35c.71,0,1.12-.37,1.12-.92s-.29-.79-1-1.07c-.88-.31-1.43-.77-1.43-1.53a1.56,1.56,0,0,1,1.75-1.46,2.39,2.39,0,0,1,1.19.27l-.19.56a2.27,2.27,0,0,0-1-.25c-.73,0-1,.44-1,.8,0,.51.33.76,1.07,1,.91.36,1.37.8,1.37,1.59s-.61,1.55-1.88,1.55a2.72,2.72,0,0,1-1.38-.35Z'
          transform='translate(-10.45 -9)' />
        <path d='M400.37,315.11l-.56,1.7h-.72l1.83-5.39h.84l1.84,5.39h-.74l-.58-1.7Zm1.77-.54-.53-1.55c-.12-.35-.2-.67-.28-1h0c-.08.32-.17.64-.27,1l-.53,1.56Z'
          transform='translate(-10.45 -9)' />
        <path d='M404.87,311.42v3.19c0,1.21.53,1.72,1.25,1.72s1.31-.53,1.31-1.72v-3.19h.71v3.14c0,1.66-.88,2.34-2,2.34s-1.94-.64-1.94-2.31v-3.17Z'
          transform='translate(-10.45 -9)' />
        <path d='M409.35,316.81v-5.39h.76l1.72,2.73a15.92,15.92,0,0,1,1,1.75h0a22.13,22.13,0,0,1-.08-2.22v-2.25h.65v5.39h-.7L411,314.07a16.38,16.38,0,0,1-1-1.79h0c0,.68.05,1.33.05,2.23v2.3Z'
          transform='translate(-10.45 -9)' />
        <path d='M415.31,311.42v5.39h-.7v-5.39Z' transform='translate(-10.45 -9)'
        />
        <path d='M419.31,314.28h-2.09v1.94h2.33v.59h-3v-5.39h2.91V312h-2.21v1.71h2.09Z'
          transform='translate(-10.45 -9)' />
        <path d='M420.46,311.49a7.36,7.36,0,0,1,1.33-.11,2.2,2.2,0,0,1,1.56.44,1.35,1.35,0,0,1,.43,1,1.42,1.42,0,0,1-1,1.38v0a1.35,1.35,0,0,1,.79,1.09,9.19,9.19,0,0,0,.42,1.48h-.72a6.7,6.7,0,0,1-.36-1.29c-.16-.74-.45-1-1.08-1h-.66v2.34h-.69Zm.69,2.46h.71c.75,0,1.22-.41,1.22-1s-.5-1-1.24-1a3,3,0,0,0-.69.06Z'
          transform='translate(-10.45 -9)' />
      </g>
      <g id='MapPart5' onClick={() => onClick(25)}>
        <polygon id='Area25' className='cls-1' points='455.09 341.87 446.14 330.99 454.31 315.44 450.41 305.33 458.59 294.44 462.47 285.5 443.03 274.23 440.69 263.72 429.8 256.34 430.2 248.17 427.49 246.62 418.54 248.95 409.59 243.12 401.43 245.06 395.99 241.95 407.26 217.46 402.6 211.63 390.93 207.35 386.25 198.02 407.26 185.96 416.98 181.69 473.35 138.92 487.74 138.92 498.24 135.42 493.18 129.98 494.74 128.81 495.91 128.03 503.3 129.98 509.51 127.26 509.51 123.37 533.23 121.81 554.23 134.64 546.84 152.53 548.01 155.64 560.84 153.31 565.51 160.69 564.73 167.3 549.56 189.85 548.01 198.8 530.9 213.18 526.63 229.51 495.13 247.4 492.01 257.12 495.13 277.33 490.86 289.38 473.35 300.66 457.41 328.66 457.8 334.1 455.09 341.87'
        />
        <polygon className='cls-2' points='434.17 188.76 429.54 188.76 429.54 193.39 434.17 193.39 434.17 188.76 434.17 188.76'
        />
        <path d='M447.59,197.49a7,7,0,0,1,1.27-.11,2.24,2.24,0,0,1,1.48.39,1.14,1.14,0,0,1,.45.95,1.3,1.3,0,0,1-.91,1.19h0a1.39,1.39,0,0,1,1.11,1.35,1.45,1.45,0,0,1-.46,1.08,2.65,2.65,0,0,1-1.86.51,8.23,8.23,0,0,1-1.08-.07Zm.7,2.21h.63c.73,0,1.16-.39,1.16-.91s-.48-.87-1.18-.87a3.46,3.46,0,0,0-.61,0Zm0,2.58a4.2,4.2,0,0,0,.58,0c.72,0,1.38-.26,1.38-1s-.63-1-1.39-1h-.57Z'
          transform='translate(-10.45 -9)' />
        <path d='M454.72,200.28h-2.1v1.95H455v.58h-3v-5.39h2.91V198h-2.21v1.71h2.1Z'
          transform='translate(-10.45 -9)' />
        <path d='M455.76,202a2.52,2.52,0,0,0,1.25.35c.71,0,1.13-.37,1.13-.92s-.29-.79-1-1.07-1.42-.77-1.42-1.53a1.56,1.56,0,0,1,1.74-1.46,2.33,2.33,0,0,1,1.19.27l-.19.56a2.18,2.18,0,0,0-1-.25c-.74,0-1,.44-1,.8s.33.76,1.08,1c.91.36,1.37.8,1.37,1.59s-.61,1.55-1.89,1.55a2.68,2.68,0,0,1-1.37-.35Z'
          transform='translate(-10.45 -9)' />
        <path d='M460.68,201.11l-.56,1.7h-.72l1.83-5.39h.84l1.84,5.39h-.75l-.57-1.7Zm1.76-.54-.52-1.55c-.12-.35-.2-.67-.28-1h0c-.08.31-.17.64-.27,1l-.53,1.56Z'
          transform='translate(-10.45 -9)' />
        <path d='M464.7,202.81v-5.39h.76l1.73,2.73a14.25,14.25,0,0,1,1,1.75h0c-.06-.72-.08-1.38-.08-2.22v-2.25h.66v5.39H468l-1.71-2.74a19.3,19.3,0,0,1-1-1.8h0c0,.68.06,1.33.06,2.23v2.3Z'
          transform='translate(-10.45 -9)' />
        <path d='M473.73,202.63a3.33,3.33,0,0,1-1.41.26l-.19.32a.62.62,0,0,1,.56.6c0,.46-.41.65-.82.65a1.23,1.23,0,0,1-.57-.14l.11-.36a1,1,0,0,0,.45.1c.18,0,.34-.07.34-.24s-.25-.3-.63-.34l.34-.62a2.49,2.49,0,0,1-2.26-2.7,2.68,2.68,0,0,1,2.81-2.83,3,3,0,0,1,1.28.24l-.17.57a2.5,2.5,0,0,0-1.09-.22,2,2,0,0,0-2.1,2.22,2,2,0,0,0,2.07,2.16,2.72,2.72,0,0,0,1.14-.22Z'
          transform='translate(-10.45 -9)' />
        <path d='M479,200.06a2.52,2.52,0,0,1-2.5,2.84,2.48,2.48,0,0,1-2.42-2.74,2.56,2.56,0,0,1,2.5-2.83A2.45,2.45,0,0,1,479,200.06Zm-4.18.09c0,1.15.62,2.18,1.72,2.18s1.73-1,1.73-2.24c0-1.07-.56-2.19-1.72-2.19S474.86,199,474.86,200.15Z'
          transform='translate(-10.45 -9)' />
        <path d='M480,202.81v-5.39h.76l1.73,2.73a15.73,15.73,0,0,1,1,1.75h0a22.13,22.13,0,0,1-.08-2.22v-2.25H484v5.39h-.7l-1.71-2.74a15.61,15.61,0,0,1-1-1.8h0c0,.68.05,1.33.05,2.23v2.3Z'
          transform='translate(-10.45 -9)' />
      </g>
      <g id='MapPart4' onClick={() => onClick(90)}>
        <polygon id='Area90' className='cls-1' points='528.18 72.04 534.01 71.27 546.06 80.21 556.56 96.54 558.9 104.71 572.88 112.87 574.44 127.26 563.95 128.81 554.23 134.64 533.23 121.81 519.24 84.1 528.18 72.04'
        />
        <polygon className='cls-2' points='533.76 101.76 529.13 101.76 529.13 106.39 533.76 106.39 533.76 101.76 533.76 101.76'
        />
        <path d='M547.83,110.91a6.23,6.23,0,0,1,1.27-.11,2.22,2.22,0,0,1,1.48.39,1.14,1.14,0,0,1,.45,1,1.28,1.28,0,0,1-.91,1.18v0a1.39,1.39,0,0,1,1.11,1.35,1.46,1.46,0,0,1-.46,1.08,2.71,2.71,0,0,1-1.86.5,8.65,8.65,0,0,1-1.08-.06Zm.7,2.21h.63c.73,0,1.17-.39,1.17-.91s-.48-.88-1.19-.88a3.16,3.16,0,0,0-.61,0Zm0,2.58a4.2,4.2,0,0,0,.58,0c.72,0,1.38-.26,1.38-1s-.63-1-1.39-1h-.57Z'
          transform='translate(-10.45 -9)' />
        <path d='M555,113.7h-2.1v1.94h2.34v.59h-3v-5.39h2.91v.58h-2.22v1.7H555Z'
          transform='translate(-10.45 -9)' />
        <path d='M556.1,110.84h.7v4.8h2.3v.59h-3Z' transform='translate(-10.45 -9)'
        />
        <path d='M559.88,110.84h2.9v.58h-2.21v1.79h2v.58h-2v2.44h-.69Z' transform='translate(-10.45 -9)'
        />
        <path d='M568.38,113.48a2.52,2.52,0,0,1-2.5,2.83,2.47,2.47,0,0,1-2.43-2.73,2.57,2.57,0,0,1,2.51-2.83A2.45,2.45,0,0,1,568.38,113.48Zm-4.18.08c0,1.15.62,2.19,1.72,2.19s1.72-1,1.72-2.24-.56-2.19-1.72-2.19S564.2,112.38,564.2,113.56Z'
          transform='translate(-10.45 -9)' />
        <path d='M569.29,110.91a7.36,7.36,0,0,1,1.33-.11,2.17,2.17,0,0,1,1.56.44,1.33,1.33,0,0,1,.42,1,1.4,1.4,0,0,1-1,1.37v0a1.35,1.35,0,0,1,.79,1.09,8.21,8.21,0,0,0,.42,1.48h-.72a7,7,0,0,1-.36-1.29c-.16-.75-.45-1-1.08-1H570v2.34h-.69Zm.69,2.45h.71c.75,0,1.22-.4,1.22-1s-.5-1-1.24-1a2.41,2.41,0,0,0-.69.07Z'
          transform='translate(-10.45 -9)' />
        <path d='M574.69,111.43h-1.64v-.59h4v.59h-1.65v4.8h-.7Z' transform='translate(-10.45 -9)'
        />
      </g>
      <g id='MapPart3' onClick={() => onClick(70)}>
        <polygon id='Area70' className='cls-1' points='338.05 131.15 353.99 135.42 349.72 153.31 341.55 154.86 340 157.19 346.61 163.03 346.61 173.52 357.11 176.24 364.11 194.91 367.6 197.24 386.25 198.02 407.26 185.96 416.98 181.69 473.35 138.92 487.74 138.92 498.24 135.42 493.18 129.98 494.74 128.81 495.91 128.03 503.3 129.98 509.51 127.26 509.51 123.37 533.23 121.81 519.24 84.1 528.18 72.04 513.8 64.27 490.86 44.05 487.35 45.22 484.63 59.22 480.35 60.77 467.14 49.5 464.02 51.83 443.81 51.83 425.15 39.78 422.81 42.11 415.42 61.55 404.15 62.72 401.43 69.71 395.99 70.49 390.15 79.43 382.76 80.21 378.1 96.54 373.83 115.2 364.5 119.87 352.04 115.98 339.22 122.59 338.05 131.15'
        />
        <polygon className='cls-2' points='437.51 105.76 432.88 105.76 432.88 110.39 437.51 110.39 437.51 105.76 437.51 105.76'
        />
        <path d='M451.51,120.48l-1.76-5.39h.76l.84,2.65c.23.73.43,1.38.57,2h0c.15-.63.37-1.31.61-2l.91-2.66h.75l-1.93,5.39Z'
          transform='translate(-10.45 -9)' />
        <path d='M457.58,118h-2.09v1.94h2.33v.59h-3v-5.39h2.92v.58h-2.22v1.7h2.09Z'
          transform='translate(-10.45 -9)' />
        <path d='M458.63,119.63a2.52,2.52,0,0,0,1.25.35c.71,0,1.13-.38,1.13-.92s-.29-.79-1-1.07-1.42-.77-1.42-1.53a1.55,1.55,0,0,1,1.74-1.46,2.46,2.46,0,0,1,1.19.26l-.19.57a2,2,0,0,0-1-.26c-.74,0-1,.44-1,.81s.33.75,1.08,1c.91.35,1.37.79,1.37,1.58s-.61,1.56-1.89,1.56a2.79,2.79,0,0,1-1.37-.35Z'
          transform='translate(-10.45 -9)' />
        <path d='M467.28,117.73a2.52,2.52,0,0,1-2.5,2.83,2.47,2.47,0,0,1-2.43-2.73,2.57,2.57,0,0,1,2.51-2.83A2.45,2.45,0,0,1,467.28,117.73Zm-4.18.08c0,1.15.62,2.19,1.72,2.19s1.72-1,1.72-2.24-.56-2.19-1.71-2.19S463.1,116.63,463.1,117.81Z'
          transform='translate(-10.45 -9)' />
        <path d='M468.88,115.09v3.19c0,1.2.54,1.72,1.26,1.72s1.31-.53,1.31-1.72v-3.19h.7v3.14c0,1.65-.87,2.33-2,2.33s-1.93-.63-1.93-2.3v-3.17Z'
          transform='translate(-10.45 -9)' />
        <path d='M473.36,115.09h.7v4.8h2.3v.59h-3Z' transform='translate(-10.45 -9)'
        />
      </g>
      <g id='MapPart2' onClick={() => onClick(21)}>
        <polygon id='Area21' className='cls-1' points='173.59 199.57 171.26 174.69 184.09 156.03 188.74 145.92 183.32 138.92 200.42 115.2 200.42 101.21 196.53 98.1 188.74 100.04 187.2 97.32 190.7 86.05 187.59 80.99 193.42 73.99 223.35 76.71 228.03 73.99 222.19 65.83 224.52 61.55 235.41 63.88 243.18 58.44 260.29 62.72 265.34 77.1 282.45 95.38 275.85 103.93 283.23 110.15 282.45 118.71 287.89 118.71 292.18 115.98 296.07 117.54 292.96 124.15 299.55 128.03 308.5 131.15 313.17 128.03 319.39 131.15 326.78 138.53 338.05 131.15 353.99 135.42 349.72 153.31 341.55 154.86 340 157.19 346.61 163.03 346.61 173.52 357.11 176.24 364.11 194.91 358.65 200.35 360.21 209.29 355.55 214.74 356.72 222.9 350.5 229.51 348.94 238.45 339.22 240.79 335.33 247.4 339.22 250.9 333.39 257.89 321.34 260.23 314.73 262.17 305.01 260.62 294.5 266.83 284.79 268.01 256.4 280.83 241.64 264.51 242.02 260.62 238.52 258.67 226.47 257.89 196.14 238.45 188.74 239.23 179.42 244.67 175.93 243.51 187.59 230.68 188.74 222.9 177.48 217.46 173.59 202.69 173.59 199.57'
        />
        <polygon className='cls-2' points='305.67 192.43 301.04 192.43 301.04 197.07 305.67 197.07 305.67 192.43 305.67 192.43'
        />
        <path d='M292.66,194.08a10.54,10.54,0,0,1,1.48-.11,3.07,3.07,0,0,1,2.18.67,2.5,2.5,0,0,1,.76,1.94,2.84,2.84,0,0,1-.77,2.09,3.32,3.32,0,0,1-2.39.78,11.06,11.06,0,0,1-1.26-.07Zm.7,4.77a4.55,4.55,0,0,0,.7,0,2.07,2.07,0,0,0,2.29-2.29,1.92,1.92,0,0,0-2.18-2.08,3.75,3.75,0,0,0-.81.08Z'
          transform='translate(-10.45 -9)' />
        <path d='M298.68,194v5.39H298V194Z' transform='translate(-10.45 -9)' />
        <path d='M301,194h.7v3.61c0,1.43-.7,1.87-1.63,1.87a2.34,2.34,0,0,1-.75-.13l.11-.57a1.53,1.53,0,0,0,.58.11c.62,0,1-.28,1-1.34Z'
          transform='translate(-10.45 -9)' />
        <path d='M307.46,196.65a2.52,2.52,0,0,1-2.5,2.84,2.48,2.48,0,0,1-2.42-2.74,2.56,2.56,0,0,1,2.5-2.83A2.45,2.45,0,0,1,307.46,196.65Zm-4.18.09c0,1.15.63,2.18,1.72,2.18s1.73-1,1.73-2.24c0-1.07-.56-2.19-1.72-2.19S303.28,195.55,303.28,196.74Z'
          transform='translate(-10.45 -9)' />
        <path d='M308.37,199.4V194h.76l1.73,2.73a15.92,15.92,0,0,1,1,1.75h0c-.06-.72-.08-1.37-.08-2.21V194h.66v5.39h-.71L310,196.67a18.19,18.19,0,0,1-1-1.8h0c0,.67.06,1.32.06,2.22v2.3Z'
          transform='translate(-10.45 -9)' />
      </g>
      <g id='MapPart1' onClick={() => onClick(89)} >
        <polygon id='Area89' className='cls-1' points='187.59 80.99 177.87 77.88 170.1 82.93 157.65 86.82 142.88 82.55 123.43 56.88 103.21 40.55 100.11 23.45 83 4 66.67 0.51 42.96 3.23 19.23 16.83 19.23 33.95 12.24 40.55 7.96 48.72 19.23 52.6 27.02 59.99 33.24 85.26 19.62 106.26 24.29 114.04 19.23 124.92 16.13 124.15 3.3 127.26 0.58 133.09 22.73 162.25 66.67 184.02 87.66 171.19 93.89 171.97 122.67 194.52 140.54 189.08 144.43 194.52 150.27 192.96 154.15 194.91 154.92 202.69 159.98 207.74 166.99 199.57 173.59 199.57 171.26 174.69 184.09 156.03 188.74 145.92 183.32 138.92 200.42 115.2 200.42 101.21 196.53 98.1 188.74 100.04 187.2 97.32 190.7 86.05 187.59 80.99'
        />
        <polygon className='cls-2' points='102.59 114.84 97.96 114.84 97.96 119.47 102.59 119.47 102.59 114.84 102.59 114.84'
        />
        <path d='M97.49,119.45l-.56,1.69h-.72L98,115.75h.84l1.84,5.39H100l-.58-1.69Zm1.77-.55-.53-1.55c-.12-.35-.2-.67-.28-1h0c-.08.32-.16.65-.27,1l-.53,1.56Z'
          transform='translate(-10.45 -9)' />
        <path d='M102,115.75v3.19c0,1.21.53,1.72,1.25,1.72s1.31-.52,1.31-1.72v-3.19h.71v3.15c0,1.65-.88,2.33-2,2.33s-1.94-.63-1.94-2.3v-3.18Z'
          transform='translate(-10.45 -9)' />
        <path d='M109.42,121.14l-.69-1.19c-.28-.45-.46-.75-.62-1.06h0c-.15.31-.3.6-.58,1.07l-.65,1.18h-.8l1.65-2.72-1.59-2.67h.81l.71,1.27c.2.35.35.62.5.91h0a10.31,10.31,0,0,1,.49-.91l.73-1.27h.8l-1.64,2.63,1.68,2.76Z'
          transform='translate(-10.45 -9)' />
        <path d='M113.83,118.62h-2.1v1.94h2.33v.58h-3v-5.39h2.91v.59h-2.21V118h2.1Z'
          transform='translate(-10.45 -9)' />
        <path d='M115,115.83a6.6,6.6,0,0,1,1.34-.12,2.19,2.19,0,0,1,1.55.44,1.33,1.33,0,0,1,.43,1,1.41,1.41,0,0,1-1,1.37v0a1.3,1.3,0,0,1,.79,1.08,8.86,8.86,0,0,0,.42,1.48h-.72a6.51,6.51,0,0,1-.36-1.28c-.16-.75-.45-1-1.08-1h-.65v2.33H115Zm.7,2.45h.71c.74,0,1.21-.41,1.21-1s-.5-1-1.24-1a3,3,0,0,0-.68.06Z'
          transform='translate(-10.45 -9)' />
        <path d='M119.27,115.83a6.67,6.67,0,0,1,1.34-.12,2.24,2.24,0,0,1,1.56.44,1.35,1.35,0,0,1,.42,1,1.4,1.4,0,0,1-1,1.37v0a1.34,1.34,0,0,1,.79,1.08,7.21,7.21,0,0,0,.42,1.48h-.72a6.51,6.51,0,0,1-.36-1.28c-.16-.75-.45-1-1.08-1H120v2.33h-.7Zm.7,2.45h.71c.75,0,1.22-.41,1.22-1s-.51-1-1.24-1a3,3,0,0,0-.69.06Z'
          transform='translate(-10.45 -9)' />
        <path d='M126.37,118.62h-2.1v1.94h2.34v.58h-3v-5.39h2.91v.59h-2.22V118h2.1Z'
          transform='translate(-10.45 -9)' />
      </g>
    </svg>
    <a className="button is-info outsideLeft" onClick={() => onClick("")}>
      Tous les départements
    </a>  
    <a className="button is-info outside" onClick={() => onClick(0)}>
      Hors région
    </a>
    
  </div>
)
