import React from 'react'
import { connect } from 'react-redux'
import {
  makeSelectError,
  makeSelectLoading,
  makeSelectResource,
  makeSelectUpdating
} from '../modules/userPostJobOffers/selectors'
import routes from '../routes'

import ProtectedAreaPostJobItemPage from './userPostJob/ProtectedAreaPostJobItemPage'
import PostJobItemForm from './postJob/PostJobItemForm'
import PostJobTitle from './postJob/PostJobTitle'
import DeleteUserPostProcess from './userPostJob/DeleteUserPostProcess'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import LoadingOverlay from './utils/LoadingOverlay'
import { archivePostOffer, updatePostJobOffer } from '../modules/userPostJobOffers/actions'

const ProtectedAreaPostJobOfferEditPage = ({
  match, location, loading, updating, error, post, handleSubmit, handleArchivePost
}) => {
  if(loading) {
    return <LoadingOverlay />;
  }
  if(error) {
    return 'error ... '+error;
  }
  if (!post) {
    toast.error(`Cette ressource est inaccessible`)
    return <Redirect to='/' />
  }

  const {title, status} = post;

  if(status !== 'drafted') {
    toast.error(`Vous ne pouvez pas modifier une offre après l'avoir publié.`);
    return <Redirect to={routes.PROTECTED_AREA_JOB_OFFER_LIST} />;
  }

  return <>
    {updating && <LoadingOverlay />}
    <ProtectedAreaPostJobItemPage
      {...{match, location}}
      post={post}
      breadcrumbsMisc={[
        { label: 'Mes offres', routeName: routes.PROTECTED_AREA_JOB_OFFER_LIST, to: routes.PROTECTED_AREA_JOB_OFFER_LIST },
        { label: title }
      ]}
      titlePage={(
        <>
          <PostJobTitle title="Modifier votre annonce" />
          <div className="post-action action-mini buttons">
            <a className="button is-success">
              <i className="fa fa-check" style={{paddingRight:'0.5rem'}}/> Publier
            </a>

            <DeleteUserPostProcess
              {...{post, handleArchivePost}}
              className="button is-danger"
              actionerLabel={<span style={{paddingLeft: '0.5rem'}}>Supprimer</span>}
            />
          </div>
        </>
      )}
    >
      <PostJobItemForm {...{post, handleSubmit}} />
    </ProtectedAreaPostJobItemPage>
  </>;
}

const mapStateToProps = (state, props) => ({
  post: makeSelectResource()(state, props),
  loading: makeSelectLoading()(state, props),
  updating: makeSelectUpdating()(state, props),
  error: makeSelectError()(state, props)
})

const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (post) => dispatch(updatePostJobOffer(post)),
  handleArchivePost: (post, archivingData) => dispatch(archivePostOffer(post, archivingData)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaPostJobOfferEditPage)
