import React from 'react'
import classnames from 'classnames'
import { TrixEditor } from "react-trix"

import 'trix/dist/trix'
import 'trix/dist/trix.css'


const handleEditorReady = (editor) => {
  // disable file tools
  document.querySelector("[data-trix-button-group=file-tools]").replaceWith('');

  document.addEventListener("trix-file-accept", function(event) {
    event.preventDefault();
  });
}

const handleChange = (html, text) => {
  // ...
}

export default ({className = null, allowEmpty, choices, onChange = (e) => {}, ...props}) => (
  <div className="control is-expanded">
    <TrixEditor
      {...props}
      className={classnames('trix-content', className)}
      onChange={(html, text) => onChange({target:{value: html}})}
      onEditorReady={handleEditorReady}
    />
  </div>
)
