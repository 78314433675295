import React from 'react'

export default ({ image, className = '' }) => {
  if (image && image.publicPath) {
    const path = image.publicPath
    const alt = image.altText ? image.altText : ''
    return (
      <img src={path} className={className} alt={alt} />
    )
  } else {
    return null
  }
}
