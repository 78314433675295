import React from 'react'
import routes from '../../routes.json'
import ModalPortal from '../utils/modal/ModalPortal'
import Modal from '../utils/modal/Modal'
import { Link } from 'react-router-dom'

export default ({ onClose }) => {
  return (
    <ModalPortal>
      <Modal onClose={onClose}>
        <div className='box has-text-centered'>
          <h5 className='is-size-4'>
            Qui êtes vous ?
          </h5>
          <br />
          <div className='buttons is-centered are-medium'>
            <Link to={routes.CREATE_PERSONAL_ACCOUNT}>
              <button
                className='button is-primary'
                type='button'
                onClick={() => { onClose() }}
              >
                <span>Un particulier</span>
              </button>
            </Link>
            <Link to={routes.CREATE_PRO_ACCOUNT}>
              <button
                className='button is-primary'
                type='button'
                onClick={() => { onClose() }}
              >
                <span>Un professionel</span>
              </button>
            </Link>
          </div>
        </div>
      </Modal>
    </ModalPortal>
  )
}
