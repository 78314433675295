import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

import { makeSelectResource as makeSelectCategory } from '../category/selectors'
import { makeSelectIsMain } from '../domain/selectors'

const selectNamespace = state => state[STATE_KEY] || initialState

export const makeSelectFooterCollection = () =>
  createSelector(
    [selectNamespace, makeSelectCategory()],
    ({ items }, cat) => items.filter(item => (cat ? (item.category && item.category.id === cat.id) : !item.category) && item.isDisplayedInFooter)
  )

export const makeSelectCollection = () =>
  createSelector(
    [selectNamespace],
    ({ items }) => items
  )
