import React from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.scss'
import { connect } from 'react-redux'
import LegalNotice from './app/legalNotice/LegalNotice'
import Contact from './app/contact/Contact'
import Partners from './app/Partners'
import SearchResults from './app/searchResults/SearchResults'
import ArticlesPage from './app/ArticlesPage'
import Category from './app/CategoryPage'
import CreateAccount from './containers/Account/CreateAccount/CreateAccount'
import SectionPage from './app/SectionPage'
import ContentPage from './app/ContentPage'

const CategorySiteSwitch = () => (
  <Switch>
    <Route path='/' exact component={Category} />
    <Route path='/inscription' exact component={CreateAccount} />
    <Route path='/mentions-legales' exact component={LegalNotice} />
    <Route path='/nous-contacter' exact component={Contact} />
    <Route path='/nos-partenaires' exact component={Partners} />
    <Route path='/recherche' exact component={SearchResults} />

    <Route path='/actualites/:article' exact component={ArticlesPage} />
    <Route path='/actualites' exact component={ArticlesPage} />
    <Route path='/:section' exact component={SectionPage} />
    <Route path='/:section/:content' exact component={ContentPage} />
  </Switch>
)

export default connect(() => ({}))(CategorySiteSwitch)
