import React from 'react'
import { connect } from 'react-redux'

import { makeSelectResource, makeSelectCollection } from '../modules/category/selectors'
import { makeSelectCollectionForCategory as makeSelectSectionCollectionForCategory } from '../modules/section/selectors'
import { makeSelectCollectionForCategory as makeSelectArticleCollectionForCategory } from '../modules/article/selectors'
import { makeSelectCollection as makeSelectContentCollection } from '../modules/content/selectors'

import { Helmet } from 'react-helmet'
import CategorySlider from './CategorySlider'
import ControlledBreadcrumbs from './utils/ControlledBreadcrumbs'
import Page from './Page'
import SearchBar from './utils/SearchBar'
import Stripe from '../components/Stripe/Stripe'
import InfoUrl from '../components/InfoUrl/InfoUrl'
import Image from './utils/Image'
import SectionLink from './sections/SectionLink'
import routes from '../routes.json'
import { getArticlesUri } from '../router'
import ControlledLoadableLink from './utils/ControlledLoadableLink'
import classnames from 'classnames'

const CategoryPage = ({ category, categories, sections, articles, contents, match }) => (
  <Page match={match} className='page page-content'>
    <Helmet>
      <title>{category.title}</title>
    </Helmet>
    {!category.domain && <ControlledBreadcrumbs {...{ category }} />}
    <SearchBar />

    <CategorySlider category={category} articles={articles} sections={sections} categories={categories} />
    <Tiles {...{ category, sections }} />

    {sections.filter(({ type }) => type === 'section').map(section => (
      <Stripe
        key={section.id}
        category={category}
        section={section}
        contents={contents.filter(item => item.section && item.section.id === section.id)}
      />
    ))}

    <InfoUrl info={category.footer} />
  </Page>
)

const mapStateToProps = (state, props) => ({
  category: makeSelectResource()(state, props),
  categories: makeSelectCollection()(state, props),
  sections: makeSelectSectionCollectionForCategory()(state, props),
  articles: makeSelectArticleCollectionForCategory()(state, props),
  contents: makeSelectContentCollection()(state, props)
})

export default connect(mapStateToProps)(CategoryPage)


const Tiles = ({sections, category}) => (
  <>
    <div className="mtiles columns is-multiline is-mobile is-variable is-3-desktop is-0-tablet is-0-mobile"
         style={{width:'100%'}}
    >
      <ControlledLoadableLink
        routeName={routes.ARTICLES}
        to={getArticlesUri(category)}
        className="mtile column is-one-fifth-desktop is-one-quarter-tablet is-half-mobile"
      >
        <div
          className="mtile-container"
          style={{ backgroundColor: category.color }}
        >
          <Image image={{ publicPath: '/assets/news-icon.png', altText: 'Actualités' }} className='icon' />
          <h3>Actualités</h3>
        </div>
      </ControlledLoadableLink>

      {sections.map((item, i) => (
        <SectionLink
          key={item.id}
          category={category}
          section={item}
          className={classnames('mtile column', {
            'is-one-fifth-desktop': (sections.length >= 5) && (i !== sections.length),
            'is-one-quarter-desktop': (sections.length < 5),
            'is-half-mobile': (!sections.length % 2) && (i === sections.length)
          })}
        >
          <div
            className={classnames("mtile-container", {iconOnly: !item.title.length})}
            style={{ backgroundColor: item.color }}
          >
            <Image image={item.icon} className='icon' />
            {item.title && <h3>{item.title}</h3>}
          </div>
        </SectionLink>
      ))}
    </div>
  </>
)
