// PUBLIC_POST_JOB_OFFER
import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

const selectNamespace = state => state[STATE_KEY] || initialState
const selectRouterMatch = (state, props) => props.match

export const makeSelectResource = () =>
  createSelector(
    [makeSelectCollection(), selectRouterMatch],
    (items, { params: { post } }) => items.find(({id}) => id === parseInt(post, 10))
  )

export const makeSelectCollection = () =>
  createSelector(
    [selectNamespace],
    ({ items }) => items
  )
