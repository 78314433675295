import { AUTHENTICATION, LOCAL_STORAGE_KEY } from './constants';
import LocalStorageManager from '../LocalStorageManager'

// The initial state of the login
export const initialState = {
  loading: true,
  error: false,
  token: LocalStorageManager.get(LOCAL_STORAGE_KEY) || null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATION.fetching:
      return {
        ...state,
        loading: true,
        error: false
      }
    case AUTHENTICATION.fetched:
      const token = action.data.token || null;
      LocalStorageManager.set(LOCAL_STORAGE_KEY, token)
      return {
        ...state,
        loading: false,
        error: false,
        token
      }
    case AUTHENTICATION.fetching_error:
      return {
        ...state,
        loading: false,
        error: true
      }
    case AUTHENTICATION.clean:
      LocalStorageManager.set(LOCAL_STORAGE_KEY, null)
      return {
        ...state,
        token: null
      }
  }
  return state
}
