import { requestWithToken } from '../apiHelper'
import { toast } from 'react-toastify'
import { USER_POST_JOB_SAVED_REQUEST, USER_POST_JOB_SAVED_REQUESTS } from './constants'

const endpoint = 'user/savedrequests'

export function fetchSavedPostJobRequests () {
  return (dispatch, getState) => {
    dispatch({ type: USER_POST_JOB_SAVED_REQUESTS.fetching })

    return requestWithToken({ endpoint }, getState)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: USER_POST_JOB_SAVED_REQUESTS.fetched, data: res.data })
      })
      .catch(error => {
        dispatch({ type: USER_POST_JOB_SAVED_REQUESTS.fetching_error, data: error })
        toast.error(error.response.data.message)
      })
  }
}

export function savePostJobRequest (id) {
  return (dispatch, getState) => {
    dispatch({ type: USER_POST_JOB_SAVED_REQUEST.save, payload: id })
    dispatch({ type: USER_POST_JOB_SAVED_REQUEST.saving })

    return requestWithToken({ endpoint: endpoint + '/' + id, method: 'POST', data: {} }, getState)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: USER_POST_JOB_SAVED_REQUEST.saved })
      })
      .catch(error => {
        dispatch({ type: USER_POST_JOB_SAVED_REQUEST.saving_error, data: error })
        toast.error(error.response.data.message)
      })
  }
}

export function loseSavedPostJobRequest (id) {
  return (dispatch, getState) => {
    dispatch({ type: USER_POST_JOB_SAVED_REQUEST.lose, payload: id })
    dispatch({ type: USER_POST_JOB_SAVED_REQUEST.saving })

    return requestWithToken({ endpoint: endpoint + '/' + id, method: 'DELETE', data: {} }, getState)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: USER_POST_JOB_SAVED_REQUEST.saved })
      })
      .catch(error => {
        dispatch({ type: USER_POST_JOB_SAVED_REQUEST.saving_error, data: error })
        toast.error(error.response.data.message)
      })
  }
}
