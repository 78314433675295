import React from 'react'
import { connect } from 'react-redux'

import { Helmet } from 'react-helmet'
import { makeSelectResource as makeSelectCategoryResource } from '../modules/category/selectors'
import { makeSelectCollection as makeSelectSectionCollection } from '../modules/section/selectors'
import { makeSelectResource as makeSelectContentResource } from '../modules/content/selectors'

import { makeSelectCollectionForCategory as makeSelectArticleCollectionForCategory } from '../modules/article/selectors'

import ControlledNavBar from './utils/ControlledNavBar'
import ControlledBreadcrumbs from './utils/ControlledBreadcrumbs'
import Page from './Page'
import PostList from './post/PostList'
import PostArticleCard from './articles/PostArticleCard'
import SearchBar from './utils/SearchBar'
import Layout from './utils/Layout'
import InfoUrl from '../components/InfoUrl/InfoUrl'

const ArticlesPage = ({ match, category, sections, articles }) => (
  <Page match={match} className='page page-content page-articles'>
    <Helmet>
      <title>Articles - {category.title}</title>
    </Helmet>

    <ControlledNavBar selectedCategory={category} selectedSection={{ slug: 'actualites' }} match={match} />
    <ControlledBreadcrumbs {...{ category, misc: [{ label: 'Actualités' }] }} />
    <SearchBar />
    <Layout title='Les actualités'> {/* background=backgroundAccount */}
      {articles.length
        ? <PostList CardComponent={PostArticleCard} cardAction={false} posts={articles.map(article => ({
          ...article,
          category,
          section: sections.find(section => article.section && article.section.id === section.id)
        }))} />
        : 'Il n\'y a aucune actualité pour le moment...'
      }
    </Layout>
    <InfoUrl info={category.footer} />
  </Page>
)

const mapStateToProps = (state, props) => ({
  category: makeSelectCategoryResource()(state, props),
  sections: makeSelectSectionCollection()(state, props),
  content: makeSelectContentResource()(state, props),

  articles: makeSelectArticleCollectionForCategory()(state, props)
  // backgroundAccount: state.config.backgroundAccount
})

export default connect(mapStateToProps)(ArticlesPage)
