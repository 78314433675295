import { AUTHENTICATION_REDIRECT } from './constants';

export const initialState = {
  target: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATION_REDIRECT.set:
      return {
        ...state,
        target: action.target
      }
    case AUTHENTICATION_REDIRECT.clean:
      return {
        ...state,
        target: null
      }

  }
  return state
}
