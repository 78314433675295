import { isObject, reduce } from 'lodash'

/**
 * Post (jobs & housings) Filter logic
 *
 * @param results
 * @param filterValues
 * @param filterConfig
 * @returns {*}
 */
export default (results, filterValues, filterConfig) => results.filter(result => {
  return reduce(filterValues, (isValid, filterValue, keyInput) => {
    const config = filterConfig.find(({name}) => name === keyInput) || {};
    // handle special cases
    if(config.callback) {
      return config.callback(filterValue, result)
        && isValid;
    }

    // if filter empty, ignore it
    if (!isObject(filterValues[keyInput]) && !filterValues[keyInput].length) {
      return isValid;
    }

    //const config = filterConfig.find(({name}) => name === keyInput) || {};
    let targets = config.targets || [keyInput];
    for (const target in targets) {
      if (typeof filterValues[keyInput] !== "string" || typeof result[targets[target]] !== "string") {
        if((result[targets[target]] || "") === filterValues[keyInput]) {
          return isValid;
        }
        return false;
      }

      const normalizedFilterValue = normalizeString(filterValues[keyInput]);

      if (-1 !== normalizeString(result[targets[target]] || "").indexOf(normalizedFilterValue)) {
        return isValid;
      }
    }
    return false;
  }, true)
});

const normalizeString = (text) => {
  if (typeof text !== "string") {
    return text;
  }

  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace('"', "")
    .replace("'", "")
    .toLowerCase()
    .trim()
}
