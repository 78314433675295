import React from 'react'
import Image from '../../utils/Image'
import Jimp, {AUTO} from 'jimp'
import classnames from 'classnames'
import ErrorsContainer from '../../form/ErrorsContainer'

import './AvatarInput.scss'

const processUploadImage = fileInput => {
  return new Promise(resolve => {
    let reader = new window.FileReader()
    reader.readAsDataURL(fileInput)
    reader.onload = function () {
      const imagePath = URL.createObjectURL(fileInput)
      Jimp.read(imagePath)
        .then(image => {
          image.cover(256, 256)
          image.getBase64Async(AUTO).then((base64) => {
            resolve(base64)
          })
        })
    }
    reader.onerror = function () {
      resolve(null)
    }
  })
}

const processInput = async ({target: { files }}) => {
  const fileInput = files[0];
  return await processUploadImage(fileInput);
}

export default ({ id, name, setInput = (name, value) => {}, selectedValue = {}, className, errors = [], isDirty = true }) => {
  const displayedValue = selectedValue
    ? (selectedValue.base64 || selectedValue.publicPath) : '';

  return (
    <div className={classnames("field column avatar-input", className)}>
      <div className="image is-128x128 is-64x64-mobile">
        <Image image={{ publicPath: displayedValue || '/assets/blank-avatar.png' }} className='is-rounded avatar' />

        <label htmlFor={id}>
          <i className="fa fa-camera fa-2x" />

          <input
            type="file"
            id={id}
            name={name}
            onChange={e => processInput(e).then(base64 => setInput(name, {...selectedValue, base64}))}
            accept="image/png, image/jpeg, image/jpg"
          />
        </label>
      </div>
      {
        isDirty && <ErrorsContainer errors={errors} />
      }
    </div>
  )
}