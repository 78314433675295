import React from 'react'
import truncate from 'truncate-html'

import ArticleLink from './articles/ArticleLink'
import Slider from './utils/Slider'

export default ({ articles = [], categories = [] }) => {
  truncate.setup({ stripTags: true, length: 60, reserveLastWord: true })
  return (
    <Slider items={articles} renderSlide={item => (
      <ArticleLink key={item.id} article={item} category={categories.find(category => item.categories[0] && item.categories[0].id === category.id)}>
        <h2 className='glide__title'>{item.title}</h2>
        <div className='glide__excerpt' dangerouslySetInnerHTML={{ __html: truncate(item.introduction) }} />
      </ArticleLink>
    )} />
  )
}
