import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { makeSelectCollection as makeSelectRequestCollection } from '../../modules/userPostJobSavedRequests/selectors'
import PostList from '../post/PostList'
import PostJobCard from '../postJob/PostJobCard'
import { getPostJobRequestUri } from '../../router'
import routes from '../../routes'
import ControlledSavePostJobRequest from '../postJob/ControlledSavePostJobRequest'

const UserJobRequestSavedList = ({ requests = [], maxItems, location }) => {
  return (
    <div>
      <PostList CardComponent={PostJobCard} ControlledSavePost={ControlledSavePostJobRequest} posts={requests} location={location} getCardLinkProps={
        (post) => ({
          routeName: routes.PUBLIC_POST_REQUEST,
          to: getPostJobRequestUri(post.id)
        })
      } />
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  requests: makeSelectRequestCollection()(state, props)
})

export default withRouter(connect(mapStateToProps)(UserJobRequestSavedList))
