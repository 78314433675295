import produce from 'immer'
import { ROUTER } from './constants'

export const initialState = {
  loadingUri: ''
}

/* eslint-disable default-case, no-param-reassign */
export default function (state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case ROUTER.locationChange:
        draft.loadingUri = action.targetPath
        break

      case ROUTER.locationChanged:
        draft.loadingUri = null
        break
    }
  })
}
