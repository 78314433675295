import React from 'react'
import classnames from 'classnames'

import './layout/Layout.scss'
import backgroundImageHelper from './backgroundImageHelper'

export default ({ title, children, background = {}, withSearchBar = true, withSideBar = false, notification = null }) => (
  <div className='layout'>
    <div className='background' style={backgroundImageHelper(background)} />

    <div className={classnames('mainColumn', { 'withSideBar': withSideBar, 'withoutTitle': !title })}>
      {notification}
      { title && <h1>{title}</h1> }
      {children}
    </div>
  </div>
)
