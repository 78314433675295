import { USER_ORGANISM, USER_ORGANISM_COLLABORATORS } from './constants'
import { requestWithToken } from '../apiHelper'
import { toast } from 'react-toastify'

const endpoint = 'user/organism'

export const getUserOrganism = () =>
  (dispatch, getState) => {
    dispatch({ type: USER_ORGANISM.fetching })
    return requestWithToken({ endpoint: `${endpoint}/current` }, getState)
      .then(res => res.data)
      .then(({ data }) => {
        dispatch({ type: USER_ORGANISM.fetched, data: data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_ORGANISM.fetching_error, error: error })
      })
  }

export function createOrganism (organism) {
  return (dispatch, getState) => {
    dispatch({ type: USER_ORGANISM.creating })

    return requestWithToken({
      endpoint,
      method: 'POST',
      data: organism
    }, getState)
      .then(res => res.data)
      .then(({ data }) => {
        toast.success(`Votre organisme à été crée`)
        dispatch({ type: USER_ORGANISM.created, data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_ORGANISM.creating_error, error: error })
      })
  }
}

export const deleteUserToOrganism = ({ id }) =>
  (dispatch, getState) => {
    dispatch({ type: USER_ORGANISM_COLLABORATORS.deleting })
    return requestWithToken({
      endpoint: `${endpoint}/collaborators/${id}`,
      method: 'DELETE'
    }, getState)
      .then(res => res.data)
      .then((res) => {
        dispatch({ type: USER_ORGANISM_COLLABORATORS.deleted, payload: id })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_ORGANISM_COLLABORATORS.deleting_error, error: error })
      })
  }

export const setUserOwnerToOrganism = ({ id }) =>
  (dispatch, getState) => {
    dispatch({ type: USER_ORGANISM_COLLABORATORS.promoting })
    return requestWithToken({
      endpoint: `${endpoint}/collaborators/${id}`,
      method: 'PATCH'
    }, getState)
      .then(res => res.data)
      .then(({ data }) => {
        dispatch({ type: USER_ORGANISM_COLLABORATORS.promoted, data: data })
        dispatch({ type: 'USER_NOT_ORGANISM_OWNER' })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_ORGANISM_COLLABORATORS.promoting_error, error: error })
      })
  }

export function inviteUserToOrganism (invitation) {
  return (dispatch, getState) => {
    dispatch({ type: USER_ORGANISM_COLLABORATORS.invite_loading })

    return requestWithToken({
      endpoint: `${endpoint}/collaborators/invite`,
      method: 'POST',
      data: invitation
    }, getState)
      .then(res => res.data)
      .then(data => {
        toast.success(`Une invitation viens d'être envoyée à l'adresse : ${invitation.email}`)
        dispatch({ type: USER_ORGANISM_COLLABORATORS.invite_loaded, data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_ORGANISM_COLLABORATORS.invite_loading_error, error: error })
      })
  }
}
