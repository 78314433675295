import React from 'react'
import classnames from 'classnames'
import ErrorsContainer from './ErrorsContainer'
import IsRequiredIndicator from './IsRequiredIndicator'

export default ({className, id = null, label = null, required = false, errors = [], isDirty = true, children, ...rest}) => (
  <div className={classnames("field column", className)} {...rest}>
    {label && <label htmlFor={id} className="label">
      {label}{required && <IsRequiredIndicator />}
    </label>}

    {children}

    {isDirty && <ErrorsContainer errors={errors} />}
  </div>
)