/**
 * ModalHandler
 *
 * handle open|close state for a given modal, from a given actioner
 *  - modal receives the onClose func prop
 *  - actioner receives the onOpen func prop
 */
import React, { cloneElement } from 'react'

// type Props = {
//   actioner: React.node,
//   modal: React.node,
// };

export default class ModalHandler extends React.Component {
  // props: Props;
  constructor (props) {
    super(props)
    this.state = {
      showModal: false
    }
    this.onOpen = this.onOpen.bind(this)
    this.onClose = this.onClose.bind(this)
  }
  // state = { showModal: false };

  onOpen () {
    this.setState({ showModal: true })
  }

  onClose () {
    this.setState({ showModal: false })
  }

  render () {
    const { showModal } = this.state
    const actioner = this.props.actioner({
      key: 'actioner',
      onOpen: this.onOpen
    })

    if (!showModal) {
      return actioner
    }

    const modal = this.props.modal({
      key: 'modal',
      onClose: this.onClose
    })

    return [
      cloneElement(actioner, { key: 'actioner' }),
      cloneElement(modal, { key: 'modal' })
    ]
  }
}
