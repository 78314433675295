import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import HomeSlider from './HomeSlider'
import Page from './Page'
import { makeSelectCollectionForHome } from '../modules/article/selectors'
import SearchBar from './utils/SearchBar'
import InfoUrl from '../components/InfoUrl/InfoUrl'
import Image from './utils/Image'
import CategoryLink from './categories/CategoryLink'
import classnames from 'classnames'

const HomePage = ({ articles = [], categories = [], match, info }) => (
  <Page match={match} className='page page-home'>
    <Helmet>
      <title>Accueil</title>
    </Helmet>
    <SearchBar />
    <HomeSlider articles={articles} categories={categories} />
    <Tiles categories={categories} />

    <InfoUrl info={info} />
  </Page>
)

const mapStateToProps = (state, props) => ({
  articles: makeSelectCollectionForHome()(state, props),
  categories: state.category.items,
  info: state.config.info
})

export default connect(mapStateToProps)(HomePage)

const Tiles = ({categories}) => (
  <div className="mtiles columns is-multiline is-mobile is-variable is-3-desktop is-0-tablet is-0-mobile">
    {categories.map((item, i) => (
      <CategoryLink
        key={item.id}
        category={item}
        className={classnames('mtile column is-half-mobile', {
          'is-one-fifth-desktop': (categories.length >= 5)
        })}
      >
        <div
          className="mtile-container"
          style={{ backgroundColor: item.color }}
        >
          <Image image={item.icon} className='icon' />
          <h3>{item.title}</h3>
        </div>
      </CategoryLink>
    ))}
  </div>
)
