import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

const selectNamespace = state => state[STATE_KEY] || initialState

export const makeSelectToken = () =>
  createSelector(
    selectNamespace,
    ({ token }) => token
  )