import { requestWithToken } from '../apiHelper'
import { toast } from 'react-toastify'
import { USER_SAVED_SEARCHES, USER_SAVED_SEARCH } from './constants'

const endpoint = 'user/researches'

export const fetchUserSearches = () =>
  (dispatch, getState) => {
    dispatch({ type: USER_SAVED_SEARCHES.fetching })
    return requestWithToken({ endpoint }, getState)
      .then(res => res.data)
      .then(({ data }) => {
        dispatch({ type: USER_SAVED_SEARCHES.fetched, data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_SAVED_SEARCHES.fetching_error, error: error })
      })
  }

export const createSearch = (search) =>
  (dispatch, getState) => {
    dispatch({ type: USER_SAVED_SEARCH.creating })
    return requestWithToken({
      endpoint: `${endpoint}`,
      method: 'POST',
      data: search
    }, getState)
      .then(res => res.data)
      .then((data) => {
        toast.success(search.isAlert ? 'L\'alerte a été sauvegardée' : 'La recherche a été sauvegardée')
        dispatch({ type: USER_SAVED_SEARCH.created, data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_SAVED_SEARCH.creating_error, error: error })
      })
  }

export const toggleSearchAlert = (searchId) =>
  (dispatch, getState) => {
    dispatch({ type: USER_SAVED_SEARCH.updating })
    return requestWithToken({
      endpoint: `${endpoint}/${searchId}/toggleAlert`,
      method: 'PATCH'
    }, getState)
      .then(res => res.data)
      .then(({ data }) => {
        dispatch({ type: USER_SAVED_SEARCH.updated, data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_SAVED_SEARCH.updating_error, error: error })
      })
  }

export const deleteSearch = (searchId) =>
  (dispatch, getState) => {
    dispatch({ type: USER_SAVED_SEARCH.deleting })
    return requestWithToken({
      endpoint: `${endpoint}/${searchId}`,
      method: 'DELETE'
    }, getState)
      .then(res => res.data)
      .then((data) => {
        toast.success(`La recherche à été supprimée`)
        dispatch({ type: USER_SAVED_SEARCH.deleted, payload: searchId })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_SAVED_SEARCH.deleting_error, error: error })
      })
  }
