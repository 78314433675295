import {
  QUERY
} from './constants'

export const initialState = {
  collections: {},
};

export const initialItemState = {
  loading: true,
  error: false,
  items: [],
  userFilter: {}
};

const reduceItem = (state = initialItemState, {type, payload}) => {
  switch (type) {
    case QUERY.fetching:
      return {
        ...state,
        loading: true
      };
    case QUERY.fetching_error:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case QUERY.fetched:
      return {
        ...state,
        loading: false,
        error: false,
        items: payload.results,
      };
    case QUERY.filter:
      return {
        ...state,
        userFilter: payload.inputs,
      };
  }
}

export default function reducer (state = initialState, {type, payload}) {
  if(-1 === [
    QUERY.fetching,
    QUERY.fetching_error,
    QUERY.fetched,
    QUERY.filter
  ].indexOf(type)) {
    return state;
  }

  return {
    ...state,
    collections: {
      ...state.collections,
      [payload.id]: reduceItem(state.collections[payload.id] || initialItemState, {type, payload}),
    },
  }
}
