import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import Socials from '../../components/Socials/Socials'

import { getCategoryUri, getMainSiteUri } from '../../router'
import routes from '../../routes'
import ControlledLoadableLink from './ControlledLoadableLink'
import { makeSelectResource as makeSelectCategoryResource } from '../../modules/category/selectors'

import './header/Header.scss'
import Image from '../../app/utils/Image'
import { logout } from '../../modules/authentication/actions'
import NewsletterRegister from '../newsletter/NewsletterRegister'

const MainLogo = () => <Image image={{ publicPath: '/assets/main-logo-mini.jpg', altText: 'Portail du CRIJ BFC' }} />
const ExternalMainLink = ({ link }) => (
  <a href={link} className='logo'>
    <MainLogo />
  </a>
)

const SecondaryLogo = ({ category }) => <Image image={category.logo} />

const MainLogos = ({ isMainDomain, category }) => (
  <>
    {isMainDomain
      ? (
        <ControlledLoadableLink routeName={routes.HOME} className='logo' to={'/'}>
          <MainLogo />
        </ControlledLoadableLink>
      ) : <ExternalMainLink link={getMainSiteUri()} />}

    {(category && category.logo) && (
      <ControlledLoadableLink routeName={routes.CATEGORY} className='logo' to={getCategoryUri(category)}>
        <SecondaryLogo category={category} />
      </ControlledLoadableLink>
    )}
  </>
)

const Header = ({ user = null, isMainDomain, category, handleLogout, isPersonalSpace = false }) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <header className={classnames('header', { 'active': isActive })}>
      <div className='left'>
        <MainLogos {...{ isMainDomain, category }} />
        {user &&
          <ul className='is-hidden-mobile is-hidden-tablet-only menu'>
            <li>
              <b>{user.firstName} {user.lastName}</b>
              <br/>
              <a className="button is-danger is-small" color='is-danger' onClick={handleLogout}>
                <i className="fa fa-sign-out-alt"/>
                <span>Déconnexion</span>
              </a>
            </li>
          </ul>
        }
      </div>
      <div className='right'>
        <ul className='menu'>
          <li className={!user ? 'double' : undefined}>
            <Link to={'/espace-perso'}>
              <i className="far fa-user" />
              <br/>
              {user ? "Espace perso" : "Se connecter ou s'inscrire"}
            </Link>
          </li>
          {user && (
            <li className="is-hidden-desktop">
              <b>{user.firstName} {user.lastName}</b>
              <br/>
              <a className="button is-danger is-small" onClick={handleLogout}>
                <i className="fa fa-sign-out-alt"/>
                <span>Déconnexion</span>
              </a>
            </li>
          )}
          <li>
            <Link to={routes.CONTACT}>
              <i className='far fa-question-circle' />
              <br/>
              Besoin d'aide ?
            </Link>
          </li>
          <li>
            <NewsletterRegister />
          </li>
        </ul>
        <div className='toggleMenu' onClick={() => setIsActive(!isActive)}>
          <i className={'fas fa-bars'} />Menu
        </div>
        <Socials />
      </div>
    </header>
  )
}

const mapStateToProps = (state, props) => ({
  category: makeSelectCategoryResource()(state, props),
  user: state.user.item,
  isMainDomain: state.domain.isMain
})

const mapDispatchToProps = (dispatch) => ({
  handleLogout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
