/**
 * preload
 *
 * Centralize preload logic for routes/pages
 */
import routes from './routes.json'

export default [
  {
    path: routes.SECTION,
    preLoad: () => {
      //console.log('before sleep, before locationChange')
      return new Promise(resolve => setTimeout(resolve, 0))
        //.then(() => console.log('after sleep, before locationChange'))
    }
  },
  {
    path: routes.CONTENT,
    preLoad: () => {
      //console.log('before sleep, before locationChange')
      return new Promise(resolve => setTimeout(resolve, 0))
        //.then(() => console.log('after sleep, before locationChange'))
    }
  },
]
