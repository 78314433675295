
/** transform api object to domain object */
export const transform = ({
  webSite, consentWebSite,
  ...organism }) => ({
  ...organism,
  webSite: { value: webSite, consent: consentWebSite }
})

/** transform domain object to api object */
export const deTransform = ({ webSite, ...organism }) => ({
  ...organism,
  webSite: webSite.value,
  consentWebSite: webSite.consent
})
