import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

import { makeSelectCollection as makeSelectPublicRequestCollection } from '../publicPostJobRequests/selectors'

const selectNamespace = state => state[STATE_KEY] || initialState
const selectPostProps = (state, props) => props.post

export const makeSelectCollection = () =>
  createSelector(
    [selectNamespace, makeSelectPublicRequestCollection()],
    ({ items }, publicRequests) => publicRequests.filter(item => items.includes(item.id))
  )

export const makeSelectLoading = () =>
  createSelector(
    selectNamespace,
    state => state.loading
  )

export const makeSelectSaving = () =>
  createSelector(
    selectNamespace,
    state => state.saving
  )

export const makeSelectResource = () =>
  createSelector(
    [selectNamespace, selectPostProps],
    ({ items }, { id }) => items.find(item => item === id)
  )
