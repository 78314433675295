import React, {useState, useEffect} from 'react'
import { initializeQuery, filterQuery } from '../../modules/query/actions'
import { connect } from 'react-redux'
import translations from '../../data/translations.json'
import {invert, values} from 'underscore'
import FloraCard from './ContentFlora/FloraCard'
import {
  makeSelectResults,
  makeSelectLoading,
  makeSelectError,
  makeSelectUserFilter
} from '../../modules/query/selectors'

import './ContentFlora/ContentFlora.scss'

const normalizeString = (text) =>
  text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace('"', "")
    .replace("'", "")
    .replace(" ", "-")
    .toLowerCase()
    .trim()



const FilterBar = ({filters, initialInputs, handleSubmit = (userInputs) => {}}) => {
  const [inputs, setInputs] = useState(initialInputs);
  return (
    <form className="columns is-variable is-1">
      {filters.map(({name, type = 'text', placeholder, choices = []}) => {
        const params = {
            id: 'input-'+name,
            name,
            onChange: (e) => setInputs({
              ...inputs,
              [name]: e.target.value
            })
        };

        switch (type) {
          case 'choice':
            return (
              <div key={name} className="field column">
                <label htmlFor={'input-'+name} className="label">{placeholder}</label>
                <div className="control is-expanded">
                  <div className="select" style={{width: '100%'}}>
                    <select
                      {...params}
                      defaultValue={inputs[name]}
                      style={{width: '100%'}}
                    >
                      <option key={'choice-empty'} value=''/>
                      {choices.map(choice => (
                        <option key={choice} value={choice}>
                          {choice}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            );
        }

        return (
          <div key={name} className="field column">
            <label htmlFor={'input-'+name} className="label">{placeholder}</label>
            <div className="control is-expanded">
              <input {...params} {...{type, value: inputs[name]}} className="input" />
            </div>
          </div>
        );
      })}
      <div className="field column is-1">
        <div className="control">
          <label className="label" style={{visibility: 'hidden'}}>Filtrer</label>
          <button
            className="button is-primary is-fullwidth"
            onClick={e => {
              e.preventDefault();
              handleSubmit(inputs);
            }}>
              Filtrer
          </button>
        </div>

        {/*<div className="control">
          <label className="label is-hidden">Réinitialiser</label>
          <button className="button primary">Réinitialiser</button>
        </div>*/}
      </div>
    </form>
  );
}

const maxItems = 6

const ContentFlora = ({doInitialize, doFilter, query, loading, error, filterValues = {}, results = []}) => {
  const {id, entity} = query;
  const publicFilters = values(query.publicFilters);
  const labels = invert(translations[entity]);

  const [nbItems, setNbItems] = useState({
    result: maxItems
  })
  useEffect(() => {
    doInitialize(id);
  }, [1]); // Only run the effect once

  if(loading) {
    return <p>Chargement du moteur de recherche ...</p>;
  }
  if(error) {
    return 'error ... '+error;
  }

  const initialInputs = publicFilters.reduce(
    (res, {field}) => {
      res[field] = filterValues[field] || '';
      return res;
    }, {}
  );

  const filters = publicFilters.map(({field, values}) => {
    const input = {
      name: field,
      placeholder: labels[field],
      value: initialInputs[field]
    };

    if(! values) {
      return input;
    }

    return {
      ...input,
      type: 'choice',
      choices: values.split(',')
    };
  });
  const filteredResults = results.filter(result => {
    for(const keyInput in filterValues) {
      if(!filterValues[keyInput].length || !result.hasOwnProperty(keyInput)) {
        continue;
      }

      const r = normalizeString(result[keyInput]);
      const f = normalizeString(filterValues[keyInput]);

      if(-1 === r.indexOf(f)) {
        return false;
      }
    }
    return true
  });

  return (
    <div className={'contentFlora'}>
      <FilterBar {...{filters, initialInputs}} handleSubmit={userInputs => doFilter(id, userInputs)} />
      <div className='column is-fullwidth'>
        {filteredResults.length
          ? <h4>Il y a {filteredResults.length} résultat{filteredResults.length > 1 && 's'}.</h4>
          : <p>Aucun résultat.</p>
        }
      </div>
      <div className='columns is-multiline'>
        {filteredResults.slice(0, nbItems.result).map(data => <FloraCard key={data.id} {...data}  />)}
      </div>

      {
        nbItems.result < filteredResults.length && (
          <button className='button is-link is-fullwidth' onClick={() => setNbItems({ result: nbItems.result + maxItems })}>
            voir plus
          </button>
        )
      }
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    results: makeSelectResults()(state, props),
    loading: makeSelectLoading()(state, props),
    error: makeSelectError()(state, props),
    filterValues: makeSelectUserFilter()(state, props)
  }
}
const mapDispatchToProps = dispatch => ({
  doInitialize: filterId => dispatch(initializeQuery(filterId)),
  doFilter: (queryId, inputs) => dispatch(filterQuery(queryId, inputs)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContentFlora)
