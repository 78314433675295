import React, { useState } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import routes from '../../routes.json'
import { toast } from 'react-toastify'

import './searchBar/SearchBar.scss'

const SearchBar = ({ submit = () => {}, submitedData = '' }) => {
  const [data, setData] = useState({ search: submitedData })
  const handleSubmit = (data) => {
    var dataTrim = data.search.trim()
    dataTrim.length < 3
      ? toast.error('Vous devez rechercher avec au moins 3 caractères', { toastId: 'SearchBar.error' })
      : submit(data)
  }

  return (
    <form className='searchBar' onSubmit={e => { e.preventDefault(); handleSubmit(data) }}>
      <div className='field has-addons'>
        <div className='control is-expanded'>
          <input
            className='input'
            type='text'
            placeholder='Rechercher'
            onChange={e => setData({ search: e.target.value })}
            value={data.search}
          />
        </div>
        <div className='control'>
          <button
            type='submit'
            className='button is-info'
          >
            <i className={'fa fa-search'} />
          </button>
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = () => ({

})

const mapDispatchToProps = (dispatch) => ({
  submit: (data) => { dispatch(push({ pathname: routes.SEARCH, search: 'recherche=' + data.search })) }
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)
