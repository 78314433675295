import React from 'react'
import ModalPortal from '../../utils/modal/ModalPortal'
import Modal from '../../utils/modal/Modal'

export default ({onConfirmation, onClose, children}) => {
  return (
    <ModalPortal>
      <Modal onClose={onClose}>
        <div className='box has-text-centered'>
          {children}
          <br />
          <div className='buttons is-centered are-medium'>
            <button
              className='button is-danger'
              type='button'
              onClick={() => {
                onConfirmation()
                onClose()
              }}
            >
              <span>Supprimer</span>
            </button>
            <button className='button' type='button' onClick={onClose}>
              <span>Annuler</span>
            </button>
          </div>
        </div>
      </Modal>
    </ModalPortal>
  )
}
