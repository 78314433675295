import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

const selectNamespace = state => state[STATE_KEY] || initialState
// const selectRouterMatch = (state, props) => props.match

export const makeSelectIsMain = () =>
  createSelector(
    selectNamespace,
    ({ isMain }) => isMain
  )
