import React from 'react'
import classnames from 'classnames'

import SectionLink from './SectionLink'
import Image from '../utils/Image'

export default ({ section, selectedSection, active, ...props }) => (
  <SectionLink
    className={classnames('section', {
      active: selectedSection && selectedSection.slug === section.slug
    })}
    style={{ backgroundColor: section.color }}
    section={section}
    {...props}
  >
    <Image image={section.icon} className='icon' />
    {section.title}
  </SectionLink>
)
