import React from 'react'
import { connect } from 'react-redux'
import {sanitize} from 'dompurify'
import classnames from 'classnames'

import Image from '../utils/Image'
import DatesBlock from './postJobItem/DatesBlock'
import LocationBlock from './postJobItem/LocationBlock'
import TagListBlock from './postJobItem/TagListBlock'
import ListBlock from './postJobItem/ListBlock'
import InfoBlock from './postJobItem/InfoBlock'
import BoolRequiredBlock from './postJobItem/BoolRequiredBlock'

import { getCategoryPostJobItemConfig } from '../../modules/postJobCategories/postJobCategoryFormHelper'
import { makeSelectPostJobOfferItemConfig } from '../../modules/config/selectors'

import './postJobItem/PostJobItem.scss'

const postJobItemConfigToComponent = (config, post) =>
  config.map(({name, label, blockType = null}) => {
    switch (blockType) {
      case 'tagList':
        return <TagListBlock key={name} title={label} items={post[name]} />
      case 'list':
        return <ListBlock key={name} title={label} items={post[name]} />
      case 'boolRequired':
        return <BoolRequiredBlock key={name} title={label} item={post[name]} />
    }
    return <InfoBlock key={name} title={label} item={post[name]} />
  }
)

const PostJobItem = ({ post, allPostJobItemConfig, ...props }) => {
  const postJobItemConfig = getCategoryPostJobItemConfig(post, allPostJobItemConfig);

  const postJobCommonConfig = [
    {
      label: "Nombre de postes",
      name: "jobsCount"
    },
    {
      label: "Rémunération / Indemnité",
      name: "remuneration"
    }
  ]

  const {jobPicture, description} = post;

  const PostJobDetailsComponent =
      post => postJobItemConfigToComponent(postJobItemConfig, post)

  const PostJobCommonComponent =
      post => postJobItemConfigToComponent(postJobCommonConfig, post)

  return (
    <div className="post-item">
      <div className="columns is-multiline">
        <div className="key-infos column is-5">
          <div className="thumb">
            <Image image={jobPicture} />
          </div>
          <DatesBlock {...post} />
          <LocationBlock {...post} />
        </div>
        <div className="post-content column is-7">
          <p
            className="description"
            dangerouslySetInnerHTML={{__html: sanitize(description)}}
          />
          <div className="columns is-multiline is-mobile">
            <PostJobCommonComponent {...post} />
          </div>
          <br />
          <div className="columns is-multiline is-mobile">
            <PostJobDetailsComponent {...post} />
          </div>
        </div>
        <div className="owner-infos column is-12">
          <h3 className="is-size-3">
            Comment postuler ?
          </h3>
          <div className="columns">
            {post.comment && (
              <div className="column is-6">
                <p>Lorem ipsum <b>dolor sit amet</b>, consectetur <i>adipiscing</i> elit. <br/>Ut nec eros non nisl rutrum ultrices. In et odio ut mauris sollicitudin ultrices. Fusce condimentum pellentesque eros eget consequat. Nunc eget sollicitudin urna, <br/>quis suscipit mauris. Aliquam id tristique lorem, eget <br/>dignissim ligula. Donec commodo mi ac purus hendrerit elementum. Curabitur nec neque id nulla mollis consequat quis ut ligula.</p>
              </div>
            )}
            <div className={classnames("contact column", {'is-6': post.comment, 'is-12': !post.comment})}>
              <a
                className="button"
                href={`mailto:${post.organismEmail || post.email}`}
              >
                <i className="fas fa-envelope" /> Envoyer un message
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state, props) => ({
  allPostJobItemConfig: makeSelectPostJobOfferItemConfig()(state, props),
})

export default connect(mapStateToProps)(PostJobItem)

