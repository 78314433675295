export const STATE_KEY = 'user_saved_searches'

export const USER_SAVED_SEARCHES = {
  fetching: `${STATE_KEY}/FETCHING`,
  fetched: `${STATE_KEY}/FETCHED`,
  fetching_error: `${STATE_KEY}/FETCHING_ERROR`
}

export const USER_SAVED_SEARCH = {
  creating: `${STATE_KEY}/CREATING`,
  created: `${STATE_KEY}/CREATED`,
  creating_error: `${STATE_KEY}/CREATING_ERROR`,

  updating: `${STATE_KEY}/UPDATING`,
  updated: `${STATE_KEY}/UPDATED`,
  updating_error: `${STATE_KEY}/UPDATING_ERROR`,

  deleting: `${STATE_KEY}/DELETING`,
  deleted: `${STATE_KEY}/DELETED`,
  deleting_error: `${STATE_KEY}/DELETING_ERROR`
}
