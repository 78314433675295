import request from '../apiHelper'
import { toast } from 'react-toastify'
import { push } from 'connected-react-router'
import routes from '../../routes.json'
import { CONTACT_MESSAGE } from './constant'

export function postContactMessage (contactMessageForm) {
  return dispatch => {
    dispatch({ type: CONTACT_MESSAGE.sending })

    return request({endpoint: 'contactmessages', method: 'POST', data: contactMessageForm})
      .then(res => res.data)
      .then(({data}) => {
        dispatch({ type: CONTACT_MESSAGE.sent, data })
        toast.success("Votre message à bien été envoyé, vous recevrez une réponse à l'adresse email renseignée", { toastId: 'ContactMessage.succes' })
        dispatch(push(routes.HOME))
      })
      .catch(error => {
        dispatch({ type: CONTACT_MESSAGE.sending_error, data: error })
        toast.error(`Le formulaire n'est pas valide`, { toastId: 'ContactMessage.error' })
      })
  }
}
