// import { POST_JOB_CATEGORY } from './constants'

// initial state
export const initialState = {
  items: []
}

export default function (state = initialState, action) {
  return state
}
