import React from 'react'
import { isObject } from 'lodash'
import TextInput from './input/TextInput'
import Select from './input/Select'
import DateInput from './input/DateInput'
import FieldWrapper from './FieldWrapper'
import CheckboxInput from './input/CheckboxInput'
import TextareaInput from './input/TextareaInput'
import CheckboxCollectionInput from './input/CheckboxCollectionInput'

// format fieldsConfig
/*
const fieldsConfig = [
  // all attributes
  {
    name: 'attrName',
    type: 'inputType', // value=[htmlInputType|checkbox|choice|custom] ,default 'text'
    label: 'label',
    placeholder: 'placeholder', // default ""
    className: 'foo', // additional classname, can be an object since its passed to classnames()
    choices: ['value 1', 'value 2'],
    allowEmpty: true, // default true,
    Component: (props) => (<></>), // default null
  },
  // minimal (render text input)
  {
    name: 'attrName',
    label: 'label',
  },
  // select input
  {
    name: 'select',
    label: 'label',
    type: 'choice',
    choices: ['value 1', 'value 2'],
    allowEmpty: true, // create a default empty option
  },
  // custom input
  // must render the label itself!
  {
    name: 'custom input',
    label: 'label',
    type: 'custom',
    Component: ({name, setInput, label, placeholder, choices, allowEmpty, className, validation, errors, selectedValue, otherAttrToPass}) => (<></>)
    otherAttrToPass: 'foo',
  }
]
*/



/**
 * Build form given the fieldsConfig
 *
 * @param fieldsConfig []
 * @param inputs {[name]:{value:*,errors:[]}}
 * @param setInput (name, value) => {}
 * @returns {*}
 */
export default (fieldsConfig, inputs, setInput = (name, value) => {}, isDirty = true) =>
  fieldsConfig.map(({
    name,
    type = 'text',
    label,
    sectionList,
    categoryList,
    placeholder = '',
    choices = [],

    Component = null,
    className = '',
    validation = { required: false },
    ...rest
  }) => {
    const { required } = validation;
    // normalize input value & errors
    const {value, errors = []} =
      (isObject(inputs[name]) && inputs[name].hasOwnProperty('value'))
        ? inputs[name]
        : { value: inputs[name] }
    ;

    if(type === 'custom') {
      return (
        <Component
          key={name}
          {...{ name, setInput, label, placeholder, choices, className, validation, errors, isDirty, required }}
          {...rest}
          selectedValue={value}
        />
      );
    }

    if(type === 'checkbox') {
      return (
        <FieldWrapper {...{
          key: name,
          label: <>&nbsp;</>,
        }} {...{className, errors, isDirty}}>
          <CheckboxInput
            {...{name, id: 'input-' + name, label, required, value, setInput}}
          />
        </FieldWrapper>
      )
    }

    const MyComponent = getComponent(type);
    return (
      <FieldWrapper {...{
        key: name,
        id: 'input-'+ name,
        className,
        label,
        required,
        errors,
        isDirty
      }}>
        <MyComponent {...{
          id: 'input-'+ name,
          className: (isDirty && errors.length) ? 'is-danger' : '',
          choices,

          placeholder,
          value,
          name,
          type,
          onChange: (e) => setInput(name, e.target.type === 'checkbox' ? e.target.checked : e.target.value)
        }} />
      </FieldWrapper>
    );
  }
)

const getComponent = inputType => {
  switch (inputType) {
    case 'multiple_choice':
      return CheckboxCollectionInput;
    case 'choice':
      return Select;
    case 'date':
      return DateInput;
    case 'textarea':
      return TextareaInput;
  }
  return TextInput;
}
