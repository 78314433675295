import React from 'react'
import ArticleLink from './ArticleLink.js'
import './Article.scss'
import backgroundImageHelper from '../utils/backgroundImageHelper.js'

export default ({ category, section, article }) => {
  const parent = section || category
  return (
    <article className='article'>
      <div className="box">
      <ArticleLink {...{ category, article }}>
        <h4 className='section' style={parent.color ? { backgroundColor: parent.color } : {}}>
          {parent.title}
        </h4>
      </ArticleLink>
      <div className='thumb'>
        <ArticleLink {...{ category, article }}>
          <div className='image' style={backgroundImageHelper(article.thumbnail)} />
        </ArticleLink>
      </div>
      <ArticleLink {...{ category, article }}>
        <h2>{article.title}</h2>
      </ArticleLink>
      <p dangerouslySetInnerHTML={{ __html: article.introduction }} />
      </div>
    </article>
  )
}
