import React from 'react'
import {map, isObject} from 'lodash'
import '../../searchResults/SearchResults.scss'

const AddressBlock = ({address1, poBox, address2, address3, zipcod, commune, cedex}) => (
  <div className={'wIcon'}>
    <i className={'fas fa-lg fa-map-marker'} />
    {address1}{poBox && ` - BP ${poBox}`}<br/>
    {address2 &&  <>{address2}<br/></>}
    {address3 && <>{address3} <br/></>}
    {zipcod} {commune} {cedex}
  </div>
);
const PhoneBlock = ({phone1, phone2}) => (
  <div className={'wIcon'}>
    <i className={'fas fa-lg fa-phone'} />
    <a href={`tel:${phone1}`}>{phone1}</a><br/>
    {(phone2 && <a href={`tel:${phone2}`}>{phone2}</a>)}
  </div>
);
const EmailBlock = ({email}) => (
  <div className={'wIcon'}>
    <i className={'fas fa-lg fa-envelope'} />
    <a href={`mailto:${email}`}>{email}</a>
  </div>
);

const BasicBlock = ({data}) => (
  <div className={'infos'}>
    {data}
  </div>
);

export default ({name, address1, poBox, address2, address3, zipcod, commune, cedex, phone1, phone2, email, ...otherFields}) => (
  <div className="column is-one-third-desktop is-half-tablet is-half-desktop is-one-quarter-widescreen" style={{display: 'flex'}}>
    <article className="box media" style={{width: '100%'}}>
      <div className="media-left">
      </div>
      <div className="media-content">
        <div className="content">
          <strong>
            {name}
          </strong>
          {address1 && <AddressBlock {...{address1, poBox, address2, address3, zipcod, commune, cedex}}/>}
          {phone1 && <PhoneBlock {...{phone1, phone2}} />}
          {email && <EmailBlock {...{email}} />}
          {map(otherFields, (value, key) => (!isObject(value) && value.length) && <BasicBlock data={value} key={key} />)}
        </div>
      </div>
    </article>
  </div>
);