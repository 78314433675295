import request from '../modules/apiHelper'
import { toast } from 'react-toastify'

export const GET_RESEARCHES_USER = 'GET_RESEARCHES_USER'
export const GET_RESEARCHES_USER_SUCCESS = 'GET_RESEARCHES_USER_SUCCESS'
export const GET_RESEARCHES_USER_ERROR = 'GET_RESEARCHES_USER_ERROR'

const endpoint = 'research/user';

export function getResearchesUser (onSuccess = () => {}) {
  return dispatch => {
    dispatch({ type: 'GET_RESEARCHES_USER' })
    return request({endpoint})
      .then(res => res.data)
      .then(res => {
        dispatch({ type: 'GET_RESEARCHES_USER_SUCCESS', data: res.data })
        onSuccess()
      })
      .catch(error => {
        dispatch({ type: 'GET_RESEARCHES_USER_ERROR', data: error })
      })
  }
}

export const POST_RESEARCH = 'POST_RESEARCH'
export const POST_RESEARCH_SUCCESS = 'POST_RESEARCH_SUCCESS'
export const POST_RESEARCH_ERROR = 'POST_RESEARCH_ERROR'

export function postResearch (researchForm, type, isAlert = false) {
  return dispatch => {
    dispatch({ type: 'POST_RESEARCH' })
    const research = {
      type: type,
      category: researchForm.category + (researchForm.subCategory ? ' || ' + researchForm.subCategory : ''),
      filter: JSON.stringify(researchForm),
      isAlert: isAlert
    }
    return request({endpoint: 'researches', method: 'POST', data: research})
      .then(res => res.data)
      .then(res => {
        dispatch({ type: 'POST_RESEARCH_SUCCESS', data: res.data })
        toast.success(res.message)
      })
      .catch(error => {
        dispatch({ type: 'POST_RESEARCH_ERROR', error: error })
      })
  }
}

export const DELETE_RESEARCH = 'DELETE_RESEARCH'
export const DELETE_RESEARCH_SUCCESS = 'DELETE_RESEARCH_SUCCESS'
export const DELETE_RESEARCH_ERROR = 'DELETE_RESEARCH_ERROR'

export function deleteResearch (id, onSuccess = () => {}) {
  return dispatch => {
    dispatch({ type: 'DELETE_RESEARCH' })
    return request({endpoint: `researches/${id}`, method: 'DELETE'})
      .then(res => res.data)
      .then(res => {
        dispatch({ type: 'DELETE_RESEARCH_SUCCESS', data: res.data })
        onSuccess()
      })
      .catch(error => {
        dispatch({ type: 'DELETE_RESEARCH_ERROR', data: error })
      })
  }
}

export const PUT_TOGGLE_RESEARCH_ALERT = 'PUT_TOGGLE_RESEARCH_ALERT'
export const PUT_TOGGLE_RESEARCH_ALERT_SUCCESS = 'PUT_TOGGLE_RESEARCH_ALERT_SUCCESS'
export const PUT_TOGGLE_RESEARCH_ALERT_ERROR = 'PUT_TOGGLE_RESEARCH_ALERT_ERROR'

export function putToggleResearchAlert (id, onSuccess = () => {}) {
  return dispatch => {
    dispatch({ type: 'PUT_TOGGLE_RESEARCH_ALERT' })
    return request({endpoint: `researches/${id}`, method: 'PUT'})
      .then(res => res.data)
      .then(res => {
        dispatch({ type: 'PUT_TOGGLE_RESEARCH_ALERT_SUCCESS', data: res.data })
        onSuccess()
      })
      .catch(error => {
        dispatch({ type: 'PUT_TOGGLE_RESEARCH_ALERT_ERROR', data: error })
      })
  }
}