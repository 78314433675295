import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './CreateAccount.scss'
import Page from '../../../app/Page'
import { Helmet } from 'react-helmet'
import Layout from '../../../app/utils/Layout'
import CreateAccountForm from '../../../components/CreateAccountForm/CreateAccountForm'
import InfoUrl from '../../../components/InfoUrl/InfoUrl'

const CreateProAccount = ({ match, info }) => (
  <Page match={match} className='page page-create-account'>
    <Helmet>
      <title>Création de votre compte</title>
    </Helmet>
    <Layout title='Création de votre compte'>
      <CreateAccountForm />
    </Layout>
    <InfoUrl info={info} />
  </Page>
)

const mapStateToProps = (state, props) => ({
  info: state.config.info
})

export default withRouter(connect(mapStateToProps)(CreateProAccount))
