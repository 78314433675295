import { USER } from './constants';

export const initialState = {
  loading: true,
  creating: false,
  updating: false,
  error: false,
  item: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case USER.fetching:
      return {
        ...state,
        loading: true,
        error: false
      }
    case USER.fetched:
      return {
        ...state,
        loading: false,
        error: false,
        item: action.data
      }
    case USER.fetching_error:
      return {
        ...state,
        loading: false,
        error: true
      }

    case USER.creating:
      return {
        ...state,
        creating: true,
        error: false
      }
    case USER.created:
      return {
        ...state,
        loading: false,
        creating: false,
        error: false,
        item: action.data
      }
    case USER.creating_error:
      return {
        ...state,
        loading: false,
        creating: false,
        error: true
      }

    case USER.updating:
      return {
        ...state,
        updating: true,
        error: false
      }
    case USER.updated:
      return {
        ...state,
        loading: false,
        updating: false,
        error: false,
        item: action.data.user
      }
    case USER.updating_error:
      return {
        ...state,
        loading: false,
        updating: false,
        error: true
      }

    case 'USER_NOT_ORGANISM_OWNER':
      return {
        ...state,
        item: {
          ...state.item,
          isOrganismAdmin: false
        }
      }
  }
  return state
}
