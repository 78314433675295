import React from 'react'
import { connect } from 'react-redux'
import routes from '../../routes.json'

import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import { getContentUri } from '../../router'
import { makeSelectResourceFromContent } from '../../modules/section/selectors.js'
import { makeSelectResourceFromSection } from '../../modules/category/selectors.js'

const ContentLink = ({ content, children, ...props }) => (
  <ControlledLoadableLink
    routeName={routes.CONTENT}
    to={getContentUri(props.category, props.section, content)}
    {...props}
  >
    {children}
  </ControlledLoadableLink>
)

const mapStateToProps = (state, props) => {
  const section = makeSelectResourceFromContent()(state, props)
  return {
    section: section,
    category: makeSelectResourceFromSection()(state, { section, ...props })
  }
}

export default connect(mapStateToProps)(ContentLink)
