import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  makeSelectError,
  makeSelectLoading, makeSelectNewObject,
  makeSelectResource,
  makeSelectUpdating
} from '../modules/userPostJobOffers/selectors'
import routes from '../routes'

import ProtectedAreaPostJobItemPage from './userPostJob/ProtectedAreaPostJobItemPage'
import PostJobItemForm from './postJob/PostJobItemForm'
import PostJobTitle from './postJob/PostJobTitle'
import DeleteUserPostProcess from './userPostJob/DeleteUserPostProcess'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import LoadingOverlay from './utils/LoadingOverlay'
import { archivePostOffer, createPostJobOffer, updatePostJobOffer } from '../modules/userPostJobOffers/actions'
import { deTransform } from '../modules/userPostJobOffers/model'

const ProtectedAreaPostJobOfferCreatePage = ({
  match, location, loading, creating, error, post, handleSubmit
}) => {
  if(loading) {
    return <LoadingOverlay />;
  }
  if(error) {
    return 'error ... '+error;
  }

  return <>
    {creating && <LoadingOverlay />}
    <ProtectedAreaPostJobItemPage
      {...{match, location}}
      post={post}
      breadcrumbsMisc={[
        { label: 'Mes offres', routeName: routes.PROTECTED_AREA_JOB_OFFER_LIST, to: routes.PROTECTED_AREA_JOB_OFFER_LIST },
        { label: 'Nouvelle offre' }
      ]}
      titlePage={(
        <>
          <PostJobTitle title="Créer une nouvelle annonce" />
        </>
      )}
    >
      <PostJobItemContext
        {...{post, handleSubmit}}
        isLastStepConditionCallback={(newPost) => !!newPost.category.length}
      >
        <PostJobItemForm />
      </PostJobItemContext>
    </ProtectedAreaPostJobItemPage>
  </>;
}

/**
 * Serves as a temporary object holder
 *   In this context, holds a PostJobItem to be given to the Form component
 *   which must reload its config given the object state
 *
 * step1, form display category selector
 * submitting step1 refresh the Context state object
 * step2, form display other fields, object has category field populated
 * submitting step2 triggers given handleSubmit callback
 */
const PostJobItemContext = ({post, handleSubmit, isLastStepConditionCallback, children}) => {
  const [object, setObject] = useState(post);
  const [isLastStep, setIsLastStep] = useState(false);

  const tempHandleSubmit = (newObject) => {
    setObject(newObject);
    setIsLastStep(isLastStepConditionCallback(newObject, post));
  }

  return React.Children.only(
    React.cloneElement(children, {
      key: JSON.stringify(object),
      post: object,
      handleSubmit: isLastStep
        ? handleSubmit
        : tempHandleSubmit
    })
  )
}

const mapStateToProps = (state, props) => ({
  post: makeSelectNewObject()(state, props),
  loading: makeSelectLoading()(state, props),
  creating: makeSelectUpdating()(state, props),
  error: makeSelectError()(state, props)
})

const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (post) => dispatch(createPostJobOffer(post)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaPostJobOfferCreatePage)
