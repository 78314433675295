import React, { useState } from 'react'
import moment from 'moment'
import { reduce } from 'lodash'
import * as yup from 'yup'
import getFormHandler from '../../../modules/formHelper'
import ModalPortal from '../../utils/modal/ModalPortal'
import Modal from '../../utils/modal/Modal'
import classnames from 'classnames'
import FieldWrapper from '../../form/FieldWrapper'
import DateInput from '../../form/input/DateInput'

const formSchema = [
  {
    name: 'jobFound',
    validation: {type: yup.bool()},
  },
  {
    name: 'date',
    validation: {type: yup.date().nullable(true)},
  },
]

export default ({ onClose, onConfirmation }) => {
  const formHandler = getFormHandler(formSchema);
  const [inputs, setInputs] = useState(formHandler.initializeInputs({ date: moment() }));
  const hasErrors = reduce(inputs, (all, {errors}) => [...all, ...errors], []).length;
  const setInput = (name, value) => formHandler.setInput(setInputs, name, value);

  return (
    <ModalPortal>
      <Modal onClose={onClose} hasCloseButton={false}>
        <div className='box'>
          <h5 className='is-size-4 has-text-centered'>
            Le poste a t-il été pourvu ?
          </h5>
          <form className="columns is-multiline is-variable is-1">
            <div className="field column is-6">
              <div className="control box">
                <label htmlFor="input-jobFound-1" className="radio" style={{width: '100%'}}>
                  <div style={{marginBottom: '1rem'}}>
                    <input id="input-jobFound-1"
                           type="radio"
                           name="jobFound"
                           onChange={() => setInput('jobFound', true)}
                     /> <span>Oui</span>
                  </div>
                  <FieldWrapper {...{className: 'is-12 is-paddingless', errors:[], label: 'à quelle date ?'}}
                    onClick={() => setInput('jobFound', true)}>
                    <DateInput
                      {...{
                        disabled: !inputs.jobFound.value,
                        id: 'input-date',
                        value: inputs.date.value,
                        name: 'date',
                        onChange: (e) => setInput('date', e.target.value)
                      }}
                    />
                  </FieldWrapper>
                </label>
              </div>
            </div>
            <div className="field column is-6">
              <div htmlFor="input-jobFound-2" className="control box" style={{height: '100%'}}>
                <label className="radio">
                  <input id="input-jobFound-2" type="radio" name="jobFound"
                         onChange={() => setInput('jobFound', false)}
                  /> <span>Non</span>
                </label>
              </div>
            </div>

            <div className="field column is-12">
              <div className="control has-text-centered">
                <button
                  disabled={hasErrors}
                  className={classnames("button is-primary is-fullwidth")}
                  onClick={e => {
                    e.preventDefault();
                    onConfirmation(formHandler.mergeInputs({}, inputs))
                  }}>
                  Valider
                </button>
                <br/>
                <a onClick={onClose}>Annuler</a>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </ModalPortal>
  )
}
