import React, { useState } from 'react'
import * as yup from 'yup'
import { reduce } from 'lodash'

import ConfirmationModalHandler from '../utils/ConfirmationModalHandler'
import ModalPortal from '../utils/modal/ModalPortal'
import Modal from '../utils/modal/Modal'
import getFormHandler from '../../modules/formHelper'

const formSchema = [
  {
    name: 'email',
    validation: { type: yup.string().email(), required: true }
  }
]

const InviteCollaboratorModal = ({ onConfirmation, onClose, children }) => {
  const formHandler = getFormHandler(formSchema)
  const [inputs, setInputs] = useState(formHandler.initializeInputs({ email: '' }))
  const hasErrors = reduce(inputs, (all, { errors }) => [...all, ...errors], []).length
  const setInput = (name, value) => formHandler.setInput(setInputs, name, value)

  return (
    <ModalPortal>
      <Modal onClose={onClose}>
        <div className='box has-text-centered'>
          {children}
          <br />
          <form>
            <div className='field'>
              <div className='control'>
                <input className={`input`} name='email' onChange={(e) => setInput('email', e.target.value)} type='email' value={inputs.email.value} required />
              </div>
            </div>
            <div className='buttons is-centered are-medium'>
              <button
                disabled={hasErrors}
                className='button is-success'
                type='button'
                onClick={(e) => {
                  e.preventDefault()
                  onConfirmation(formHandler.mergeInputs({}, inputs))
                  onClose()
                }}
              >
                <span>Inviter</span>
              </button>
              <button className='button' type='button' onClick={onClose}>
                <span>Annuler</span>
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </ModalPortal>
  )
}

/**
 * Display multi modal if published offer, confirmation only otherwise
 */
export default ({ handleInviteCollaborator, className = 'tag is-danger', actionerLabel = '' }) => (
  <MyConfirmationModalHandler {...{
    className,
    actionerLabel,
    onConfirmation: (inputs) => handleInviteCollaborator(inputs)
  }} />
)

const MyConfirmationModalHandler = ({ onConfirmation, className = 'tag is-success', actionerLabel = '' }) => (
  <ConfirmationModalHandler
    {...{ actionerLabel, className, onConfirmation }}
    Modal={InviteCollaboratorModal}
    icon='fas fa-plus'
    modalChildren={(
      <>
        <h5 className='is-size-4'>
          Entrez l'email du collaborateur à inviter :
        </h5>
      </>
    )}
  />
)
