import React from 'react'
import { connect } from 'react-redux'
import { makeSelectCollection } from '../../modules/postJobCategories/selectors'
import classnames from 'classnames'

import './postJobCategoryList/PostJobCategoryList.scss'

const emptyLabel = 'Toutes les catégories';

const CategoryItem = ({title, value, subCategories = [], handleSelect, selectedValue, ...props}) => (
  <div {...props}>
    <b
      className={classnames('category-title', {selected: JSON.stringify(selectedValue) === JSON.stringify(value)})}
      onClick={e => handleSelect({...e, target:{value}})}
    >
      {title}
    </b>
    {subCategories && (
      <div className={'subcategories'}>
        {subCategories.map(subCategory => {
          const subValue = {category: title, subCategory};
          return (
            <div key={'subcat-'+subCategory}
                 className={classnames('subcategory-title', {
                   selected: JSON.stringify(selectedValue) === JSON.stringify(subValue)
                 })}
                 onClick={e => handleSelect({...e, target:{value: subValue}})}
            >
              {subCategory}
            </div>
          )
        })}
      </div>
    )}
  </div>
)

const PostJobCategoryList = ({categories = [], selectedValue = {category:''}, handleSelect = (e) => {}, allowEmpty = true}) => (
  <div className='category-list columns is-variable is-1 is-multiline has-text-centered is-marginless' style={{background: '#fff'}}>
    {allowEmpty && <CategoryItem
      className='column is-12'
      {...{title: emptyLabel, value: {category:''}, handleSelect, selectedValue }}
    />}
    {categories.map(({title, subCategories = []}) =>
      <CategoryItem
        className='column is-6'
        key={'cat-'+title}
        {...{title, value: {category: title}, handleSelect, subCategories, selectedValue }}
      />
    )}
  </div>
)

const mapStateToProps = (state, props) => ({
  categories: makeSelectCollection()(state, props),
})

export default connect(mapStateToProps)(PostJobCategoryList)
