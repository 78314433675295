import React, { useState } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'

import {
  makeSelectResource as makeSelectCategoryResource,
  makeSelectCollection as makeSelectCategoryCollection
} from '../../modules/category/selectors'
import { makeSelectCollection as makeSelectSectionCollection } from '../../modules/section/selectors'

import { Helmet } from 'react-helmet'
import { makeSelectCollectionForSearchContents } from '../../modules/content/selectors'
import './SearchResults.scss'
import ContentItem from '../contents/ContentItem'
import { makeSelectCollectionForSearchArticles } from '../../modules/article/selectors'
import ArticleItem from '../articles/ArticleItem'
import ControlledBreadcrumbs from '../utils/ControlledBreadcrumbs'
import Page from '../Page'
import SearchBar from '../utils/SearchBar'
import Layout from '../utils/Layout'

const maxItems = 6

const SearchResults = ({ match, category, location, searchContents, searchArticles, categories, sections, ...props }) => {
  const [nbItems, setNbItems] = useState({
    content: maxItems,
    article: maxItems,
    job: maxItems
  })
  const params = queryString.parse(location.search)

  return (
    <Page match={match} className='searchResults'>
      <Helmet>
        <title>Recherche</title>
      </Helmet>

      <ControlledBreadcrumbs {...{ category, misc: [{ label: 'Recherche' }] }} />
      <SearchBar submitedData={params.recherche} />

      <Layout title={params.recherche ? `Résultats de la recherche "${params.recherche}"` : 'Rien à rechercher'} >
        {
          searchContents.length > 0 && (
            <div className='searchBox'>
              <h2>Sous Rubriques</h2>
              <div className='itemList'>
                {
                  searchContents.slice(0, nbItems.content).map(content => (
                    <ContentItem key={content.id} content={content} />
                  ))
                }
              </div>
              {
                nbItems.content < searchContents.length &&
                  <button className='button is-link is-fullwidth' onClick={() => setNbItems({ ...nbItems, content: nbItems.content + maxItems })}>voir plus</button>
              }
            </div>
          )
        }
        {
          searchArticles.length > 0 &&
          <div className='searchBox'>
            <h2>Actualités</h2>
            <div className='itemList'>
              {
                searchArticles.slice(0, nbItems.article).map(article => (
                  <ArticleItem
                    key={article.id}
                    category={categories.find(category => article.categories[0] && article.categories[0].id === category.id)}
                    section={sections.find(section => article.section && article.section.id === section.id)}
                    article={article} />
                ))
              }
            </div>
            {
              nbItems.article < searchArticles.length &&
              <button className='button is-link is-fullwidth' onClick={() => setNbItems({ ...nbItems, article: nbItems.article + maxItems })}>voir plus</button>
            }
          </div>
        }
        {
          !searchContents.length && !searchArticles.length &&
            <div>
              Il n'y a pas de résultat pour cette recherche.
            </div>
        }
      </Layout>
    </Page>

  )
}

const mapStateToProps = (state, props) => ({
  category: makeSelectCategoryResource()(state, props),
  searchContents: makeSelectCollectionForSearchContents()(state, props),
  searchArticles: makeSelectCollectionForSearchArticles()(state, props),
  categories: makeSelectCategoryCollection()(state, props),
  sections: makeSelectSectionCollection()(state, props)
})

export default connect(mapStateToProps)(SearchResults)
