import React from 'react'
import { connect } from 'react-redux'
import {
  makeSelectError,
  makeSelectLoading,
  makeSelectResource,
  makeSelectUpdating
} from '../modules/userPostJobOffers/selectors'
import routes from '../routes'

import PostJobItem from './postJob/PostJobItem'
import ProtectedAreaPostJobItemPage from './userPostJob/ProtectedAreaPostJobItemPage'
import { getPostJobOfferUri, getUserPostJobOfferEditUri } from '../router'
import PostJobTitle from './postJob/PostJobTitle'
import ControlledLoadableLink from './utils/ControlledLoadableLink'
import DeleteUserPostProcess from './userPostJob/DeleteUserPostProcess'
import PublishUserPostProcess from './userPostJob/PublishUserPostProcess'
import LoadingOverlay from './utils/LoadingOverlay'
import { archivePostOffer, publishPostOffer } from '../modules/userPostJobOffers/actions'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'

const ProtectedAreaPostJobOfferPreviewPage = ({ match, location, loading, error, post = {}, handleArchivePost, handlePublishPost }) => {
  if(loading) {
    return <LoadingOverlay />;
  }
  if(error) {
    return 'error ... '+error;
  }
  if (!post) {
    toast.error(`Cette ressource est inaccessible`)
    return <Redirect to='/' />
  }

  const {id, title, status} = post;

  return (
    <ProtectedAreaPostJobItemPage
      {...{match, location}}
      post={post}
      breadcrumbsMisc={[
        { label: 'Mes offres', routeName: routes.PROTECTED_AREA_JOB_OFFER_LIST, to: routes.PROTECTED_AREA_JOB_OFFER_LIST },
        { label: title }
      ]}
      infoMessage={(
        <div className="notification is-warning">
          <i className="fa fa-exclamation-triangle"/> Ceci est une prévisualisation de votre annonce, Vous seul pouvez la voir.
          <br />
          {status === 'drafted' && (<>Votre annonce est <b>en brouillon</b>, vous devez la valider pour quelle soit visible publiquement.</>)}
          {status === 'waiting' && (<>Votre annonce est <b>en attente de validation</b>, elle n'est donc plus éditable. Vous recevrez un email lorsque celle-ci sera validé par un administrateur et disponible publiquement.</>)}
          {status === 'published' && (<>Votre annonce est <b>publiée</b>, elle est <a href={getPostJobOfferUri(post.id)} target="_blank">disponible publiquement</a> et n'est plus éditable.</>)}
        </div>
      )}
      titlePage={(
        <>
          <PostJobTitle {...post} />
          <div className="post-action action-mini buttons">
            {status === 'drafted' && (<>
              <ControlledLoadableLink
                className="button is-warning"
                routeName={routes.PROTECTED_AREA_JOB_OFFER_EDIT}
                to={getUserPostJobOfferEditUri(id)}
              >
                <i className="far fa-edit" style={{paddingRight:'0.5rem'}}/> Editer
              </ControlledLoadableLink>
              </>
            )}
            {(status === 'drafted') && (
              <PublishUserPostProcess
                {...{post, handlePublishPost}}
                className="button is-success"
                actionerLabel={<span style={{paddingLeft: '0.5rem'}}>Publier</span>}
              />
            )}

            <DeleteUserPostProcess
              {...{post, handleArchivePost}}
              className="button is-danger"
              actionerLabel={<span style={{paddingLeft: '0.5rem'}}>Supprimer</span>}
            />
          </div>
        </>
      )}
    >
      <PostJobItem post={post} />
    </ProtectedAreaPostJobItemPage>
  );
}

const mapStateToProps = (state, props) => ({
  post: makeSelectResource()(state, props),
  loading: makeSelectLoading()(state, props),
  updating: makeSelectUpdating()(state, props),
  error: makeSelectError()(state, props)
})
const mapDispatchToProps = (dispatch) => ({
  handleArchivePost: (post, archivingData) => dispatch(archivePostOffer(post, archivingData)),
  handlePublishPost: (post, archivingData) => dispatch(publishPostOffer(post, archivingData))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaPostJobOfferPreviewPage)
