import React from 'react'
import { connect } from 'react-redux'

import { makeSelectResource } from '../modules/publicPostJobOffers/selectors'

import PostJobItemPage from './postJob/PostJobItemPage'
import PostJobItem from './postJob/PostJobItem'
import routes from '../routes'
import ControlledSavePostJobOffer from './postJob/ControlledSavePostJobOffer'

const PublicPostJobOfferItemPage = ({ match, location, post }) => {
  return (
    <PostJobItemPage
      {...{match, location}}
      post={post}
      breadcrumbsMisc={[
        { label: 'Offres', routeName: routes.JOB_FILTER_OFFER, to: routes.JOB_FILTER_OFFER+'?summerJob=false' },
        { label: post && post.title }
      ]}
      ControlledSavePost={ControlledSavePostJobOffer}
    >
      <PostJobItem post={post} />
    </PostJobItemPage>
  );
}

const mapStateToProps = (state, props) => ({
  post: makeSelectResource()(state, props),
})

export default connect(mapStateToProps)(PublicPostJobOfferItemPage)
