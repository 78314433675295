import React from 'react'
import classnames from 'classnames'
import { isObject } from 'lodash'

export default ({className = null, value = '', choices = [], placeholder, allowEmpty = true, ...props}) => (
  <div className="control is-expanded">
    <div className={classnames('select', className)} style={{ width: '100%' }}>
      <select
        {...props}
        style={{ width: '100%' }}
        value={value}
        className={!value ? 'placeholder' : undefined}
      >
        {allowEmpty && <option key={'choice-empty'} id='placeHolder' value=''>{placeholder}</option>}
        {getNormalizedChoices(choices).map(choice => (
          <option key={choice.value} value={choice.value}>
            {choice.label}
          </option>
        ))}
      </select>
    </div>
  </div>
)

const getNormalizedChoices = choices => choices.map(choice => (!isObject(choice))
  ? {label: choice, value: choice}
  : choice
)