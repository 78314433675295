import React, { useState } from 'react'
import { connect } from 'react-redux'
import { reduce } from 'lodash'
import classnames from 'classnames'
import queryString from 'qs'
import '../../AccountForms.scss'
import formSchema from './formSchema'
import formSchemaOrganism from './formSchemaOrganism'
import emptyUser from './emptyUser'
import emptyOrganism from './emptyOrganism'
import buildForm from '../../app/form/buildForm'
import getFormHandler from '../../modules/formHelper'
import { transform, deTransform } from '../../modules/user/model'
import { transform as transformOrganism, deTransform as deTransformOrganism } from '../../modules/userOrganism/model'
import { createUser, createUserPro } from '../../modules/user/actions'

const CreateAccountForm = ({ user = transform(emptyUser), organism = transformOrganism(emptyOrganism), handleSubmit, handleSubmitPro, isProfessionalAccount = false, location }) => {
  user.roles = isProfessionalAccount ? ['ROLE_PROFESSIONAL'] : ['ROLE_USER']
  const formSchemaRole = formSchema.filter(({ roles }) => roles ? user.roles.some(r => roles.includes(r)) : true)
  // const invitationToken = queryString.parse(location.search).invitation_token
  // const invitationToken = true
  // if (invitationToken) {
  //   user.organism = 14
  //   user.email.value = 'test.test@test.test'
  //   formSchemaRole.find(({ name }) => name === 'email').disabled = true
  // }
  const formHandler = getFormHandler(formSchemaRole)
  const [inputs, setInputs] = useState(formHandler.initializeInputs(user))
  const hasErrors = reduce(inputs, (all, { errors }) => [...all, ...errors], []).length
  const setInput = (name, value) => formHandler.setInput(setInputs, name, value)

  const formHandlerOrganism = getFormHandler(formSchemaOrganism)
  const [inputsOrganism, setInputsOrganism] = useState(formHandlerOrganism.initializeInputs(organism))
  const hasErrorsOrganism = reduce(inputsOrganism, (all, { errors }) => [...all, ...errors], []).length
  const setInputOrganism = (name, value) => formHandlerOrganism.setInput(setInputsOrganism, name, value)

  return (
    <form className="columns is-multiline is-variable is-1">
      <div className="column is-12">
        <div className="columns is-multiline is-variable is-1">
          {buildForm(
            formSchemaRole
              .filter(({ group }) => group === 'default')
              .map(fieldConfig => ({ className: 'is-4', ...fieldConfig })),
            inputs,
            setInput
          )}
        </div>
        <div className="columns is-multiline is-variable is-1">
          <div className="column is-12">
            <h4 className="is-size-4">Coordoonées</h4>
          </div>
          {buildForm(
            formSchemaRole
              .filter(({ group }) => group === 'coordinates')
              .map(fieldConfig => ({ className: 'is-6', ...fieldConfig })),
            inputs,
            setInput
          )}
        </div>
        {
          isProfessionalAccount && (
              <>
                <div className="columns is-multiline is-variable is-1">
                  <div className="column is-12">
                    <h3 className="is-size-4">Mon organsime</h3>
                  </div>
                  {buildForm(
                    formSchemaOrganism
                      .filter(({ group }) => group === 'default')
                      .map(fieldConfig => ({ className: 'is-4', ...fieldConfig })),
                    inputsOrganism,
                    setInputOrganism
                  )}
                </div>
                <div className="columns is-multiline is-variable is-1">
                  <div className="column is-12">
                    <h4 className="is-size-4">Coordoonées</h4>
                  </div>
                  {buildForm(
                    formSchemaOrganism
                      .filter(({ group }) => group === 'coordinates')
                      .map(fieldConfig => ({ className: 'is-6', ...fieldConfig })),
                    inputsOrganism,
                    setInputOrganism
                  )}
                </div>
              </>
          )
        }
        <div className="columns is-multiline is-variable is-1">
          {buildForm(
            formSchemaRole
              .filter(({ group }) => group === 'other')
              .map(fieldConfig => ({ className: 'is-12', ...fieldConfig })),
            inputs,
            setInput
          )}
        </div>
        <div className="field column is-12">
          <div className="control">
            {
              isProfessionalAccount
                ? (
                  <button
                    disabled={hasErrors || hasErrorsOrganism}
                    className={classnames("button is-primary is-fullwidth")}
                    onClick={e => {
                      e.preventDefault()
                      handleSubmitPro(formHandler.mergeInputs(user, inputs), formHandlerOrganism.mergeInputs(organism, inputsOrganism))
                      // .then((apiResult) => {
                      //   // reset form
                      //   setInputs(formHandler.initializeInputs(apiResult))
                      // })
                    }}>
                    Créer mon compte professionel
                  </button>
                )
                : (
                  <button
                    disabled={hasErrors}
                    className={classnames("button is-primary is-fullwidth")}
                    onClick={e => {
                      e.preventDefault()
                      handleSubmit(formHandler.mergeInputs(user, inputs))
                      // .then((apiResult) => {
                      //   // reset form
                      //   setInputs(formHandler.initializeInputs(apiResult))
                      // })
                    }}>
                    Créer mon compte
                  </button>
                )
            }
          </div>
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = (state, props) => ({
})

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleSubmit: (user) => dispatch(createUser(deTransform(user))),
    handleSubmitPro: (user, organism) => dispatch(createUserPro(deTransform(user), deTransformOrganism(organism)))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountForm)
