import React from 'react'
import moment from 'moment'

import ModalHandler from '../utils/ModalHandler'
import RemoveModal from './UserPostJobCard/RemoveModal'
import FollowUpModal from './UserPostJobCard/FollowUpModal'
import ConfirmationModalHandler from '../utils/ConfirmationModalHandler'

/**
 * Display multi modal if published offer, confirmation only otherwise
 */
export default ({post, handleArchivePost, className="tag is-danger", actionerLabel=''}) => {
  if(post.status !== 'published') {
    return (
      <MyConfirmationModalHandler {...{
        post,
        className,
        actionerLabel,
        onConfirmation: () => handleArchivePost(post, {jobFound: false})
      }} />
    )
  }

  return (
    <ModalHandler
      actioner={(propsModal1) => (
        <MyConfirmationModalHandler {...{post, onConfirmation: propsModal1.onOpen, className, actionerLabel}} />
      )}
      // modal 2, follow up question
      modal={propsModal2 => (
        <FollowUpModal
          {...propsModal2}
          onConfirmation={(archivingData = {date: moment(), jobFound: false}) => {
            handleArchivePost(post, archivingData)
            propsModal2.onClose()
          }}
        />
      )}
    />
  )
}

const MyConfirmationModalHandler = ({post, onConfirmation, className="tag is-danger", actionerLabel=''}) => (
  <ConfirmationModalHandler
    {...{actionerLabel, className, onConfirmation}}
    Modal={RemoveModal}
    modalChildren={(
      <>
        <h5 className='is-size-4'>
          Êtes-vous sûr de vouloir supprimer cette annonce ?
        </h5>
        <p>{post.title}</p>
      </>
    )}
  />
)