// excuse me wtf
import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

import { makeSelectResource as makeSelectSection } from '../section/selectors'

import queryString from 'query-string'
import striptags from 'striptags'
import { sanitize } from 'dompurify'

const selectNamespace = state => state[STATE_KEY] || initialState
const selectRouterMatch = (state, props) => props.match
const selectQueryParamSearch = (state, props) => queryString.parse(props.location.search)

export const makeSelectCollection = () =>
  createSelector(
    [selectNamespace],
    ({ items }) => items
  )

export const makeSelectCollectionForSection = () =>
  createSelector(
    [selectNamespace, makeSelectSection()],
    ({ items }, { id = null }) => items.filter(item => item.section && item.section.id === id)
  )

export const makeSelectResource = () =>
  createSelector(
    [selectNamespace, selectRouterMatch],
    ({ items }, { params: { content } }) => {
      return items.find(item => item.slug === content)
    })

export const makeSelectCollectionForSearchContents = () =>
  createSelector(
    [selectNamespace, selectQueryParamSearch],
    ({ items }, { recherche = '' }) => items.filter(
      item => check(recherche, item.title || '') ||
      item.contentBlocks
        .filter(contentBlock => contentBlock.type === 'text')
        .find(contentBlock => check(recherche, contentBlock.title || '') || check(recherche, contentBlock.text || ''))
    )
  )

const check = (searchValue, text) => sanitize(striptags(text)).toLowerCase().includes(searchValue.toLowerCase())
