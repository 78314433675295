import React from 'react'

import routes from '../../routes.json'

import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import { getArticleUri } from '../../router'

export default ({ category, article, children, ...props }) => (
  <ControlledLoadableLink
    routeName={routes.ARTICLE}
    to={getArticleUri(category, article)}
    {...props}
  >
    {children}
  </ControlledLoadableLink>
)
