import React from 'react'
import { Helmet } from 'react-helmet'
import ControlledBreadcrumbs from '../utils/ControlledBreadcrumbs'
import routes from '../../routes.json'
import Page from '../Page'
import PostJobNavBar from './PostJobNavBar'

import PostJobTitle from './PostJobTitle'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router'
import Layout from '../utils/Layout'

export default ({ match, location, post, isLiked, handleToggleLike = () => {}, breadcrumbsMisc = [], ControlledSavePost, children }) => {
  if (!post) {
    toast.error(`Cette ressource est inaccessible`)
    return <Redirect to='/' />
  }

  const {title} = post;
  return (
    <Page match={match} className='page page-post'>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PostJobNavBar match={match} />
      <ControlledBreadcrumbs {...{misc: [
          { label: 'Jobs', routeName: routes.JOB_FILTER_OFFER, to: routes.JOB_FILTER_OFFER },
          ...breadcrumbsMisc,
        ]}} woSearchBar />

      <Layout title={(
        <>
          <PostJobTitle {...post} />
          <ControlledSavePost {...{post, location}} />
        </>
      )}>
        {children}
      </Layout>
    </Page>
  )
}
