import React, { useState } from 'react'

import ModalPortal from '../utils/modal/ModalPortal'
import Modal from '../utils/modal/Modal'

export default ({ onClose, onConfirmation }) => {
  const [inputs, setInputs] = useState({
    email: ''
  })
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    })
  }
  return (
    <ModalPortal>
      <Modal onClose={onClose}>
        <div className='box has-text-centered'>
          <h5 className='is-size-4'>
            S'inscrire à la Newsletter ?
          </h5>
          <br />
          <form onSubmit={e => { e.preventDefault(); onConfirmation(inputs, onClose) }}>
            <div className='field'>
              <label className='label'>Votre email</label>
              <div className='control'>
                <input className={`input`} name='email' onChange={handleChange} type='email' value={inputs.email} required />
              </div>
            </div>
            <div className='buttons is-centered are-medium'>
              <button
                className='button is-succes'
                type='submit'
              >
                <span>Valider</span>
              </button>
              <button className='button is-danger' type='button' onClick={onClose}>
                <span>Annuler</span>
              </button>
            </div>

          </form>
        </div>
      </Modal>
    </ModalPortal>
  )
}
