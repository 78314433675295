import React from 'react'
import PostJobCategoryField from './postJobFilter/PostJobCategoryField'
import { departmentList } from '../utils/RegionMap'
import * as yup from 'yup'
import GeoApiGouvAdresseField, { onlyCityApiConfig } from '../utils/GeoApiGouvAdresseField'
import {map} from 'lodash'

export default [
  {
    name: 'search',
    label: 'Recherche',
    type: 'text',
    placeholder: 'Mot clef',
    targets: ['title', 'comment'],
    validation: {
      type: yup.object()
    }
  },
  {
    name: 'category',
    label: 'Catégorie',
    type: 'custom',
    Component: PostJobCategoryField,
    validation: {
      type: yup.object().shape({
        category: yup.string(),
        subCategory: yup.string()
      }),
    },
    callback: (filterValue, result) => {
      if(filterValue.category) {
        if(filterValue.category !== result.category) {
          return false;
        }
        if(filterValue.subCategory && filterValue.subCategory !== result.subCategory) {
          return false;
        }
      }

      return true;
    }
  },
  {
    name: 'department',
    label: 'Département',
    type: 'choice',
    placeholder: 'Tous les départements',
    choices: [
      ...map(departmentList, (label, key) => ({ value: key, label }))
    ],
    allowempty: true,
    callback: (filterValue, result) =>{
      if(filterValue) {
        if(filterValue !== result.fullAddress.postcode.substr(0,2)) {
          return false;
        }
      }
      return true;
    }
  },

  {
    name: 'city',
    label: 'Ville',
    type: 'custom',
    Component: ({setInput, ...props}) =>
      <GeoApiGouvAdresseField
        {...props}
        apiConfig={onlyCityApiConfig}
        resultIsResetOnChange={false}
        key={(props.selectedValue && props.selectedValue.label) || ''}
        // cleanup result data
        setInput={(name, {label, postcode}) => setInput(name, {label, postcode})}
      />,
    callback: (filterValue, result) =>
      filterValue.postcode === result.fullAddress.postcode,
    validation: {
      type: yup.object()
    }
  },
  {
    name: 'summerJob',
    label: 'Job d\'été',
    type: 'checkbox',
    targets: ['summerJob'],
    callback: (filterValue, result) => {
      if (filterValue) {
        if (filterValue === 'false') {
          if (filterValue !== result.summerJob.toString()) {
            return true
          }
          return true
        }
        if (filterValue === result.summerJob.toString()) {
          return true
        }
      }
    }
  }
];