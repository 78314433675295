import React from 'react'
import './Stripe.scss'
import ContentLink from '../../app/contents/ContentLink'
import Image from '../../app/utils/Image'

export default ({ category, section, contents }) => (
  <div className='stripe' style={{ backgroundColor: section.color }}>
    <h3>{section.title}</h3>
    <ul className='menu'>
      {contents.map(item => (
        <li key={item.id}>
          <ContentLink
            category={category}
            section={section}
            content={item}
          >
            <i className='fa fa-angle-right' />
            <span>{item.title}</span>
          </ContentLink>
        </li>
      ))}
    </ul>
    {section.thumbnail && <div className='thumb'>
      <Image image={section.thumbnail} />
    </div>}
  </div>
)
