import React from 'react'
import { connect } from 'react-redux'
import { makeSelectResource } from '../modules/adminPostJobOffer/selectors'
import routes from '../routes'

import PostJobItem from './postJob/PostJobItem'
import PostJobTitle from './postJob/PostJobTitle'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import AdminPage from './admin/AdminPage'
import Layout from './utils/Layout'
import queryString from 'query-string'
import ConfirmationModalHandler from './utils/ConfirmationModalHandler'
import RemoveModal from './userPostJob/UserPostJobCard/RemoveModal'

const AdminPostJobOfferPreviewPage = ({ location, match, post = {} }) => {
  const { returnUrl } = queryString.parse(location.search);

  if (!post || !post.id) {
    toast.error(`Cette ressource est inaccessible`)
    return <Redirect to={returnUrl || '/admin'} />
  }

  const {id, title, status} = post;

  return (
    <AdminPage
      match={match}
      className='page page-post'
    >
      <div className="notification is-warning" style={{
        position: 'sticky', top: 0, zIndex: 10, margin: 0,
      }}>
        <div className="columns">
          <div className="column">
            {returnUrl && (
              <a href={returnUrl} className="button is-link is-small" style={{float: 'left', marginRight: '0.3rem'}}>
                <i className="fa fa-angle-left" />
              </a>
            )}
            <h5 className="is-size-5">
              ADMIN - Annonce</h5>
            <div className="is-hidden-mobile">
              {status === 'drafted' && (<>
                Cette annonce est <b>un brouillon</b>, aucune action attendue.
              </>)}
              {status === 'waiting' && (<>
                Cette annonce est <b>en attente de validation</b>, validez-la après vérification.
              </>)}
              {status === 'published' && (<>
                Cette annonce est <b>publiée</b>, vous pouvez toujours la retirer.
              </>)}
            </div>
          </div>
          <div className="column is-narrow buttons">
              {(status === 'waiting') && (
                <a className="button is-success" href={routes.ADMIN_POST_JOB_OFFER_VALIDATE.replace(':id', post.id)}>
                  <i className="fa fa-check" style={{paddingRight:'0.5rem'}}/> Publier
                </a>
              )}
            {(status === 'waiting' || status === 'published') && (
              <ConfirmationModalHandler
                className="button is-danger"
                Modal={RemoveModal}
                actionerLabel={
                  <span style={{paddingLeft: '0.5rem'}}>
                    {status !== 'published' ? 'Refuser' : 'Retirer'}
                  </span>
                }
                onConfirmation={() => {
                  window.location = routes.ADMIN_POST_JOB_OFFER_REFUSE.replace(':id', post.id);
                }}
                modalChildren={(
                  <>
                    <h5 className='is-size-4'>
                      Êtes-vous sûr de vouloir supprimer cette annonce ?
                    </h5>
                    <p>{post.title}</p>
                  </>
                )}
              />
            )}
          </div>
        </div>
      </div>

      <Layout
          title={(
          <>
            <PostJobTitle {...post} />
          </>
          )}>
        <PostJobItem post={post} />
      </Layout>

    </AdminPage>
  );
}

const mapStateToProps = (state, props) => ({
  post: makeSelectResource()(state, props)
})

export default connect(mapStateToProps)(AdminPostJobOfferPreviewPage)
