import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

import { makeSelectResource as makeSelectCategory } from '../category/selectors'
import { makeSelectResource as makeSelectArticle } from '../userArticle/selectors'

const selectNamespace = state => state[STATE_KEY] || initialState
const selectRouterMatch = (state, props) => props.match

export const makeSelectCollection = () =>
  createSelector(
    [selectNamespace],
    ({ items }) => items
  )

export const makeSelectCollectionForCategory = () =>
  createSelector(
    [selectNamespace, makeSelectCategory()],
    ({ items }, { id }) => items.filter(item => item.category && item.category.id === id)
  )

export const makeSelectResourceForArticle = () =>
  createSelector(
    [selectNamespace, selectRouterMatch, makeSelectArticle()],
    ({ items }, { section = { id: '' } }) => items.find(item => item.id && item.id === section.id) || null
  )

export const makeSelectResource = () =>
  createSelector(
    [selectNamespace, selectRouterMatch],
    ({ items }, { params: { section } }) => items.find(item => item.slug === section)
  )

export const makeSelectResourceFromTitle = (title) =>
  createSelector(
    [selectNamespace],
    ({ items }) => items.find(item => item.title === title)
  )

export const makeSelectResourceFromContent = () =>
  createSelector(
    [selectNamespace, (state, props) => props.content],
    ({ items }, content) => items.find(item => item.id === content.section.id)
  )
