import React from 'react'
import { connect } from 'react-redux'

import { Helmet } from 'react-helmet'
import { makeSelectResource } from '../modules/category/selectors'
import ControlledNavBar from './utils/ControlledNavBar'
import ControlledBreadcrumbs from './utils/ControlledBreadcrumbs'

import './partners/Partners.scss'
import Page from './Page'
import { makeSelectCollection as makeSelectPartnerCollection } from '../modules/partner/selectors'
import Image from './utils/Image'
import Layout from './utils/Layout'
import SearchBar from './utils/SearchBar'

const Partners = ({ match, partners = [], category = null }) => {
  return (
    <Page match={match} className='page page-content'>
      <Helmet>
        <title>Nos partenaires</title>
      </Helmet>

      {category && <ControlledNavBar selectedCategory={category} match={match} />}

      <ControlledBreadcrumbs {...{ category, misc: [{ label: 'Nos partenaires' }] }} />
      <SearchBar />

      <Layout title='Nos partenaires'>
        <ul className='partners-list'>
          {
            partners.map(partner => (
              <li key={partner.id} style={{ float: 'left' }}>
                <a href={partner.link} target='_blank'>
                  <Image image={partner.logo} className={'img-partner-list'}/>
                </a>
              </li>
            ))
          }
        </ul>
        {/* <div className='btnRetour'>
          <GoBack>Retour</GoBack>
        </div> */}
      </Layout>
    </Page>

  )
}

const mapStateToProps = (state, props) => ({
  partners: makeSelectPartnerCollection()(state, props),
  category: makeSelectResource()(state, props)
})

export default connect(mapStateToProps)(Partners)
