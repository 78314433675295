import React from 'react'
import { connect } from 'react-redux'

import routes from '../../routes.json'

import { makeSelectCollectionForCategory } from '../../modules/section/selectors'

import ArticlesNavBarLink from '../articles/ArticlesNavBarLink'
import SectionNavBarLink from '../sections/SectionNavBarLink'
import NavBar from '../NavBar'

import ControlledSubNavBar from './ControlledSubNavBar'

const RenderSubNavBar = ({ match, selectedContent, selectedSection, selectedCategory, section }) => {
  if (match.path !== routes.CONTENT ||
    !selectedContent ||
    selectedSection.id !== section.id) {
    return ''
  }

  return <ControlledSubNavBar {...{ match, selectedCategory, selectedSection, selectedContent }} />
}

export const ControlledNavBar = ({
  selectedCategory,
  selectedSection,
  selectedContent,
  match,
  sections = []
}) => {
  return (
    <NavBar withContents={match.path === routes.CONTENT}>
      <li>
        <ArticlesNavBarLink
          category={selectedCategory}
          active={match.path === routes.ARTICLES}
        />
      </li>
      {sections.map(section => (
        <li key={section.slug}>
          <SectionNavBarLink
            section={section}
            category={selectedCategory}
            selectedSection={selectedSection}
          />
          <RenderSubNavBar {...{ match, selectedContent, selectedSection, selectedCategory, section }} />
        </li>
      ))}
    </NavBar>
  )
}

const mapStateToProps = (state, props) => ({
  sections: makeSelectCollectionForCategory()(state, props)
})

export default connect(mapStateToProps)(ControlledNavBar)
