import React, { useState } from 'react'
import classnames from 'classnames'

require('./navBar/NavBar.scss')

export default ({ children, withContents = false }) => {
  const [toggled, setToggled] = useState(false)

  return (
    <nav className={classnames('navBar', { active: toggled })}>
      <span
        role='button'
        tabIndex={0}
        className='toggle'
        onClick={() => setToggled(!toggled)}
        onKeyPress={() => setToggled(!toggled)}
      >
        {toggled ? 'Masquer les rubriques' : 'Afficher les rubriques'}
      </span>
      <ul className={classnames('sections', { withContents })}>{children}</ul>
    </nav>
  )
}
