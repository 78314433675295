import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import LegalNotice from './app/legalNotice/LegalNotice'
import Contact from './app/contact/Contact'
import Partners from './app/Partners'
import SearchResults from './app/searchResults/SearchResults'
import ArticlePage from './app/ArticlePage'
import CreateProAccount from './containers/Account/CreateAccount/CreateProAccount'
import CreatePersonalAccount from './containers/Account/CreateAccount/CreatePersonalAccount'
//import EditRequest from './containers/Request/EditRequest/EditRequest'
import Redirect from 'react-router/Redirect'

import routes from './routes'
import ArticlesPage from './app/ArticlesPage'
import PublicPostJobOfferListPage from './app/PublicPostJobOfferListPage'
import PublicPostJobOfferItemPage from './app/PublicPostJobOfferItemPage'
import PublicPostJobRequestListPage from './app/PublicPostJobRequestListPage'
import PublicPostJobRequestItemPage from './app/PublicPostJobRequestItemPage'
import PrivateRoute from './app/PrivateRoute'
import { getUser } from './modules/user/actions'
import LoginPage from './app/LoginPage'
import ProtectedAreaHomePage from './app/ProtectedAreaHomePage'
import ProtectedAreaProfilePage from './app/ProtectedAreaProfilePage'
import HomePage from './app/HomePage'
//import MySavedSearches from './containers/Account/MySavedSearches/MySavedSearches'
//import CreateOffer from './containers/Offer/CreateOffer/CreateOffer'
// import MyOrganism from './containers/Organism/MyOrganism'
//import RequestContainer from './containers/Request/RequestContainer/RequestContainer'
import SectionPage from './app/SectionPage'
import ContentPage from './app/ContentPage'
import CategoryPage from './app/CategoryPage'
import ProtectedAreaPostJobOfferListPage from './app/ProtectedAreaPostJobOfferListPage'
import ProtectedAreaPostJobOfferPreviewPage from './app/ProtectedAreaPostJobOfferPreviewPage'
import ProtectedAreaPostJobOfferCreatePage from './app/ProtectedAreaPostJobOfferCreatePage'
import ProtectedAreaPostJobOfferEditPage from './app/ProtectedAreaPostJobOfferEditPage'
import AdminPostJobOfferPreviewPage from './app/AdminPostJobOfferPreviewPage'
import ProtectedAreaSavedPost from './app/ProtectedAreaSavedPost'
import ProtectedAreaSavedSearch from './app/ProtectedAreaSavedSearch'
import ProtectedAreaOrganism from './app/ProtectedAreaOrganism'
import ProtectedAreaArticleEditPage from './app/ProtectedAreaArticle/ProtectedAreaArticleEditPage'
import ProtectedAreaArticlePreviewPage from './app/ProtectedAreaArticle/ProtectedAreaArticlePreviewPage'
import ProtectedAreaArticleCreatePage from './app/ProtectedAreaArticle/ProtectedAreaArticleCreatePage'
import ProtectedAreaArticleListPage from './app/ProtectedAreaArticle/ProtectedAreaArticleListPage'
class MainSiteSwitch extends React.Component {
  componentWillMount () {
    this.props.dispatch(getUser())
  }

  render () {
    return (
      <Switch>
        <Route path={routes.ADMIN_POST_JOB_OFFER_PREVIEW} exact component={AdminPostJobOfferPreviewPage} />

        <Route path={routes.HOME} exact component={HomePage} />

        <Route path={routes.LOGIN} exact component={LoginPage} />
        <Route path={routes.CREATE_PERSONAL_ACCOUNT} exact component={CreatePersonalAccount} />
        <Route path={routes.CREATE_PRO_ACCOUNT} exact component={CreateProAccount} />

        <PrivateRoute path={routes.PROTECTED_AREA} exact component={ProtectedAreaHomePage} />
        <PrivateRoute path={routes.PROTECTED_AREA_PROFILE} exact component={ProtectedAreaProfilePage} />

        <PrivateRoute path={routes.PROTECTED_AREA_ARTICLE_LIST} exact component={ProtectedAreaArticleListPage} />
        <PrivateRoute path={routes.PROTECTED_AREA_ARTICLE_CREATE} exact component={ProtectedAreaArticleCreatePage} />
        <PrivateRoute path={routes.PROTECTED_AREA_ARTICLE_PREVIEW} exact component={ProtectedAreaArticlePreviewPage} />
        <PrivateRoute path={routes.PROTECTED_AREA_ARTICLE_EDIT} exact component={ProtectedAreaArticleEditPage} />
        
        <PrivateRoute path={routes.PROTECTED_AREA_SAVED_SEARCHES} exact component={ProtectedAreaSavedSearch} />
        <PrivateRoute path={routes.PROTECTED_AREA_MANAGE_ORGANISM} exact component={ProtectedAreaOrganism} />
        {/* <PrivateRoute path='/espace-perso/mes-actualites' exact component={MyArticles} /> */}
        {/* <PrivateRoute path='/espace-perso/creer-un-article' exact component={CreateArticle} /> */}

        <PrivateRoute path={routes.PROTECTED_AREA_SAVED_ANNOUNCEMENTS} exact component={ProtectedAreaSavedPost} />
        <PrivateRoute path={routes.PROTECTED_AREA_JOB_LIST} exact component={() => (<Redirect to={routes.PROTECTED_AREA_JOB_OFFER_LIST} />)} />
        <PrivateRoute path={routes.PROTECTED_AREA_JOB_OFFER_LIST} exact component={ProtectedAreaPostJobOfferListPage} />

        <PrivateRoute path={routes.PROTECTED_AREA_JOB_OFFER_PREVIEW} exact component={ProtectedAreaPostJobOfferPreviewPage} />
        <PrivateRoute path={routes.PROTECTED_AREA_JOB_OFFER_EDIT} exact component={ProtectedAreaPostJobOfferEditPage} />
        <PrivateRoute path={routes.PROTECTED_AREA_JOB_OFFER_CREATE} exact component={ProtectedAreaPostJobOfferCreatePage} />

        {/* <PrivateRoute path={routes.PROTECTED_AREA_JOB_OFFER_LIST }  exact component={ProtectedAreaPostJobRequestListPage} /> */}

        {/* <PrivateRoute path='/espace-perso/mes-annonces-emploi/deposer-une-offre' exact component={CreateOffer} /> */}
        {/* <PrivateRoute path='/espace-perso/mes-annonces-emploi/deposer-une-offre/preview' exact component={OfferPreview} /> */}
        {/* <PrivateRoute path='/espace-perso/mes-annonces-emploi/modifier-une-offre/preview' exact component={OfferPreview} /> */}
        {/* <PrivateRoute path='/espace-perso/mes-annonces-emploi/modifier-une-offre/:id' exact component={EditOffer} /> */}
        {/* <PrivateRoute path='/espace-perso/mes-annonces-emploi/deposer-une-candidature' exact component={CreateRequest} /> */}
        {/* <PrivateRoute path='/espace-perso/mes-annonces-emploi/deposer-une-candidature/preview' exact component={RequestPreview} /> */}
        {/* <PrivateRoute path='/espace-perso/mes-annonces-emploi/modifier-une-candidature/preview' exact component={RequestPreview} /> */}
        {/* <PrivateRoute path='/espace-perso/mes-annonces-emploi/modifier-une-candidature/:id' exact component={EditRequest} /> */}
        {/* <PrivateRoute path='/espace-perso/annonces/jobs/candidatures/:id' exact component={RequestContainer} /> */}
        {/* <PrivateRoute path='/espace-perso/article/editer/:id' exact component={CreateArticle} /> */}

        <Route path='/mentions-legales' exact component={LegalNotice} />
        <Route path='/nous-contacter' exact component={Contact} />
        <Route path='/nos-partenaires' exact component={Partners} />
        <Route path='/recherche' exact component={SearchResults} />

        <Route path='/annonces/jobs' exact component={() => (<Redirect to={routes.JOB_FILTER_OFFER} />)} />
        <Route path={routes.JOB_FILTER_OFFER} exact component={PublicPostJobOfferListPage} />
        <Route path={routes.JOB_FILTER_REQUEST} exact component={PublicPostJobRequestListPage} />
        <Route path={routes.PUBLIC_POST_OFFER} exact component={PublicPostJobOfferItemPage} />
        <Route path={routes.PUBLIC_POST_REQUEST} exact component={PublicPostJobRequestItemPage} />

        <Route path={routes.ARTICLE} exact component={ArticlePage} />
        <Route path='/:category/:section/:content' exact component={ContentPage} />
        <Route path={routes.ARTICLES} exact component={ArticlesPage} />

        <Route path='/:category/:section' exact component={SectionPage} />
        <Route path='/:category' exact component={CategoryPage} />
      </Switch>
    )
  }
}

export default connect()(MainSiteSwitch)
