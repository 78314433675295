// @flow
import React, {useEffect} from 'react'
import classnames from 'classnames'

import './Modal.scss'

// type Props = {
//   children: React.node,
//   onClose: () => void,
//   hasCloseButton: boolean,
//   isOpen: boolean,
// };

const addBodyClass = () => document.documentElement.classList.add('modal-open');
const removeBodyClass = () => document.documentElement.classList.remove('modal-open');


export default function Modal ({
  children,
  hasCloseButton = true,
  isOpen = true,
  onClose,
  contentClassname = '',
}) {
  useEffect(() => {
    return () => {
      removeBodyClass();
    }
  }, [])

  if(isOpen) {
    addBodyClass();
  }

  return (
    <div className={classnames('modal', { 'is-active': isOpen })}>
      <div
        className='modal-background'
        onClick={() => hasCloseButton && onClose()}
        role='presentation'
      />
      <div className={classnames('modal-content', contentClassname)}>{children}</div>
      {hasCloseButton && (
        <button
          className='modal-close is-large'
          aria-label='close'
          type='button'
          onClick={onClose}
        />
      )}
    </div>
  )
}
