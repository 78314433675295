import React from 'react'
import classnames from 'classnames'
import IsRequiredIndicator from '../IsRequiredIndicator'
import './input.scss'

export default ({name, id, label, required = false, value = false, setInput, className='', ...props}) => {
  if (typeof value === 'string') {
    value = value === 'true'
  }
  return (
    <div className="control is-expanded">
      <label htmlFor={id} className={classnames("checkbox", className)} {...props}>
        <input
          {...{
            id,
            name,
            onChange: (e) => setInput(name, !!e.target.checked)
          }}
          type="checkbox"
          checked={value}
          className={classnames("checkbox-case")}
        />
        <>&nbsp;</>{label}{required && <IsRequiredIndicator/>}
      </label>
    </div>
  )
}
