import React, { useState } from 'react'
import classnames from 'classnames'
import AutoSuggest from 'react-autosuggest'
import { search } from '../../modules/geoApiGouvApiAdresse/actions'
import FieldWrapper from '../form/FieldWrapper'

export const fullAddressApiConfig = {type: 'housenumber'};
export const onlyCityApiConfig = {type:'municipality'};

/**
 * For postJobForm, use fullAddressApiConfig & resultIsResetOnChange=true
 * For postJobFilter (city field), use onlyCityApiConfig & resultIsResetOnChange=false
 */
export default ({
  name, setInput, label, selectedValue, placeholder, choices, allowEmpty, className, validation, errors, isDirty,
  resultIsResetOnChange = true,
  apiConfig = fullAddressApiConfig,
}) => {
  const [value, setValue] = useState((selectedValue && selectedValue.label) || '')
  const [suggestions, setSuggestions] = useState([])

  const validated = !errors.length;

  const onChange = (event, { newValue }) => {
    setValue(newValue)
    if(resultIsResetOnChange) {
      setInput(name, null)
    }
  }

  const { required } = validation;

  return (
    <FieldWrapper {...{
      id: 'input-'+ name,
      label,
      required,
      errors,
      isDirty
    }}>
      <div
        className={classnames('dropdown', {'is-active': !!suggestions.length})}
        style={{display: 'block'}}
      >
        <AutoSuggest
          inputProps={{
            id: 'input-'+ name,
            placeholder: "Entrez une ville",
            value,
            autoComplete: 'off',
            onChange: onChange,
          }}

          suggestions={suggestions}
          onSuggestionsFetchRequested={data => onSuggestionsFetchRequested(setSuggestions, apiConfig, data)}
          onSuggestionsClearRequested={() => onSuggestionsClearRequested(setSuggestions)}
          getSuggestionValue={({label}) => label}

          renderInputComponent={({className, ...inputProps}) => (
            <div className={classnames("control has-icons-left", {['has-icons-right']: resultIsResetOnChange})}>
              <input className={classnames('input', className)} {...inputProps} />
              <span className={classnames('icon is-small is-left', {'has-text-success': validated})}>
                <i className="fas fa-map-marked"/>
              </span>
              {resultIsResetOnChange && <span className={classnames('icon is-small is-right', {'has-text-success': validated})}>
                <i className={classnames('fas', {
                  'fa-ellipsis-h': !validated,
                  'fa-check': validated
                })} />
              </span>}
            </div>
          )}
          renderSuggestionsContainer={({ containerProps: {className, style = {}, ...containerProps}, children, query }) => (
            <div
              className={classnames('dropdown-menu', className)}
              style={{...style, width: '100%'}}
              {...containerProps}
            >
              <div className="dropdown-content">
                {children}
              </div>
            </div>
          )}
          renderSuggestion={({label}, { isHighlighted }) => (
            <a className={classnames("dropdown-item", {'is-active': isHighlighted})}>
              {label}
            </a>
          )}
          onSuggestionSelected={(e, suggestion) => onSuggestionSelected(setInput, name, suggestion)}
        />
      </div>
    </FieldWrapper>
  )
}

const onSuggestionSelected = (setInput, name, {suggestion}) => {
  setInput(name, suggestion)
}

const onSuggestionsFetchRequested = (setSuggestions, apiConfig, { value, reason }) =>
  search(value, apiConfig, (results) => {
    setSuggestions(results)
  })

const onSuggestionsClearRequested = (setSuggestions) => {
  setSuggestions([])
}