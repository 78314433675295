import React from 'react'

import routes from '../../routes.json'

import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import { getCategoryUri } from '../../router'

export default ({ category, children, ...props }) => (
  <ControlledLoadableLink
    routeName={routes.CATEGORY}
    to={getCategoryUri(category)}
    {...props}
  >
    {children}
  </ControlledLoadableLink>
)
