import React, { useState } from 'react'
import { connect } from 'react-redux'
import { reduce } from 'lodash'
import classnames from 'classnames'
import formSchema from './formSchema'
import buildForm from '../form/buildForm'
import { makeSelectUser } from '../../modules/user/selectors'
import getFormHandler from '../../modules/formHelper'
import { updateUser, updateUserPassword } from '../../modules/user/actions'
import { deTransform, transform } from '../../modules/user/model'
import TextInput from '../form/input/TextInput'
import FieldWrapper from '../form/FieldWrapper'

const ProtectedAreaProfileForm = ({ currentUser, handleSubmit, handlePasswordSubmit }) => {
  const formSchemaRole = formSchema.filter(({ roles }) => roles ? currentUser.roles.some(r => roles.includes(r)) : true)
  const formHandler = getFormHandler(formSchemaRole);
  const [inputs, setInputs] = useState(formHandler.initializeInputs(currentUser));
  const hasErrors = reduce(inputs, (all, {errors}) => [...all, ...errors], []).length;
  const setInput = (name, value) => formHandler.setInput(setInputs, name, value);

  const [passwordInputs, setPasswordInputs] = useState({ plainPassword: '', passwordConfirm: '' })
  const [passwordErrors, setPasswordErrors] = useState({ plainPassword: [], passwordConfirm: [] })

  const passwordSubmit = (e) => {
    if (!passwordInputs.plainPassword.match('(?=^.{8,}$)(?=.*\\d)(?=.*\\W+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$')) {
      setPasswordErrors({ ...passwordErrors, plainPassword: ['(au moins 8 caractères, 1 chiffre, 1 majuscule, 1 minuscule et 1 caractère spécial)'] })
    } else if (passwordInputs.plainPassword !== passwordInputs.passwordConfirm) {
      setPasswordErrors({ passwordConfirm: ['Les mots de passes ne sont pas identiques'], plainPassword: [] })
    } else {
      setPasswordErrors({ ...passwordErrors, plainPassword: [], passwordConfirm: [] })
      setPasswordInputs({ plainPassword: '', passwordConfirm: '' })
      handlePasswordSubmit(passwordInputs)
    }
  }

  return (
    <>
      <form className="columns is-multiline is-variable is-1">
        <div className="column is-12">
          <div className="columns is-multiline is-variable is-1">
            {buildForm(
              formSchemaRole
                .filter(({group}) => group === 'default')
                .map(fieldConfig => ({className: 'is-4', ...fieldConfig})),
              inputs,
              setInput
            )}
          </div>
          <div className="columns is-multiline is-variable is-1">
            <div className="column is-12">
              <h4 className="is-size-4">Coordoonées</h4>
            </div>
            {buildForm(
              formSchemaRole
                .filter(({group}) => group === 'coordinates')
                .map(fieldConfig => ({className: 'is-6', ...fieldConfig})),
              inputs,
              setInput
            )}
          </div>
          <div className="columns is-multiline is-variable is-1">
            <div className="column is-12">
              <h4 className="is-size-4">Nouveautés</h4>
            </div>
            {buildForm(
              formSchemaRole
                .filter(({group}) => group === 'other')
                .map(fieldConfig => ({className: 'is-12', ...fieldConfig})),
              inputs,
              setInput
            )}
          </div>
        </div>
        <div className="field column is-12">
          <div className="control">
            <button
              disabled={hasErrors}
              className={classnames("button is-primary is-fullwidth")}
              onClick={e => {
                e.preventDefault();
                handleSubmit(formHandler.mergeInputs(currentUser, inputs))/*.then((apiResult) => {
                  // reset form
                  setInputs(formHandler.initializeInputs(apiResult))
                })*/;
              }}>
              Enregistrer mes informations
            </button>
          </div>
        </div>
      </form>
      <form className="columns is-multiline is-variable is-1">
        <div className="column is-12">
          <div className="columns is-multiline is-variable is-1">
            <div className="column is-12">
              <h4 className="is-size-4">Changer son mot de passe</h4>
            </div>
            {[
              { label: 'Mot de passe', name: 'plainPassword', type: 'password', patern: '(?=^.{8,}$)(?=.*\\d)(?=.*\\W+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$', formrow: 3, info: '(au moins 8 caractères, 1 chiffre, 1 majuscule, 1 minuscule et 1 caractère spécial)' },
              { label: 'Confirmation du mot de passe', name: 'passwordConfirm', type: 'password' }
            ].map(({ label, name, type, patern = null, info = null, required = true, className = 'is-6', isDirty = true }) => {
              return <FieldWrapper {...{
                key: name,
                id: 'input-'+ name,
                className,
                label,
                required,
                errors: passwordErrors[name],
                isDirty
              }}>
                <TextInput {...{
                  id: 'input-'+ name,
                  className: (isDirty && passwordErrors[name].length) ? 'is-danger' : '',
                  value: passwordInputs[name],
                  name,
                  type,
                  onChange: (e) => setPasswordInputs({ ...passwordInputs, [name]: e.target.value })
                }} />
              </FieldWrapper>
            })}
          </div>
        </div>
        <div className="field column is-12">
          <div className="control">
            <button
              className={classnames("button is-primary is-fullwidth")}
              onClick={e => {
                e.preventDefault();
                passwordSubmit(formHandler.mergeInputs(currentUser, inputs))/*.then((apiResult) => {
                  // reset form
                  setInputs(formHandler.initializeInputs(apiResult))
                })*/;
              }}>
              Enregistrer mon mot de passe
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

const mapStateToProps = (state, props) => ({
  currentUser: transform(makeSelectUser()(state, props))
})

const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (user) => dispatch(updateUser(deTransform(user))),
  handlePasswordSubmit: (passwordForm) => dispatch(updateUserPassword(passwordForm))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaProfileForm)
