import axios from 'axios'
import { makeSelectToken } from './authentication/selectors'

const API_BASE_URL = '/api/'

export const requestWithToken = (requestConfig, getState) => request({
  ...requestConfig,
  securityToken: makeSelectToken()(getState())
})

const request = ({
  endpoint,
  method = 'GET',
  data = null,
  securityToken = null,
  baseUrl = API_BASE_URL
}) =>
  axios.request({
    baseURL: baseUrl,
    url: endpoint,
    method,
    data,
    headers: securityToken ? { Authorization: `Bearer ${securityToken}` } : {},
  })
  .catch(error => {
    console.error(error)
    throw (error)
  })

export default request;