import { STATE_KEY, AUTHENTICATION_REDIRECT } from './constants'
import { push } from "connected-react-router"

export function setRedirectTarget(target) {
  return (dispatch) => {
    dispatch({ type: AUTHENTICATION_REDIRECT.set, target })
  }
}

export function redirect() {
  return (dispatch, getState) => {
    const { target = null } = getState()[STATE_KEY];

    if (target) {
      dispatch({ type: AUTHENTICATION_REDIRECT.clean })
      dispatch(push(target))
    }
  }
}