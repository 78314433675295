import { requestWithToken } from '../apiHelper'
import { toast } from 'react-toastify'
import { USER_POST_JOB_SAVED_OFFER, USER_POST_JOB_SAVED_OFFERS } from './constants'

const endpoint = 'user/savedoffers'

export function fetchSavedPostJobOffers () {
  return (dispatch, getState) => {
    dispatch({ type: USER_POST_JOB_SAVED_OFFERS.fetching })

    return requestWithToken({ endpoint }, getState)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: USER_POST_JOB_SAVED_OFFERS.fetched, data: res.data })
      })
      .catch(error => {
        dispatch({ type: USER_POST_JOB_SAVED_OFFERS.fetching_error, data: error })
        toast.error(error.response.data.message)
      })
  }
}

export function savePostJobOffer (id) {
  return (dispatch, getState) => {
    dispatch({ type: USER_POST_JOB_SAVED_OFFER.save, payload: id })
    dispatch({ type: USER_POST_JOB_SAVED_OFFER.saving })

    return requestWithToken({ endpoint: endpoint + '/' + id, method: 'POST', data: {} }, getState)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: USER_POST_JOB_SAVED_OFFER.saved })
      })
      .catch(error => {
        dispatch({ type: USER_POST_JOB_SAVED_OFFER.saving_error, data: error })
        toast.error(error.response.data.message)
      })
  }
}

export function loseSavedPostJobOffer (id) {
  return (dispatch, getState) => {
    dispatch({ type: USER_POST_JOB_SAVED_OFFER.lose, payload: id })
    dispatch({ type: USER_POST_JOB_SAVED_OFFER.saving })

    return requestWithToken({ endpoint: endpoint + '/' + id, method: 'DELETE', data: {} }, getState)
      .then(res => res.data)
      .then(res => {
        dispatch({ type: USER_POST_JOB_SAVED_OFFER.saved })
      })
      .catch(error => {
        dispatch({ type: USER_POST_JOB_SAVED_OFFER.saving_error, data: error })
        toast.error(error.response.data.message)
      })
  }
}
