
/** transform api object to domain object */
export const transform = ({
  firstName, consentFirstName,
  lastName, consentLastName,
  email, consentMail,
  mainPhone, consentPhone,
  plainPassword, passwordConfirm,
  ...user}) => ({
    ...user,
    lastName: {value: lastName, consent: consentLastName},
    firstName: {value: firstName, consent: consentFirstName},
    email: {value: email, consent: consentMail},
    mainPhone: {value: mainPhone, consent: consentPhone},
    password: {plainPassword: plainPassword, passwordConfirm: passwordConfirm}
  })

/** transform domain object to api object */
export const deTransform = ({lastName, firstName, email, mainPhone, password, ...user}) => ({
  ...user,
  lastName: lastName.value,
  consentLastName: lastName.consent,
  firstName: firstName.value,
  consentFirstName: firstName.consent,
  email: email.value,
  consentMail: email.consent,
  mainPhone: mainPhone.value,
  consentPhone: mainPhone.consent,
  plainPassword: password.plainPassword,
  passwordConfirm: password.passwordConfirm
})