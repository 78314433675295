export const STATE_KEY = 'user_article'

export const USER_ARTICLES = {
  fetching: `${STATE_KEY}/FETCHING`,
  fetched: `${STATE_KEY}/FETCHED`,
  fetching_error: `${STATE_KEY}/FETCHING_ERROR`
}
export const USER_ARTICLE = {
  creating: `${STATE_KEY}/CREATING`,
  created: `${STATE_KEY}/CREATED`,
  creating_error: `${STATE_KEY}/CREATING_ERROR`,

  updating: `${STATE_KEY}/UPDATING`,
  updated: `${STATE_KEY}/UPDATED`,
  updating_error: `${STATE_KEY}/UPDATING_ERROR`,

  archiving: `${STATE_KEY}/ARCHIVING`,
  archived: `${STATE_KEY}/ARCHIVED`,
  archiving_error: `${STATE_KEY}/ARCHIVING_ERROR`,

  publishing: `${STATE_KEY}/PUBLISHING`,
  published: `${STATE_KEY}/PUBLISHED`,
  publishing_error: `${STATE_KEY}/PUBLISHING_ERROR`
}
