import {
  QUERY,
  FILTER_QUERY_RESULTS
} from './constants'
import request from '../apiHelper'

export function filterQuery(id, inputs = {}) {
  return dispatch => dispatch({ type: QUERY.filter, payload: {id, inputs} })
}

export function initializeQuery(id) {
  return dispatch => {
    dispatch({ type: QUERY.fetching, payload: {id} })
    return request({endpoint: `queries/${id}/results`})
      .then(res => res.data)
      .then(({data}) => {
        dispatch({ type: QUERY.fetched, payload: {id, results: data}})
      })
      .catch(error => {
        dispatch({ type: QUERY.fetching_error, payload: {error: error }})
      })
  }
}