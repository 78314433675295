import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { push } from 'connected-react-router'
import ProtectedAreaPage from './protectedArea/ProtectedAreaPage'
import Layout from './utils/Layout'
import UserJobRequestSavedList from './userPostSaved/UserJobRequestSavedList'
import UserJobOfferSavedList from './userPostSaved/UserJobOfferSavedList'

const ProtectedAreaSavedPost = ({ changeURLParams, match, ...props }) => {
  const [filters, setFilters] = useState({
    type: queryString.parse(props.location.search).type || 'offre'
  })
  const handleFilterChange = (e) => {
    const filtersChange = { ...filters }
    filtersChange[e.target.name] = e.target.value
    setFilters(filtersChange)
    changeURLParams(queryString.stringify(filtersChange))
  }
  return (
    <ProtectedAreaPage
      match={match}
      title="Mes annonces sauvegardées"
      breadcrumbsMisc={[
        { label: "Mes annonces sauvegardées" },
      ]}
    >
      <Layout
        title="Mes annonces sauvegardées"
      >
        <div className="my-saved-job-page columns is-multiline">
          <div className="column">
            <div className="control">
              <div className='select'>
                <select className={'offerForm'} name={'type'} id={'type'} value={filters.type} onChange={handleFilterChange} >
                  <option value={'offre'}>Offres</option>
                  <option value={'candidature'}>Candidatures</option>
                </select>
              </div>
            </div>
          </div>
          {
            filters.type === 'candidature' ? <UserJobRequestSavedList /> : <UserJobOfferSavedList />
          }
        </div>
      </Layout>
    </ProtectedAreaPage>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  changeURLParams: (params) => dispatch(push({ search: params }))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaSavedPost))
