import request from '../apiHelper'
import { toast } from 'react-toastify'
import { AUTHENTICATION } from './constants'
import routes from '../../routes'
import { push } from "connected-react-router"
import { getUser } from '../user/actions'
import { redirect, setRedirectTarget } from '../authenticationRedirect/actions'
import { makeSelectRedirectionTarget } from '../authenticationRedirect/selectors'

const endpoint = 'login_check';

export function login(loginForm) {
  return (dispatch, getState) => {
    dispatch({ type: AUTHENTICATION.fetching })
    return request({endpoint, method: 'POST', data: loginForm})
      .then(res => {
        dispatch({ type: AUTHENTICATION.fetched, data: res.data })
        dispatch(getUser(() => {
          // redirect to espace-perso if no redirect is setup
          if(! makeSelectRedirectionTarget()(getState())) {
            dispatch(setRedirectTarget(routes.PROTECTED_AREA))
          }
          dispatch(redirect())
        }))
      })
      .catch(error => {
        dispatch({ type: AUTHENTICATION.fetching_error, data: error })
        dispatch(push(routes.LOGIN))
        toast.error('Identifiant ou mot de passe invalide', { toastId: 'Login.error' })
      })
  }
}

export function logout() {
  return dispatch => {
    dispatch({ type: AUTHENTICATION.clean })

    // reload website
    location.replace('/connexion')
  }
}