import ModalHandler from './ModalHandler'
import React from 'react'

export default ({modalChildren, onConfirmation, className="tag is-danger", actionerLabel='', Modal, icon = 'fa fa-trash'}) => (
  <ModalHandler
    actioner={({ onOpen }) => (
      <a className={className} onClick={e => { e.stopPropagation(); onOpen() }}>
        <i className={icon} style={{ paddingRight: '0.5rem' }} /> {actionerLabel}
      </a>
    )}
    modal={props => (
      <Modal
        {...props}
        onConfirmation={onConfirmation}
      >
        {modalChildren}
      </Modal>
    )}
  />
)