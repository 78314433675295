import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

const selectNamespace = state => state[STATE_KEY] || initialState
const selectRouterMatch = (state, props) => props.match

export const makeSelectCollection = () =>
  createSelector(
    [selectNamespace],
    // @todo update the filter
    ({ items }) => items
  )

export const makeSelectLoading = () =>
  createSelector(
    [selectNamespace],
    ({ loading = true }) => loading
  )

export const makeSelectUpdating = () =>
  createSelector(
    [selectNamespace],
    ({ updating }) => updating
  )

export const makeSelectError = () =>
  createSelector(
    [selectNamespace],
    ({ error = false }) => error
  )

export const makeSelectResource = () =>
  createSelector(
    [selectNamespace, selectRouterMatch],
    ({ items }, { params: { id } }) => items.find(item => item.id === parseInt(id, 10))
  )
