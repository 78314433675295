export default {
  logo: '',
  name: '',
  siret: '',
  initials: '',
  email: '',
  webSite: '',
  consentWebSite: false,
  address: '',
  additionalAddress: '',
  zipcode: '',
  city: '',
  region: '',
  mainPhone: '',
  commentMainPhone: '',
  sparePhone: '',
  commentSparePhone: ''
}
