import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import queryString from 'qs'
import moment from 'moment'
import 'moment/locale/fr'
import routes from '../../routes.json'
import Button from '../Button/Button'
import { deleteResearch, putToggleResearchAlert } from '../../actions/research'
import './SearchSaved.scss'
import { deleteSearch, toggleSearchAlert } from '../../modules/userSavedSearch/actions'

const SearchSavedCard = ({ searchSaved = {}, handleDeleteResearch, handleViewAnnounces, handleToggleAlert }) => (
  <div className="box">
    <div className="columns">
      <div className="column is-4">
        <div className="column">
          <span className="title-researche">{searchSaved.category || 'Toutes catégories'}</span>
        </div>
        <div className="column">
          <span>Créée le {moment(searchSaved.created).format('LL')} à {moment(searchSaved.created).format('LT')} </span>
        </div>
        <div className="column">
          <Button color='is-link' icon='sign-out-alt' action={() => handleViewAnnounces(searchSaved.filter, searchSaved.type)}>Voir les annonces</Button>
        </div>
      </div>
      <div className="column is-2 is-offset-5">
        <div className="column">
          <label className="checkbox">
            <input type="checkbox" checked={searchSaved.isAlert} onChange={() => handleToggleAlert(searchSaved.id)} />
            <span> M’alerter par email</span>
          </label>
        </div>
        <div className="column">
          <Button color='is-danger' icon='sign-out-alt' action={() => handleDeleteResearch(searchSaved.id)}>Supprimer cette recherche</Button>
        </div>
      </div>
    </div>
  </div>
)

const mapStateToProps = (state, props) => ({})

const mapDispatchToProps = dispatch => ({
  handleViewAnnounces: (filter, type) => dispatch(push({ pathname: routes[type], search: queryString.stringify(JSON.parse(filter)) })),
  handleDeleteResearch: (searchId) => dispatch(deleteSearch(searchId)),
  handleToggleAlert: (searchId) => dispatch(toggleSearchAlert(searchId))
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchSavedCard)
