// initial state
export const initialState = {
  postJobItemConfig: {},
  protectedAreaConfig: [],
  backgroundAccount: '',
}

export default function (state = initialState, action) {
  return state
}
