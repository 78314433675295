import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

const selectNamespace = state => state[STATE_KEY] || initialState
const selectQuery = (state, props) => props.query

export const makeSelectResource = () =>
  createSelector(
    [selectNamespace, selectQuery],
    ({ collections }, {id}) => collections[id] ? collections[id] : {},
  )

export const makeSelectLoading = () =>
  createSelector(
    [makeSelectResource()],
    ({ loading = true }) => loading
  )

export const makeSelectError = () =>
  createSelector(
    [makeSelectResource()],
    ({ error = false }) => error
  )

export const makeSelectResults = () =>
  createSelector(
    [makeSelectResource()],
    ({ items = [] }) => items
  )

export const makeSelectUserFilter = () =>
  createSelector(
    [makeSelectResource()],
    ({userFilter}) => userFilter
  )
