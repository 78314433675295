import moment from 'moment'

export default {
  roles: [],
  avatar: null,
  lastName: '',
  firstName: '',
  nickname: '',
  consentLastName: false,
  consentFirstName: false,
  email: '',
  plainPassword: '',
  passwordConfirm: '',
  consentMail: false,
  birthdate: moment(),
  gender: '',
  status: '',
  job: '',
  mainPhone: '',
  consentPhone: false,
  commentMainPhone: '',
  sparePhone: '',
  commentSparePhone: '',
  address: '',
  zipcode: '',
  city: '',
  region: '',
  consentTerms: false,
  consentNews: false
}
