import { USER_POST_JOB_OFFER, USER_POST_JOB_OFFERS } from './constants';

export const initialState = {
  loading: true,
  updating: false,
  creating: false,
  error: false,
  items: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_POST_JOB_OFFERS.fetching:
      return {
        ...state,
        loading: true,
        error: false
      }
    case USER_POST_JOB_OFFERS.fetched:
      return {
        ...state,
        loading: false,
        error: false,
        items: action.data
      }
    case USER_POST_JOB_OFFERS.fetching_error:
      return {
        ...state,
        loading: false,
        error: true
      }
    case USER_POST_JOB_OFFER.archiving:
      return {
        ...state,
        loading: true,
        error: false
      }
    case USER_POST_JOB_OFFER.archived:
      return {
        ...state,
        loading: false,
        error: false,
        items: state.items.filter(({id}) => id !== action.data.id),
      }
    case USER_POST_JOB_OFFER.archiving_error:
      return {
        ...state,
        loading: false,
        error: true
      }

    case USER_POST_JOB_OFFER.publishing:
      return {
        ...state,
        loading: true,
        error: false
      }
    case USER_POST_JOB_OFFER.published:
      return {
        ...state,
        loading: false,
        error: false,
        items: state.items.map(item => item.id === action.data.id
          ? action.data : item)
      }
    case USER_POST_JOB_OFFER.publishing_error:
      return {
        ...state,
        loading: false,
        error: true
      }

    case USER_POST_JOB_OFFER.updating:
      return {
        ...state,
        updating: true,
        error: false
      }
    case USER_POST_JOB_OFFER.updated:
      return {
        ...state,
        updating: false,
        error: false,
        items: state.items.map(item => item.id === action.data.id
          ? action.data : item)
      }
    case USER_POST_JOB_OFFER.updating_error:
      return {
        ...state,
        updating: false,
        error: true
      }
    case USER_POST_JOB_OFFER.creating:
      return {
        ...state,
        creating: true,
        error: false
      }
    case USER_POST_JOB_OFFER.created:
      return {
        ...state,
        creating: false,
        error: false,
        items: state.items.concat([action.data])
      }
    case USER_POST_JOB_OFFER.creating_error:
      return {
        ...state,
        creating: false,
        error: true
      }
  }

  return state
}
