import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

import { makeSelectResource as makeSelectCategory } from '../category/selectors'
import { makeSelectResource as makeSelectSection } from '../section/selectors'

import queryString from 'query-string'
import striptags from 'striptags'
import { sanitize } from 'dompurify'

const selectNamespace = state => state[STATE_KEY] || initialState
const selectQueryParamSearch = (state, props) => queryString.parse(props.location.search)
const selectRouterMatch = (state, props) => props.match

export const makeSelectCollectionForProtectedArea = () =>
  createSelector(
    [selectNamespace],
    // @todo update the filter
    ({ items }) => items.filter(item => item.highlight)
  )

export const makeSelectCollectionForHome = () =>
  createSelector(
    [selectNamespace],
    ({ items }) => items.filter(item => item.highlight)
  )

export const makeSelectCollectionForCategory = () =>
  createSelector(
    [selectNamespace, makeSelectCategory()],
    ({ items }, { id }) => items.filter(item => item.categories.find(cat => cat.id === id))
  )

export const makeSelectCollectionForSection = () =>
  createSelector(
    [selectNamespace, makeSelectSection()],
    ({ items }, { id = null }) => items.filter(item => item.section && item.section.id === id)
  )

export const makeSelectCollectionForSearchArticles = () =>
  createSelector(
    [selectNamespace, selectQueryParamSearch],
    ({ items }, { recherche = '' }) => items.filter(
      item => check(recherche, item.title || '') ||
      check(recherche, item.introduction || '') ||
      check(recherche, item.content || '')
    )
  )

const check = (searchValue, text) => sanitize(striptags(text)).toLowerCase().includes(searchValue.toLowerCase())

export const makeSelectResource = () =>
  createSelector(
    [selectNamespace, selectRouterMatch],
    ({ items }, { params: { article } }) => items.find(item => item.slug === article)
  )
