import { FORUM } from './constants';
// initial state
export const initialState = {
  loading: false,
  items: []
}

export default function (state = initialState, action) {
  switch (action.type) {

  }
  return state
}
