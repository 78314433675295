import React from 'react'
import { connect } from 'react-redux'

import { makeSelectCollectionForSection } from '../../modules/content/selectors'

import SubNavBar from '../navBar/SubNavBar'
import ContentNavBarLink from '../contents/ContentNavBarLink'

export const ControlledSubNavBar = ({
  // match,
  selectedCategory,
  selectedSection,
  selectedContent,
  contents = []
}) => (
  <SubNavBar section={selectedSection}>
    {contents.map(content => (
      <li key={content.slug}>
        <ContentNavBarLink category={selectedCategory} section={selectedSection} selectedContent={selectedContent} content={content} />
      </li>
    ))}
  </SubNavBar>
)

const mapStateToProps = (state, props) => ({
  contents: makeSelectCollectionForSection()(state, props)
})

export default connect(mapStateToProps)(ControlledSubNavBar)
