import React, { useState } from 'react'
import classNames from 'classnames'

export default ({ liked, onClick=() => {} }) => {
  const [hover, setHover] = useState(false)

  return <a
    className={classNames({
      'far': !liked,
      'fas': liked || (!liked && hover),
      'fa-heart-broken': hover && liked,
      'fa-heart': (!hover && liked) || !liked
    })}
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}
    onClick={onClick}
  />
}
