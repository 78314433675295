import React from 'react'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import routes from '../../routes.json'
import UserArticleTitleBar from './UserArticleTitleBar'
import ProtectedAreaPage from '../protectedArea/ProtectedAreaPage'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Layout from '../utils/Layout'
import PostArticleCard from '../articles/PostArticleCard'
import PostList from '../post/PostList'
import { makeSelectCollection } from '../../modules/userArticle/selectors'
import { makeSelectCollection as makeSelectCollectionCategory } from '../../modules/category/selectors'
import { archiveUserArticle } from '../../modules/userArticle/actions'
import { getUserArticlePreviewUri } from '../../router'

const ProtectedAreaArticleListPage = ({ match, articles = [], handleArchiveArticle, categoryList }) => {
  return (
    <ProtectedAreaPage
      match={match}
      title='Mes actualités'
      breadcrumbsMisc={[
        { label: 'Mes actualités', routeName: routes.PROTECTED_AREA_ARTICLE_LIST, to: routes.PROTECTED_AREA_ARTICLE_LIST },
      ]}
    >
      <Layout>
        <div className='columns is-multiline'>
          <UserArticleTitleBar match={match} />
          <div className='column'>
            {!articles.length
              ? (
                <>
                  <h4>Vous n'avez aucune actualité.</h4>
                </>
              ) : (
              <>
                <PostList
                  CardComponent={PostArticleCard}
                  categoryList={categoryList}
                  posts={articles.map(article => ({
                    ...article,
                    section: article.section,
                    category: !article.section ? article.categories[0] : article.section.category
                  }))}
                  getCardLinkProps={
                    (article) => ({
                      routeName: routes.PROTECTED_AREA_ARTICLE_PREVIEW,
                      to: getUserArticlePreviewUri(article.id)
                    })
                  }
                  handleArchiveArticle={handleArchiveArticle}
                />
              </>
              )
            }
          </div>
        </div>
      </Layout>
    </ProtectedAreaPage>
  )
}

const mapStateToProps = (state, props) => ({
  articles: makeSelectCollection()(state, props),
  categoryList: makeSelectCollectionCategory()(state, props)
})

const mapDispatchToProps = (dispatch) => ({
  handleArchiveArticle: (article) => dispatch(archiveUserArticle(article)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaArticleListPage))
