import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import ProtectedAreaPage from './protectedArea/ProtectedAreaPage'
import Layout from './utils/Layout'
import OrganismDetail from './protectedAreaOrganism/OrganismDetail'

const ProtectedAreaOrganism = ({ match }) => {
  return (
    <ProtectedAreaPage
      match={match}
      title='Mon organisme'
      breadcrumbsMisc={[
        { label: 'Mon organisme' }
      ]}
    >
      <Layout
        title='Mon organisme'
      >
        <OrganismDetail />
      </Layout>
    </ProtectedAreaPage>
  )
}

const mapStateToProps = (state, props) => ({
})

const mapDispatchToProps = dispatch => ({
  changeURLParams: (params) => dispatch(push({ search: params }))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaOrganism))
