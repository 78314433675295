import React from 'react'
import classnames from 'classnames'
import LoaderGif from './LoaderGif'

export default ({
  to,
  loading,
  className = '',
  style = {},
  children,
  handleClick
}) => (
  <a
    href={to}
    onClick={handleClick}
    className={classnames({ loading }, className)}
    style={style}
  >
    {children}
    {loading && <LoaderGif />}
  </a>
)
