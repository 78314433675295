import { createSelector } from 'reselect'
import moment from 'moment'
import { STATE_KEY } from './constants'
import { initialState } from './reducer'
import { makeSelectUser } from '../user/selectors'

const selectNamespace = state => state[STATE_KEY] || initialState
const selectRouterMatch = (state, props) => props.match

export const makeSelectCollection = () =>
  createSelector(
    selectNamespace,
    // sortBy created DESC
    ({ items }) => items.sort((item1, item2) =>
      moment(item1.created).diff(moment(item2.created))
  ))

export const makeSelectLoading = () =>
  createSelector(
    [selectNamespace],
    ({ loading = true }) => loading
  )

export const makeSelectUpdating = () =>
  createSelector(
    [selectNamespace],
    ({ updating }) => updating
  )

export const makeSelectError = () =>
  createSelector(
    [selectNamespace],
    ({ error = false }) => error
  )

export const makeSelectResource = () =>
  createSelector(
    [selectNamespace, selectRouterMatch],
    ({ items }, { params: { id } }) => items.find(item => item.id === parseInt(id, 10))
  )

export const makeSelectNewObject = () =>
  createSelector(
    [makeSelectUser()],
    ({
      firstName,
      lastName,
      nickname,
      email,
      mainPhone
    }) => (
      {
        category: '',
        subCategory: '',
        fullAddress: {
          coordinates: {},
          label: '',
        },
        // preload contact data
        firstName,
        lastName,
        nickname,
        email,
        mainPhone
      }
    )
  )