import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/fr'
//import { postPostSaved, deletePostSaved, patchPostArchived } from '../../actions'
import routes from '../../routes.json'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'
// import { makeSelectCollection as makeSelectPostCollection } from '../../modules/userJobPostSaved/selectors'
import classnames from 'classnames'
import Image from '../utils/Image'
import { getPostJobOfferUri } from '../../router'

import backgroundImageHelper from '../utils/backgroundImageHelper'
import PostCard from '../post/PostCard'
import PostJobTitle from './PostJobTitle'

export default ({ post, location, routeName, getLinkProps = (post) => ({}), ControlledSavePost, ...props }) => {
  const {id, title, category, subCategory, jobPicture, fullAddress, created, published} = post;

  const linkProps = getLinkProps(post);
  return (
    <PostCard {...{
      linkProps,
      title: <>{category} / {subCategory}</>,
      titleColor: '#000',
      thumbPicture: jobPicture
    }}>
      <div className="column is-12">
        <ControlledLoadableLink {...linkProps}>
          <h2>
            <PostJobTitle {...post} />
          </h2>
        </ControlledLoadableLink>
        <p>{post.post ? 'Type de poste: ' + post.post : 'Type de domaine: ' + post.domain}</p>
      </div>
      <div className="column is-paddingless">
        <p className="location">
          <i className="fas fa-lg fa-map-marker" /> {`${fullAddress.city} (${fullAddress.postcode.substr(0, 2)})`}
        </p>
      </div>
      <div className="column is-paddingless">
        <p className="date">
          <i className="fas fa-lg fa-calendar" /> {moment(published || created).format('Do MMMM YYYY')}
        </p>
      </div>
      <div className="column rigth is-paddingless">
        <ControlledSavePost {...{ post, location }} />
      </div>
      <div className="actionButtons">
        {/*!isForManage && <IconHeart liked={isSaved} toggleLike={(event) => toggleLike(event)} />}
            {isForManage &&
            <ModalHandler
              actioner={(propsModalAskForJob) => (
                <ModalHandler
                  actioner={({ onOpen }) => (
                    <Icon action={(event) => { event.stopPropagation(); onOpen() }} icon='fas fa-trash fa-2x' />
                  )}
                  modal={props => <ModalRemove {...props} onConfirmation={propsModalAskForJob.onOpen} subject='cette offre' />}
                />
              )}
              modal={props2 => <ModalAskForJob {...props2} onConfirmation={(isJobFullFilled, date = null) => { handleDeletePost(post.id, isJobFullFilled, date) }} />}
            />
            }
            {isForManage && post.status === 'draft' &&
            <ControlledLoadableLink
              routeName={routes.PROTECTED_AREA_JOB_POST_EDIT}
              to={`/espace-perso/mes-annonces-emploi/modifier-une-offre/${post.id}`}
              args={{ postId: post.id }}
            >
              <button><i className='far fa-edit fa-2x' /></button>
            </ControlledLoadableLink>
            */}
      </div>
    </PostCard>
  );
}
