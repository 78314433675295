// @flow
// import React from 'react';
import ReactDOM from 'react-dom'

// type Props = {
//   children: React.node,
// };

export default ({ children }) =>
  ReactDOM.createPortal(children, document.getElementById('modal-root'))
