import React from 'react'
import ModalHandler from '../../utils/ModalHandler'
import ModalPortal from '../../utils/modal/ModalPortal'
import Modal from '../../utils/modal/Modal'
import ArticleCategoryList from '../ArticleCategoryList'
import IsRequiredIndicator from '../../form/IsRequiredIndicator'

import './ArticleCategoryField.scss'

const emptyLabel = 'Toutes les catégories';

const Wrapper = ({name, required, placeholder = '', children}) => (
  <div key={name} className="field column">
    <label htmlFor={'input-'+name} className="label">{placeholder}{required && <IsRequiredIndicator />}</label>
    <div className="control is-expanded">
      {children}
    </div>
  </div>
)



export default ({id, name, setInput = (name, value) => {}, required = false, selectedValue, allowEmpty = true, emptyLabel = 'Toutes les catégories', ...rest}) => (
  <Wrapper {...{name, required, placeholder: 'Catégorie'}}>
    <ModalHandler
      actioner={({ onOpen }) => (
        <div className='select is-fullwidth post-job-category-input'>
          <input
            value={(selectedValue && selectedValue.category)
              ? Object.values(selectedValue).join(' > ')
              : ""}
            className="input"
            placeholder={emptyLabel}
            onClick={onOpen}
            required
            readOnly
          />
        </div>
      )}
      modal={({ onClose }) => (
        <ModalPortal>
          <Modal onClose={onClose} contentClassname='post-job-category-input'>
            <ArticleCategoryList {...{allowEmpty, selectedValue, handleSelect: (e) => {
                setInput(name, e.target.value);
                onClose();
                console.log(e.target.value)
              }}} />
          </Modal>
        </ModalPortal>

      )}
    />
  </Wrapper>
)
