import React from 'react'
import TextInput from '../../form/input/TextInput'
import FieldWrapper from '../../form/FieldWrapper'

export default ({
  name,
  errors,
  setInput = (name, value) => {},
  selectedValue: { plainPassword, passwordConfirm },
  className,
  label,
  consentLabel,
  required,
  ...props
}) => (
  <>
    <FieldWrapper {...{
      key: name,
      id: 'input-' + name,
      className,
      label: 'Mot de passe',
      required,
      errors
    }}>
      <TextInput {...props} {...{
        name: 'plainPassword',
        errors,
        value: plainPassword,
        type: 'password',
        id: 'input-' + name,
        className: errors.length ? 'is-danger' : '',
        onChange: (e) => setInput(name, { passwordConfirm, plainPassword: e.target.value })
      }} />
    </FieldWrapper>
    <FieldWrapper {...{
      key: name,
      id: 'input-' + name,
      className,
      label: 'Confirmation du mot de passe',
      required,
      errors
    }}>
      <TextInput {...props} {...{
        name: 'passwordConfirm',
        errors,
        value: passwordConfirm,
        type: 'password',
        className: errors.length ? 'is-danger' : '',
        onChange: (e) => setInput(name, { plainPassword, passwordConfirm: e.target.value })
      }} />
    </FieldWrapper>
  </>
)