export const STATE_KEY = 'user'

export const USER = {
  fetching: `${STATE_KEY}/FETCHING`,
  fetched: `${STATE_KEY}/FETCHED`,
  fetching_error: `${STATE_KEY}/FETCHING_ERROR`,

  creating: `${STATE_KEY}/CREATING`,
  created: `${STATE_KEY}/CREATED`,
  creating_error: `${STATE_KEY}/CREATING_ERROR`,

  updating: `${STATE_KEY}/UPDATING`,
  updated: `${STATE_KEY}/UPDATED`,
  updating_error: `${STATE_KEY}/UPDATING_ERROR`,
};
