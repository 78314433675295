
import {isArray} from 'lodash'

/**
 * Build the PostJobItemConfig for the given Post::category/subCategory
 *
 * Note: post is the transformed post object!
 */
export const getPostJobItemConfig = ({category}, allPostJobItemConfig) => {
  const {common} = allPostJobItemConfig;
  
  return [
    ...common,
    ...getCategoryPostJobItemConfig(category, allPostJobItemConfig)
      .map(({group = '', ...fieldConfig}) => ({...fieldConfig, group: group || 'category-fields' })),
  ];
};

export const getCategoryPostJobItemConfig = ({category, subCategory}, allPostJobItemConfig) => {
  if(allPostJobItemConfig[category]) {
    if(subCategory && allPostJobItemConfig[category][subCategory]) {
      return allPostJobItemConfig[category][subCategory];
    }

    if(isArray(allPostJobItemConfig[category])) {
      return allPostJobItemConfig[category];
    }
  }

  return allPostJobItemConfig.defaultConfig;
}