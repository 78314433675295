import React, { useState } from 'react'
import { connect } from 'react-redux'
import { reduce } from 'lodash'

import ProtectedAreaPage from '../protectedArea/ProtectedAreaPage'
import Layout from '../utils/Layout'
import buildForm from '../form/buildForm'
import formSchema from './formSchema'
import { makeSelectCollection as makeSelectCollectionCategory } from '../../modules/category/selectors'
import { makeSelectCollection as makeSelectCollectionSection } from '../../modules/section/selectors'
import { makeSelectResource, makeSelectLoading, makeSelectUpdating, makeSelectError } from '../../modules/userArticle/selectors'
import getFormHandler from '../../modules/formHelper'
import LoadingOverlay from '../utils/LoadingOverlay'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import routes from '../../routes.json'
import DeleteUserArticleProcess from './DeleteUserArticleProcess'
import { updateUserArticle } from '../../modules/userArticle/actions'
import { publishUserArticle } from '../../modules/userArticle/actions'
import { archiveUserArticle } from '../../modules/userArticle/actions'
import ArticleCategoryField from './articleFilter/ArticleCategoryField'
import PublishAreaArticle from './PublishAreaArticle'

const ProtectedAreaArticleEditPage = ({ match, location, loading, updating, error, article, handleSubmit, handlePublishArticle, handleArchiveArticle, categoryList, sectionList }) => {
  if (loading) {
    return <LoadingOverlay />
  }

  if (!article) {
    toast.error(`Cette ressource est inaccessible`)
    return <Redirect to='/' />
  }

  const { title, status } = article

  if (status !== 'drafted') {
    toast.error(`Vous ne pouvez pas modifier une offre après l'avoir publié.`)
    return <Redirect to={routes.PROTECTED_AREA_ARTICLE_LIST} />
  }

  const formSchemaRole = formSchema(categoryList, sectionList)
  const formHandler = getFormHandler(formSchemaRole)
  const [inputs, setInputs] = useState(formHandler.initializeInputs(article))
  const [isDirty, setDirty] = useState(false);
  const hasErrors = reduce(inputs, (all, { errors }) => [...all, ...errors], []).length
  const setInput = (name, value) => {
    setDirty(false);
    formHandler.setInput(setInputs, name, value);
  }

  const beforeSubmit = (article) => {
    if (article.custom.value.sectionTitle) {
      const sectionId = sectionList.find(item => item.title === article.custom.value.sectionTitle).id
      const categoryId = categoryList.find(item => item.title === article.custom.value.category).id
      article.section = {
        value: sectionId,
        errors: []
      }
      article.category = {
        value: categoryId,
        errors: []
      }
    }
    else {
      const categoryId = categoryList.find(item => item.title === article.custom.value.category).id
      article.category = {
        value: categoryId,
        errors: []
      }
      article.section = {
        value: null,
        errors: []
      }
    }
    handleSubmit(article)
    
  }
  return (
    <ProtectedAreaPage
      match={match}
      title='Déposer une actualité'
      breadcrumbsMisc={[
        { label: 'Edit une actualité' }
      ]}
    >
      <Layout
        title='Modifier mon actualité'
      >
        <div className='post-action action-mini buttons'>
          <PublishAreaArticle
            {...{ post: article, handlePublishArticle }}
            className='button is-success'
            actionerLabel={<span style={{ paddingRight: '0.5rem' }}>Publier</span>}
          />

          <DeleteUserArticleProcess
            {...{post: article, handleArchiveArticle}}
            className="button is-danger"
            actionerLabel={<span style={{ paddingLeft: '0.5rem' }}>Supprimer</span>}
          />
        </div>
        <div className='profile-page columns is-multiline'>
          <form className="columns is-multiline is-variable is-1">
            <div className="column is-12">
              <div className="columns is-multiline is-variable is-1">
                {buildForm(
                  formSchema(categoryList, sectionList)
                    .filter(({group}) => group === 'date')
                    .map(fieldConfig => ({className: 'is-12', ...fieldConfig})),
                  inputs,
                  setInput,
                  isDirty
                )}
              </div>
              <div className="columns is-multiline is-variable is-1">
                {buildForm(
                  formSchema(categoryList, sectionList)
                    .filter(({group}) => group === 'default')
                    .map(fieldConfig => ({className: 'is-12', ...fieldConfig})),
                  inputs,
                  setInput,
                  isDirty
                )}
              </div>
            </div>
            <div className="field column is-12">
              <div className="control">
                <button
                  disabled={hasErrors}
                  className="button is-primary is-fullwidth"
                  onClick={e => {
                    e.preventDefault()
                    beforeSubmit({ ...article, ...inputs })
                  }}>
                  {'Enregistrer'}
                </button>
                <label>{hasErrors ? 'Veuillez remplir tous les champs obligatoires marqués d\'un «*»' : '' }</label>
              </div>
            </div>
          </form>
        </div>
      </Layout>
    </ProtectedAreaPage>
  )
}

const mapStateToProps = (state, props) => ({
  user: state.user.item,
  article: makeSelectResource()(state, props),
  loading: makeSelectLoading()(state, props),
  updating: makeSelectUpdating()(state, props),
  error: makeSelectError()(state, props),
  categoryList: makeSelectCollectionCategory()(state, props),
  sectionList: makeSelectCollectionSection()(state, props)
})

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleSubmit: (article) => dispatch(updateUserArticle(article)),
    handleArchiveArticle: (article) => dispatch(archiveUserArticle(article)),
    handlePublishArticle: (article) => dispatch(publishUserArticle(article))

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedAreaArticleEditPage)
