import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ color, icon, action, children }) => {
  return (
    <button onClick={action} className={`button ${color}`}>
      <span>{children}</span>
      {icon && (
        <span className="icon is-small">
          <i className={icon} />
        </span>
      )
      }
    </button>
  )
}
Button.propTypes = {
  submit: PropTypes.func
}

export default Button
