import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'
import queryString from 'query-string'

const selectNamespace = state => state[STATE_KEY] || initialState

export const makeSelectBackgroundAccountImageSrc = () =>
  createSelector(
    selectNamespace,
    ({ backgroundAccount }) => backgroundAccount
  )

// protected area
// should not use directly, see modules/protectedArea/selectors
export const makeSelectProtectedAreaLinksConfig = () =>
  createSelector(
    selectNamespace,
    ({ protectedAreaConfig }) => protectedAreaConfig
  )

// jobs
export const makeSelectPostJobOfferItemConfig = () =>
  createSelector(
    selectNamespace,
    ({ postJobOfferItemConfig }) => postJobOfferItemConfig
  )

export const makeSelectPostJobRequestItemConfig = () =>
  createSelector(
    selectNamespace,
    ({ postJobRequestItemConfig }) => postJobRequestItemConfig
  )

