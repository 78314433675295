import React from 'react'

import ProtectedAreaPage from './protectedArea/ProtectedAreaPage'
import ProtectedAreaProfileForm from './protectedAreaProfile/ProtectedAreaProfileForm'
import Layout from './utils/Layout'

export default ({ match }) => {
  return (
    <ProtectedAreaPage
      match={match}
      title="Mon profil"
      breadcrumbsMisc={[
        { label: "Mon profil" },
      ]}
    >
      <Layout
        title="Modifier mes informations"
      >
        <div className="profile-page columns is-multiline">
          <div className="column">
            <ProtectedAreaProfileForm />
          </div>
        </div>
      </Layout>
    </ProtectedAreaPage>
  )
}
