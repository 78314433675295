import React from 'react'
import classnames from 'classnames'

import ContentLink from './ContentLink'
import Image from '../utils/Image'

export default ({ content, selectedContent, active, ...props }) => (
  <ContentLink
    className={classnames('content', {
      active: selectedContent.slug === content.slug
    })}
    style={{ backgroundColor: content.color }}
    content={content}
    {...props}
  >
    <Image image={content.icon} className='icon' />
    {content.title}
  </ContentLink>
)
