import * as yup from 'yup'
import { ValidationError } from 'yup'
import { isNil, mapValues } from 'lodash'

export const getFormHandler = (fieldsConfig) => {
  const validationSchema = buildValidationSchema(fieldsConfig);

  return {
    initializeInputs: (values) => initializeInputs(fieldsConfig, values, validationSchema),
    setInput: (setInputs, name, value) => setInputs(inputs => ({
      ...inputs,
      [name]: validateField(name, value, validationSchema)
    })),
    mergeInputs,
  };
};
export default getFormHandler;

/**
 * Merge the user inputs with the original object
 */
const mergeInputs = (values, inputs) => {
  return {
    ...values,
    ...mapValues(inputs, 'value')
  }
}

/**
 * Given the fieldsConfig and values,
 *    build the corresponding inputs map for the form to consume

 * @param fieldsConfig []
 * @param values {}
 * @param validationSchema
 * @returns {[name]: {value: '', errors: []}}
 */
export const initializeInputs = (fieldsConfig, values, validationSchema = null) =>
  fieldsConfig.reduce(
    (res, {name}) => ({
        ...res,
        [name]: validateField(
          name,
          isNil(values[name]) ? undefined : values[name],
          validationSchema
        )
      }
    ), {});

const validateField = (name, input, validationSchema = null) => {
  let cleanedValue = input;
  let errors = [];

  if(validationSchema) {
    try {
      cleanedValue = validationSchema.validateSyncAt(name, {[name]: input});
    } catch (e) {
      if (!e instanceof ValidationError) {
        throw e;
      }
      errors = e.errors;
    }
  }

  return {value: cleanedValue, errors};
}


/**
 * Given the fieldsConfig,
 *    build the yup validation schema object
 *
 * @param fieldsConfig []
 * @returns {*}
 */
export const buildValidationSchema = (fieldsConfig) =>
  yup.object().shape(
    fieldsConfig.reduce((schema, {name, validation = {}, ...rest}) => {
      const {type = getDefaultShape(rest.type), required = false} = validation;

      return {
        ...schema,
        [name]: required ? type.required() : type
      }
    }, {}));

const getDefaultShape = inputType => {
  switch (inputType) {
    case 'multiple_choice':
      return yup.array(yup.string())
    case 'checkbox':
      return yup.bool()
    case 'email':
      return yup.string().email()
    case 'date':
      return yup.date().nullable(true)
    // case 'tel':
  }
  return yup.string()
}


