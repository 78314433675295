import React from 'react'

import ConfirmationModalHandler from '../utils/ConfirmationModalHandler'
import PublishModal from './UserPostJobCard/PublishModal'

/**
 * Display multi modal if published offer, confirmation only otherwise
 */
export default ({ post, handlePublishPost, className = 'tag is-danger', actionerLabel = '' }) => (
  <MyConfirmationModalHandler {...{
    post,
    className,
    actionerLabel,
    onConfirmation: () => handlePublishPost(post)
  }} />
)

const MyConfirmationModalHandler = ({ post, onConfirmation, className = 'tag is-success', actionerLabel = '' }) => (
  <ConfirmationModalHandler
    {...{ actionerLabel, className, onConfirmation }}
    Modal={PublishModal}
    modalChildren={(
      <>
        <h5 className='is-size-4'>
          Êtes-vous sûr de vouloir publier cette annonce ?
        </h5>
        <p>{post.title}</p>
      </>
    )}
  />
)
