import React, { useState } from 'react'
import { connect } from 'react-redux'

import { Helmet } from 'react-helmet'
import { postContactMessage } from '../../modules/contactMessage/actions'
import ControlledNavBar from '../utils/ControlledNavBar'
import ControlledBreadcrumbs from '../utils/ControlledBreadcrumbs'
import { makeSelectResource as makeSelectCategoryResource } from '../../modules/category/selectors'
import Page from '../Page'

import './Contact.scss'
import SearchBar from '../utils/SearchBar'
import Layout from '../utils/Layout'

/**
 * @todo refactor with buildForm()
 */
const Contact = ({ match, category = null, postMessage }) => {
  const [inputs, setInputs] = useState({
    lastName: '',
    firstName: '',
    email: '',
    content: ''
  })
  const [errors, setErrors] = useState({
    lastName: '',
    firstName: '',
    email: '',
    content: ''
  })
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    })
  }

  const checkField = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (!value) {
      return setErrors({ ...errors, [name]: 'Ce champ ne doit pas être vide' })
    }
    if (name === 'email' && !value.match('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}')) {
      return setErrors({ ...errors, [name]: "Cet email n'est pas valide" })
    }
    return setErrors({ ...errors, [name]: '' })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    postMessage(inputs)
  }

  return (
    <Page match={match}>
      <div className='page page-content page-contact'>
        <Helmet>
          <title>Nous contacter</title>
        </Helmet>

        {category && <ControlledNavBar selectedCategory={category} match={match} />}

        <ControlledBreadcrumbs {...{ category, misc: [{ label: 'Nous contacter' }] }} />
        <SearchBar />

        <Layout title='Nous contacter'>
          <form onSubmit={handleSubmit}>
            {[
              { label: 'Prénom', name: 'firstName', required: true },
              { label: 'Nom', name: 'lastName', required: true },
              { label: 'Email', name: 'email', type: 'email', required: true }
            ].map(({ label, name, type = 'text', required = false }) => (
              <div className='field' key={name}>
                <label className='label'>
                  {label}
                  {required && <span className='requiredfield'> *</span>}
                </label>
                <div className='control'>
                  <input className={`input ${errors[name] && 'is-danger'}`} name={name} onBlur={checkField} onChange={handleChange} type={type} value={inputs[name]} required={required} />
                </div>
                {errors[name] && <span className='error'> {errors[name]}</span>}
              </div>
            ))}

            <div className='field'>
              <label className='label'>
                Votre question
                <span className='requiredfield'> *</span>
              </label>
              <div className='control'>
                <textarea className={`textarea ${errors.content && 'is-danger'}`} name='content' onBlur={checkField} onChange={handleChange} value={inputs.content} required />
              </div>
              {errors.content && <span className='error'> {errors.content}</span>}
            </div>

            <div className='buttonsContainer'>
              <button type={'submit'}>
                Envoyer mon message <i className='fas fa-check' />
              </button>
            </div>
          </form>
        </Layout>
      </div>
    </Page>
  )
}

const mapStateToProps = (state, props) => {
  return {
    category: makeSelectCategoryResource()(state, props),
    error: state.error
  }
}

const mapDispatchToProps = (dispatch) => ({
  postMessage: (contactMessageForm) => dispatch(postContactMessage(contactMessageForm))
})

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
