
/** transform api object to domain object */
export const transform = ({
  category, subCategory,
  ...object}) => ({
    ...object,
    category: {category, subCategory},
  })

/** transform domain object to api object */
export const deTransform = ({
  category: {category, subCategory},
  ...object}) => ({
  ...object,
  category,
  subCategory,
})