import React from 'react'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router'
import Layout from '../utils/Layout'
import ProtectedAreaPage from '../protectedArea/ProtectedAreaPage'
import PostJobTitle from '../postJob/PostJobTitle'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import routes from '../../routes'
import { getPostJobOfferUri, getUserPostJobOfferEditUri } from '../../router'
import DeleteUserPostProcess from './DeleteUserPostProcess'

export default ({ match, location, post, breadcrumbsMisc = [{ label: "Mes annonces job" }], titlePage, infoMessage, children }) => {
  if (!post) {
    toast.error(`Cette ressource est inaccessible`)
    return <Redirect to='/' />
  }

  const {id, title, status} = post;
  return (
    <ProtectedAreaPage
      match={match}
      title={title}
      breadcrumbsMisc={breadcrumbsMisc}
      className='page page-post'
    >
      <Layout
        notification={infoMessage}
        title={titlePage}>
        {children}
      </Layout>
    </ProtectedAreaPage>
  )
}
