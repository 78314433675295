import React from 'react'
import { connect } from 'react-redux'

import { makeSelectResource as makeSelectCategoryResource } from '../modules/category/selectors'
import { makeSelectResource as makeSelectSectionResource } from '../modules/section/selectors'
import { makeSelectCollectionForSection as makeSelectContentCollectionForSection } from '../modules/content/selectors'
import { makeSelectCollectionForSection as makeSelectArticleCollectionForSection } from '../modules/article/selectors'

import { Helmet } from 'react-helmet'
import SectionSlider from './SectionSlider'
import ControlledBreadcrumbs from './utils/ControlledBreadcrumbs'
import ControlledNavBar from './utils/ControlledNavBar'
import Page from './Page'
import SearchBar from './utils/SearchBar'
import Image from './utils/Image'
import classnames from 'classnames'
import ContentLink from './contents/ContentLink'
import InfoUrl from '../components/InfoUrl/InfoUrl'

const SectionPage = ({ match, category, section, articles, contents }) => (
  <Page match={match} className='page page-section'>
    <Helmet>
      <title>{`${section.title} - ${category.title}`}</title>
    </Helmet>

    <ControlledNavBar selectedCategory={category} selectedSection={section} match={match} />

    <ControlledBreadcrumbs {...{ category, section }} />
    <SearchBar />

    <SectionSlider {...{ category, section, articles, contents }} />
    <Tiles {...{category, section, contents}} />
    <InfoUrl info={category.footer} />
  </Page>
)

const mapStateToProps = (state, props) => ({
  category: makeSelectCategoryResource()(state, props),
  section: makeSelectSectionResource()(state, props),
  contents: makeSelectContentCollectionForSection()(state, props),
  articles: makeSelectArticleCollectionForSection()(state, props)
})

export default connect(mapStateToProps)(SectionPage)


const Tiles = ({contents, section, category}) => (
  <>
    <div className="mtiles columns is-multiline is-mobile is-variable is-3-desktop is-0-tablet is-0-mobile"
         style={{width:'100%'}}
    >
      {contents.map((item, i) => (
        <ContentLink
          key={item.id}
          category={category}
          section={section}
          content={item}
          className={classnames('mtile column', {
            'is-one-fifth-desktop': (contents.length >= 5) && (i !== contents.length),
            'is-one-quarter-desktop': (contents.length < 5),
            'is-half-mobile': (!contents.length % 2) && (i === contents.length),
            'iconOnly': ! item.title.length
          })}
        >
          <div
            className={classnames("mtile-container", {iconOnly: !item.title.length})}
            style={{ backgroundColor: section.color }}
          >
            <Image image={item.icon} className='icon' />
            {item.title && <h3>{item.title}</h3>}
          </div>
        </ContentLink>
      ))}
    </div>
  </>
)