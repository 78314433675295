import React from 'react'
import classnames from 'classnames'
import Glide from '@glidejs/glide'

import GlideContent from './slider/Content'
import GlideBullets from './slider/Bullets'
import GlideSlide from './slider/Slide'

import './slider/Slider.scss'
import backgroundImageHelper from './backgroundImageHelper'

let glide

export default class Slider extends React.Component {
  componentDidMount () {
    glide = new Glide('#glide', {
      type: 'carousel',
      gap: 0,
      dots: true,
      animationDuration: 1500,
      autoheight: false,
      autoplay: 5000
    })

    // needed when the slider's page is not the original loaded page (Glide sux)
    new Promise(resolve => setTimeout(resolve, 200)).then(() => {
      this.props.items.length && glide.mount()
    })
  }

  componentWillUnmount () {
    this.props.items.length && glide.destroy()
  }

  render () {
    const { items, background, withBreadcrumb, children = () => <div />, renderSlide = () => '' } = this.props

    if (!items.length) {
      return (
        <div id='glide' style={backgroundImageHelper(background)}>
          <GlideContent>{children}</GlideContent>
        </div>
      )
    }

    return (
      <div id='glide' className={classnames({ withBreadcrumb })}>
        <div className='glide__track' data-glide-el='track'>
          <ul className='glide__slides'>
            {items.map(item => (
              <GlideSlide
                key={item.id}
                style={backgroundImageHelper(item.background)}
              >
                {renderSlide(item)}
              </GlideSlide>
            ))}
          </ul>
        </div>
        <GlideBullets
          items={items}
          handleClick={index => glide.go(`=${index}`)}
        />
        <GlideContent>{children}</GlideContent>
      </div>
    )
  }
}
