import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

const selectNamespace = state => state[STATE_KEY] || initialState
const selectPostProps = (state, props) => props.post

export const makeSelectCollection = () =>
  createSelector(
    [selectNamespace],
    ({ items }) => items
  )

export const makeSelectCreating = () =>
  createSelector(
    selectNamespace,
    state => state.creating
  )

export const makeSelectResource = () =>
  createSelector(
    [selectNamespace, selectPostProps],
    ({ items }, { id }) => items.find(item => item === id)
  )
