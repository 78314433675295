import rawRequest from '../apiHelper'
import queryString from 'qs'

const request = (params) => rawRequest({
  ...params,
  baseUrl: 'https://api-adresse.data.gouv.fr/'
})

export const search = (q, config = {}, onSuccess = () => {}) => request({
    baseUrl: 'https://api-adresse.data.gouv.fr/',
    endpoint: 'search/?'+queryString.stringify({...{
      q,
      type: 'housenumber',
      autocomplete: 1,
      //lat: 47.32, lon: 5.06 // priority Dijon, does not work ?
    }, ...config}),
  }).then(({data}) => data)
    .then(({features}) => features.map(({
      properties: {score, id, type, x, y, importance, cityCode, context, ...properties},
      geometry: {coordinates}
    }) => ({...properties, coordinates: {
      lon: coordinates[0],
      lat: coordinates[1]
    }})))
    .then(data => {
      onSuccess(data)
    })
