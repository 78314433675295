import React from 'react'
import * as yup from 'yup'
import WithConsentTextField from '../../app/protectedAreaProfile/protectedAreaProfileForm/WithConsentTextField';
import AvatarInput from '../../app/protectedAreaProfile/protectedAreaProfileForm/AvatarInput';
import PasswordConfirmation from '../../app/protectedAreaProfile/protectedAreaProfileForm/PasswordConfirmation';

const withConsentValidation = {
  type: yup.object().shape({
    consent: yup.bool(),
    value: yup.string().required()
  }),
  required: true
}

const withConsentValidationEmail = {
  type: yup.object().shape({
    consent: yup.bool(),
    value: yup.string().required().email()
  }),
  required: true
}

const passwordValidation = {
  type: yup.object().shape({
    plainPassword: yup.string()
      .min(8, 'Mot de passe trop court - il doit contenir au moins 8 caractères.')
      .matches('(?=^.{8,}$)(?=.*\\d)(?=.*\\W+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$', '(Minimum 8 caractères, 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial)'),
    passwordConfirm: yup.string()
      .oneOf([yup.ref('plainPassword'), null], 'Les mots de passes doivent être identique')
  }),
  required: true
}

export default [
  {
    group: 'default',
    label: 'Avatar',
    name: 'avatar',
    type: 'custom',
    roles: ['ROLE_USER'],
    Component: AvatarInput,
    className: 'is-2',
    validation: {
      type: yup.object().shape({
        base64: yup.string().required(),
        publicPath: yup.string()
      }).nullable(true),
      required: true
    }
  },
  {
    group: 'default',
    label: 'Nom',
    name: 'lastName',
    className: 'is-5',
    type: 'custom',
    roles: ['ROLE_USER'],
    validation: withConsentValidation,
    Component: (props) => (
      <WithConsentTextField
        {...props}
        consentLabel=" J'accepte de voir apparaître mon nom sur le site public"
      />
    )
  },
  {
    group: 'default',
    label: 'Prénom',
    name: 'firstName',
    className: 'is-5',
    type: 'custom',
    roles: ['ROLE_USER'],
    validation: withConsentValidation,
    Component: (props) => (
      <WithConsentTextField
        {...props}
        consentLabel=" J'accepte de voir apparaître mon prénom sur le site public"
      />
    )
  },
  {
    group: 'default',
    label: 'Nom',
    name: 'lastName',
    className: 'is-6',
    type: 'custom',
    roles: ['ROLE_PROFESSIONAL'],
    validation: withConsentValidation,
    Component: (props) => (
      <WithConsentTextField
        {...props}
        consentLabel=" J'accepte de voir apparaître mon nom sur le site public"
        hiddenConsent
      />
    )
  },
  {
    group: 'default',
    label: 'Prénom',
    name: 'firstName',
    className: 'is-6',
    type: 'custom',
    roles: ['ROLE_PROFESSIONAL'],
    validation: withConsentValidation,
    Component: (props) => (
      <WithConsentTextField
        {...props}
        consentLabel=" J'accepte de voir apparaître mon prénom sur le site public"
        hiddenConsent
      />
    )
  },
  { group: 'default',
    label: 'Pseudonyme',
    name: 'nickname',
    validation: {type: yup.string(), required: true} },
  {
    group: 'default',
    label: 'Email',
    name: 'email',
    type: 'custom',
    roles: ['ROLE_USER'],
    validation: withConsentValidationEmail,
    Component: (props) => (
      <WithConsentTextField
        {...props}
        type='email'
        consentLabel=" J'accepte de voir apparaître mon email sur le site public"
      />
    )
  },
  {
    group: 'default',
    label: 'Email',
    name: 'email',
    type: 'custom',
    roles: ['ROLE_PROFESSIONAL'],
    validation: withConsentValidationEmail,
    Component: (props) => (
      <WithConsentTextField
        {...props}
        type='email'
        consentLabel=" J'accepte de voir apparaître mon email sur le site public"
        hiddenConsent
      />
    )
  },
  { group: 'default',
    type: 'custom',
    name: 'password',
    validation: passwordValidation,
    Component: (props) => (
      <PasswordConfirmation
        {...props}
      />
    )
  },
  { group: 'default',
    label: 'Date de naissance',
    name: 'birthdate',
    type: 'date',
    roles: ['ROLE_USER'],
    validation: { required: true } },
  { group: 'default', label: 'Sexe',
    name: 'gender',
    type: 'choice',
    choices: [
      { value: 'female', label: 'Femme' },
      { value: 'male', label: 'Homme' }
    ],
    validation: { required: true } },
  {
    group: 'default',
    roles: ['ROLE_USER'],
    label: 'Statut',
    name: 'status',
    type: 'choice',
    choices: [
      { value: 'collégien', label: 'Collégien' },
      { value: 'lycéen', label: 'Lycéen' },
      { value: 'étudiant', label: 'Étudiant' },
      { value: "demandeur d'emploi", label: "Demandeur d'emploi" },
      { value: 'en emploi', label: 'En emploi' },
      { value: 'apprenti', label: 'Apprenti' },
      { value: 'en formation', label: 'En formation' },
      { value: 'autre', label: 'Autre' }
    ],
    validation: { required: true } },
  { group: 'default', roles: ['ROLE_PROFESSIONAL'], label: 'Fonction', name: 'job' },

  { group: 'coordinates', label: 'Adresse', name: 'address', roles: ['ROLE_USER'],
    validation: { required: true } },
  { group: 'coordinates', label: 'Code postal', name: 'zipcode', roles: ['ROLE_USER'],
    validation: { required: true } },
  { group: 'coordinates', label: 'Ville', name: 'city', roles: ['ROLE_USER'],
    validation: { required: true } },
  { group: 'coordinates', label: 'Département', name: 'region', roles: ['ROLE_USER'],
    validation: { required: true } },
  { group: 'coordinates', label: 'Tél. principal', name: 'mainPhone',
    type: 'custom',
    roles: ['ROLE_USER'],
    validation: withConsentValidation,
    Component: (props) => (
      <WithConsentTextField
        {...props}
        type='tel'
        consentLabel=" J'accepte de voir apparaître mon numéro sur le site public"
      />
    )
  },
  { group: 'coordinates', label: 'Tél. principal', name: 'mainPhone',
    type: 'custom',
    roles: ['ROLE_PROFESSIONAL'],
    validation: withConsentValidation,
    Component: (props) => (
      <WithConsentTextField
        {...props}
        type='tel'
        consentLabel=" J'accepte de voir apparaître mon numéro sur le site public"
        hiddenConsent
      />
    )
  },
  { group: 'coordinates', label: 'Commentaire', name: 'commentMainPhone', placeholder: 'Exemple : joignable de 8h00 à 17h00' },
  { group: 'coordinates', label: 'Tél. secondaire', name: 'sparePhone', type: 'tel', roles: ['ROLE_USER']},
  { group: 'coordinates', label: 'Commentaire', name: 'commentSparePhone', placeholder: 'Exemple : joignable de 8h00 à 17h00', roles: ['ROLE_USER']},

  { group: 'other', label: 'Je souhaite recevoir la newsletter', name: 'consentNews', type: 'checkbox', },
  { group: 'other', label: "J'ai lu et j'accepte les conditions d'utilisation", name: 'consentTerms', type: 'checkbox', validation: { type: yup.bool().oneOf([true], "vous devez accepter les conditions d'utilisations"), required: true } }
]
