import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import PostCard from '../post/PostCard'
import PostJobCardActions from './PostJobCardActions'
import PostJobTitle from '../postJob/PostJobTitle'

export default ({ post,
  handleArchivePost = (post, archivingData) => {},
  routeName,
  getLinkProps = (post) => ({}),
  ...props
}) => {
  const {id, title, category, subCategory, jobPicture, fullAddress, created, published} = post;

  const linkProps = getLinkProps(post);

  return (
    <PostCard {...{
      linkProps,
      title: <>{category} / {subCategory}</>,
      titleColor: '#000',
      thumbPicture: jobPicture
    }}>
      <div className="column is-12">
        <ControlledLoadableLink {...linkProps}>
          <h2>
            <PostJobTitle {...post} />
          </h2>
        </ControlledLoadableLink>
      </div>
      <div className="column is-paddingless">
        <p className="location">
          <i className="fas fa-lg fa-map-marker" /> {`${fullAddress.city} (${fullAddress.postcode.substr(0, 2)})`}
        </p>
      </div>
      <div className="column is-paddingless">
        <p className="date">
          <i className="fas fa-lg fa-calendar" /> {moment(published || created).format('Do MMMM YYYY')}
        </p>
      </div>
      <PostJobCardActions {...{post, handleArchivePost}} />
    </PostCard>
  );
}
