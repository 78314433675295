import React from 'react'
import SelectAccountTypeModal from './SelectAccountTypeModal'
import ModalHandler from '../utils/ModalHandler'

export default () => (
  <div className="box registration-hero">
    <div className="columns is-multiline">
      <div className="column is-12">
        <h2>Pas encore inscrit(e) ?</h2>
        <div>
          La création de votre compte perso vous permet de :
          <ul>
            <li><i className='fas fa-check' />Publier une actualité, un évènement</li>
            <li><i className='fas fa-check' />Déposer des offres (jobs, emploi, stage, alternance)</li>
            <li><i className='fas fa-check' />Proposer sa candidature (jobs, baby-sitting, cours particuliers, animation)</li>
            <li><i className='fas fa-check' />Recevoir des alertes jobs, logement</li>
            <li><i className='fas fa-check' />Sauvegarder des annonces</li>
            <li><i className='fas fa-check' />Poser votre question</li>
            <li><i className='fas fa-check' />S'inscrire à la newlsetter</li>
          </ul>
          La création de votre espace vous permet également d'accéder à tous les services de l'Information Jeunesse en Bourgogne-Franche-Comté :
          <br/>avantagesjeunes.com ; agitateursdemobilite.fr ; energiejeune.fr ; topo-bfc.info
          <br/><span>(pour l'instant chaque internaute à un lien de connexion vers chaque espace avec les infos liées)</span>
        </div>
      </div>
      <div className="column is-12 has-text-centered">
        <ModalHandler
          actioner={({ onOpen }) => (
            <div className='createaccountlink'>
              <a onClick={onOpen} className="button is-link">
                Je crée mon espace
              </a>
            </div>
          )}
          modal={props => <SelectAccountTypeModal {...props} />}
        />
      </div>
    </div>
  </div>
)