import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import routes from '../../routes'
import Tabs from '../utils/Tabs'
import React from 'react'

export default ({match}) => (
  <>
    <div className="column is-8">
      <Tabs items={[
        {
          isActive: match.path.substr(0, routes.PROTECTED_AREA_JOB_OFFER_LIST.length) === routes.PROTECTED_AREA_JOB_OFFER_LIST,
          item: <a>Mes offres</a>
        },
        {
          isActive: match.path.substr(0, routes.PROTECTED_AREA_JOB_REQUEST_LIST.length) === routes.PROTECTED_AREA_JOB_REQUEST_LIST,
          item: <a>Mes candidatures</a>
        },
      ]} />
    </div>
    <div className="column is-4 has-text-right" style={{marginTop: '0.7rem'}}>
      <ControlledLoadableLink
        routeName={routes.PROTECTED_AREA_JOB_OFFER_CREATE}
        to={routes.PROTECTED_AREA_JOB_OFFER_CREATE}
        className="button is-primary is-fullwidth"
      >
        +
        Déposer votre annonce
      </ControlledLoadableLink>
    </div>
  </>
)