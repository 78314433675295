import React from 'react'
import ModalHandler from '../../utils/ModalHandler'
import ModalPortal from '../../utils/modal/ModalPortal'
import Modal from '../../utils/modal/Modal'
import PostJobCategoryList from '../PostJobCategoryList'

import './PostJobCategoryField.scss'

const emptyLabel = 'Toutes les catégories';

const Wrapper = ({name, placeholder = '', children}) => (
  <div key={name} className="field column">
    <label htmlFor={'input-'+name} className="label">{placeholder}</label>
    <div className="control is-expanded">
      {children}
    </div>
  </div>
)

export default ({id, name, setInput = (name, value) => {}, selectedValue = {}, allowEmpty = true, emptyLabel = 'Toutes les catégories', ...rest}) => (
  <Wrapper {...{name, placeholder: 'Catégorie'}}>
    <ModalHandler
      actioner={({ onOpen }) => (
        <div className='select is-fullwidth post-job-category-input'>
          <input
            value={(selectedValue && selectedValue.category)
              ? Object.values(selectedValue).join(' > ')
              : ''}
            className="input"
            onClick={onOpen}
            readOnly
            placeholder={emptyLabel}
          />
        </div>
      )}
      modal={({ onClose }) => (
        <ModalPortal>
          <Modal onClose={onClose} contentClassname='post-job-category-input'>
            <PostJobCategoryList {...{allowEmpty, selectedValue, handleSelect: (e) => {
                setInput(name, e.target.value);
                onClose();
              }}} />
          </Modal>
        </ModalPortal>

      )}
    />
  </Wrapper>
)
