import React from 'react'

export default ({
  items,
  handleClick
}) => (
  <div className='glide__bullets' data-glide-el='controls[nav]'>
    {items.map((item, index) => (
      <button
        type='button'
        key={item.id}
        className='glide__bullet'
        data-glide-dir={'=' + index}
        title={item.title}
        onClick={() => handleClick(index)}
      />
    ))}
  </div>
)
