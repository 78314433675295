import React from 'react'
import truncate from 'truncate-html'
import routes from '../../routes.json'

import ArticleLink from '../articles/ArticleLink'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import Image from '../utils/Image.js'
import { connect } from 'react-redux'
import { makeSelectCollectionForProtectedArea } from '../../modules/article/selectors'
import { makeSelectCollection as makeSelectCategoryCollection } from '../../modules/category/selectors'
import { makeSelectUserProtectedAreaLinksConfig } from '../../modules/protectedArea/selectors'
import Slider from '../utils/Slider'
import classnames from 'classnames'

const ProtectedAreaSlider = ({ articles = [], categories = [], protectedAreaLinks = [], userRoles = [] }) => {
  truncate.setup({ stripTags: true, length: 60, reserveLastWord: true })

  return (
    <Slider items={articles} renderSlide={item => (
      <ArticleLink key={item.id} article={item} category={categories.find(category => item.categories[0] && item.categories[0].id === category.id)}>
        <h2 className='glide__title'>{item.title}</h2>
        <div className='glide__excerpt' dangerouslySetInnerHTML={{ __html: truncate(item.introduction) }} />
      </ArticleLink>
    )}>
      <Tiles protectedAreaLinks={protectedAreaLinks} />
    </Slider>
  )
}

const mapStateToProps = (state, props) => {
  return {
    categories: makeSelectCategoryCollection()(state, props),
    articles: makeSelectCollectionForProtectedArea()(state, props),
    protectedAreaLinks: makeSelectUserProtectedAreaLinksConfig()(state, props)
  }
}

export default connect(mapStateToProps)(ProtectedAreaSlider)

const Tiles = ({protectedAreaLinks}) => (
  <div className="mtiles columns is-multiline is-mobile is-variable is-3-desktop is-0-tablet is-0-mobile">
    {protectedAreaLinks.map((item, i) => (
      <ControlledLoadableLink
        key={item.id}
        className={classnames('mtile column', {
          'is-one-fifth-desktop': (protectedAreaLinks.length >= 5),
          'is-half-mobile': (!protectedAreaLinks.length % 2) && (i === protectedAreaLinks.length),
        })}
        routeName={routes[item.routeName]}
        to={routes[item.routeName]}
        args={item.args}
      >
        <div
          className="mtile-container"
          style={{ backgroundColor: item.color }}
        >
          <Image image={item.icon} className='icon' />
          <h3>{item.title}</h3>
        </div>
      </ControlledLoadableLink>
    ))}
  </div>
)
