import React from 'react'
import Header from './utils/Header'
import Footer from './utils/Footer'

export default ({ children, match, className = 'page', isPersonalSpace = false }) => {
  return (
    <div className={className}>
      <Header match={match} isPersonalSpace={isPersonalSpace} />
      {children}
      <Footer match={match} />
    </div>

  )
}
