import React from 'react'
import getFormHandler from '../../modules/formHelper'
import buildForm from '../form/buildForm'

import './postJobFilter/PostJobFilter.scss'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import routes from '../../routes.json'
import ControlledSaveSearch from './ControlledSaveSearch'

export default ({clearForm, filterSchema = [], filterValues, handleSubmit = (userInputs) => {}, saveSearch, location, type}) => {
  const formHandler = getFormHandler(filterSchema);

  //const [inputs, setInputs] = useState(formHandler.initializeInputs(filterValues));
  // submit inputs directly
  const inputs = formHandler.initializeInputs(filterValues);
  const setInputs = (getUserInputs) => handleSubmit(formHandler.mergeInputs(filterValues, getUserInputs(inputs)));

  const setInput = (name, value) => formHandler.setInput(setInputs, name, value);

  clearForm = () => {
    document.getElementById("filter").reset()
  }    
  
  return (
    <form id='filter' className="columns is-multiline is-variable is-1 filter-bar">
      {buildForm(filterSchema, filterValues, setInput)}
      <div className='column is-12'>
        <ControlledSaveSearch location={location} type={type} />

        <button
          className='button is-success action'
          style={{float: 'right'}}
          onClick={clearForm}
        >
          Réinitialiser le filtre
        </button>
      </div>
    </form>
  );
}