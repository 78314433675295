import React from 'react'
import { connect } from 'react-redux'
import { makeSelectCollection as makeSelectCategoryCollection } from '../../modules/category/selectors'
import { makeSelectCollection as makeSelectSectionCollection } from '../../modules/section/selectors'
import classnames from 'classnames'

import './ArticleCategoryList.scss'

const emptyLabel = 'Toutes les catégories';

const CategoryItem = ({title, value, sections = [], handleSelect, selectedValue, publicSections, ...props}) => (
  <div {...props}>
    <b
      className={classnames('category-title', {selected: JSON.stringify(selectedValue) === JSON.stringify(value)})}
      onClick={e => handleSelect({...e, target:{value}})}
    >
      {title}
    </b>
    {sections && (
      <div className={'subcategories'}>
        {sections.map(categorySection => {
          const sectionTitle = publicSections.find(section => section.id == categorySection.id).title
          const subValue = {category: title, sectionTitle};
          return (
            <div key={'subcat-'+sectionTitle}
                 className={classnames('subcategory-title', {
                   selected: JSON.stringify(selectedValue) === JSON.stringify(subValue)
                 })}
                 onClick={e => handleSelect({...e, target:{value: subValue}})}
            >
              {sectionTitle}
            </div>
          )
        })}
      </div>
    )}
  </div>
)

const ArticleCategoryList = ({categories = [], publicSections = [], selectedValue = {category:''}, handleSelect = (e) => {}}) => (
  <div className='category-list columns is-variable is-1 is-multiline has-text-centered is-marginless' style={{background: '#fff'}}>
    {categories.map(({title, sections = []}) =>
      <CategoryItem
        className='column is-6'
        key={'cat-'+title}
        publicSections={publicSections}
        {...{title, value: {category: title}, handleSelect, sections, selectedValue }}
      />
    )}
  </div>
)

const mapStateToProps = (state, props) => ({
  categories: makeSelectCategoryCollection()(state, props),
  publicSections: makeSelectSectionCollection()(state, props)
})

export default connect(mapStateToProps)(ArticleCategoryList)
