import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'

import getFormHandler from '../../modules/formHelper'
import { reduce } from 'lodash'
import buildForm from '../form/buildForm'

import GeoApiGouvAdresseField from '../utils/GeoApiGouvAdresseField'
import { deTransform, transform } from '../../modules/userPostJobOffers/model'
import { getPostJobItemConfig } from '../../modules/postJobCategories/postJobCategoryFormHelper'
import { makeSelectPostJobOfferItemConfig } from '../../modules/config/selectors'
import PostJobCategoryField from './postJobFilter/PostJobCategoryField'

/**
 * Transform custom types (from site_config.yml) into Component & validation
 */
const getComponentAndValidation = (type, validation = {}) => {
  if(type.substr(0, 'special'.length) !== 'special') {
    return {
      type,
      validation
    }
  }

  switch (type) {
    case 'specialJobCategory':
      return {
        type: 'custom',
        allowEmpty: false,
        Component: ({...props}) => (
          <PostJobCategoryField {...props} emptyLabel="" />
        ),
        validation: {...validation, type: yup.object().shape({
            category: yup.string().required(),
            subCategory: yup.string()
          }),
        }
      }
    // ...
    case 'specialLocation':
      return {
        type: 'custom',
        Component: GeoApiGouvAdresseField,
        validation: {...validation,
          type: yup.object().shape({
            coordinates: yup.object().shape({
              lat: yup.string().required(),
              lon: yup.string().required()
            }),
            label: yup.string(),
            name: yup.string(),
            city: yup.string().required(),
            postcode: yup.string().required(),
          })
        }
      }
    // ...
  }

  throw new Error('"'+ type +'" is not implemented!');
}

const _extractFormSchema = (postJobItemConfig, post) => {
  return postJobItemConfig
    .map(({type = '', validation, ...inputConfig}) => ({
      ...inputConfig,
      ...getComponentAndValidation(type, validation),
    }))
    ;
}

const getPostJobItemFormSchema = ({category: {category}}, postJobItemConfig) => {
  let config = postJobItemConfig;
  if(!category) {
    // display first step only
    config = postJobItemConfig.filter(({group}) => group === 'first-step')
  }

  return _extractFormSchema(config);
}

const reloadFormSchema = (post, allPostJobItemConfig) => {
  const postJobItemConfig = getPostJobItemConfig(post, allPostJobItemConfig);
  return getPostJobItemFormSchema(post, postJobItemConfig);
}

const PostJobItemForm = ({ handleSubmit, post, allPostJobItemConfig, ...props }) => {
  const formSchema = reloadFormSchema(post, allPostJobItemConfig);

  const formHandler = getFormHandler(formSchema);
  const [inputs, setInputs] = useState(formHandler.initializeInputs(post));
  const [isDirty, setDirty] = useState(false);
  const hasErrors = reduce(inputs, (all, {errors}) => [...all, ...errors], []).length;

  const setInput = (name, value) => {
    setDirty(false);
    formHandler.setInput(setInputs, name, value);
  }

  const {category} = post.category;
  console.log('formSchema', formSchema[0].Component)
  console.log('inputs', inputs)
  console.log(inputs.desiredExperience)
  return (
    <form className="columns is-multiline is-variable is-1">
      <div className="column is-12">
        {!category && (
          <div className="columns is-multiline is-variable is-1">
            <div className="column is-12">
              <h4 className="is-size-5">
                Sélectionnez la catégorie et sous-catégorie de votre annonce :
              </h4>
            </div>
            {buildForm(
              formSchema
                .filter(({group}) => group === 'first-step')
                .map(fieldConfig => ({className: 'is-12', ...fieldConfig})),
              inputs,
              setInput,
              isDirty
            )}
          </div>
        )}

        {category && <>
          <div className="columns is-multiline is-variable is-1">
            <div className="column is-6">
              <label className="label">Catégorie</label>
              <input className="input" value={post.category.category} disabled />
            </div>
            <div className="column is-6">
              <label className="label">&nbsp;</label>
              <input className="input" value={post.category.subCategory} disabled />
            </div>
          </div>
          <div className="columns is-multiline is-variable is-1">
            {buildForm(
              formSchema
                .filter(({group}) => group === 'default')
                .map(fieldConfig => ({className: 'is-12', ...fieldConfig})),
              inputs,
              setInput,
              isDirty
            )}
          </div>
          <div className="columns is-multiline is-variable is-1">
            <div className="column is-12">
              <h4 className="is-size-4">Informations complémentaires</h4>
            </div>
            {buildForm(
              formSchema
                .filter(({group}) => group === 'category-fields')
                .map(fieldConfig => ({className: 'is-12', ...fieldConfig})),
              inputs,
              setInput,
              isDirty
            )}
          </div>
          <div className="columns is-multiline is-variable is-1">
            <div className="column is-12">
              <h4 className="is-size-4">Lieu de travail</h4>
            </div>
            {buildForm(
              formSchema
                .filter(({group}) => group === 'location')
                .map(fieldConfig => ({className: 'is-12', ...fieldConfig})),
              inputs,
              setInput,
              isDirty
            )}
          </div>
          <div className="columns is-multiline is-variable is-1">
            <div className="column is-12">
              <h4 className="is-size-4">Contact</h4>
            </div>
            {buildForm(
              formSchema
                .filter(({group}) => group === 'contact')
                .map(fieldConfig => ({className: 'is-12', ...fieldConfig})),
              inputs,
              setInput,
              isDirty
            )}
          </div>
        </>}
      </div>
      <div className="field column is-12">
        <div className="control">
          <button
            disabled={hasErrors}
            className="button is-primary is-fullwidth"
            onClick={e => {
              e.preventDefault();
              handleSubmit(formHandler.mergeInputs(post, inputs));
            }}>
            {category ? 'Enregistrer' : 'Étape suivante >'}
          </button>
        </div>
      </div>

    </form>
  )
}

const mapStateToProps = (state, props) => ({
  allPostJobItemConfig: makeSelectPostJobOfferItemConfig()(state, props),
})

const mergeProps = (stateProps, dispatchProps, {handleSubmit, ...ownProps}) => {
  return ({
    ...stateProps, ...dispatchProps, ...ownProps,
    handleSubmit: (post) => handleSubmit(deTransform(post)),
    post: transform(ownProps.post),
  });
}

export default connect(mapStateToProps, () => ({}), mergeProps)(PostJobItemForm)
