import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { makeSelectCollection as makeSelectOfferCollection } from '../../modules/userPostJobSavedOffers/selectors'
import PostList from '../post/PostList'
import PostJobCard from '../postJob/PostJobCard'
import routes from '../../routes'
import { getPostJobOfferUri } from '../../router'
import ControlledSavePostJobOffer from '../postJob/ControlledSavePostJobOffer'

const UserJobOfferSavedList = ({ offers = [], maxItems, location }) => {
  return (
    <div>
      <PostList CardComponent={PostJobCard} ControlledSavePost={ControlledSavePostJobOffer} posts={offers} location={location} getCardLinkProps={
        (post) => ({
          routeName: routes.PUBLIC_POST_OFFER,
          to: getPostJobOfferUri(post.id)
        })
      } />
    </div>
  )
}

const mapStateToProps = (state, props) => ({
  offers: makeSelectOfferCollection()(state, props)
})

export default withRouter(connect(mapStateToProps)(UserJobOfferSavedList))
