export const STATE_KEY = 'user_post_job_saved_requests'

export const USER_POST_JOB_SAVED_REQUESTS = {
  fetching: `${STATE_KEY}/FETCHING`,
  fetched: `${STATE_KEY}/FETCHED`,
  fetching_error: `${STATE_KEY}/FETCHING_ERROR`
}

export const USER_POST_JOB_SAVED_REQUEST = {
  save: `${STATE_KEY}/SAVE_ONE`,
  lose: `${STATE_KEY}/LOSE_ONE`,
  saving: `${STATE_KEY}/SAVING_ONE`,
  saved: `${STATE_KEY}/ONE_SAVED`,
  saving_error: `${STATE_KEY}/SAVING_ONE_ERROR`
}
