import React from 'react'

import ConfirmationModalHandler from '../utils/ConfirmationModalHandler'
import RemoveModal from '../userPostJob/UserPostJobCard/RemoveModal'
import ModalHandler from '../utils/ModalHandler'

/**
 * Display multi modal if published offer, confirmation only otherwise
 */
export default ({post, handleArchiveArticle, className="tag is-danger", actionerLabel=''}) => {
  if(post.status !== 'published') {
    return (
      <MyConfirmationModalHandler {...{
        post,
        className,
        actionerLabel,
        onConfirmation: () => handleArchiveArticle(post)
      }} />
    )
  }

  return (
    <ModalHandler
      actioner={(propsModal1) => (
        <MyConfirmationModalHandler {...{post, onConfirmation: propsModal1.onOpen, className, actionerLabel}} />
      )}
    />
  )
}

const MyConfirmationModalHandler = ({post, onConfirmation, className="tag is-danger", actionerLabel=''}) => (
  <ConfirmationModalHandler
    {...{actionerLabel, className, onConfirmation}}
    Modal={RemoveModal}
    modalChildren={(
      <>
        <h5 className='is-size-4'>
          Êtes-vous sûr de vouloir supprimer cette actualité ?
        </h5>
        <p>{post.title}</p>
      </>
    )}
  />
)