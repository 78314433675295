import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { makeSelectCollection as makeSelectRequestCollection } from '../modules/publicPostJobRequests/selectors'
import queryString from 'qs'
import { push } from 'connected-react-router'
import RegionMap from './utils/RegionMap'
import PostJobListPage from './postJob/PostJobListPage'
import PostCounter from './post/PostCounter'
import PostJobCard from './postJob/PostJobCard'
import PostList from './post/PostList'
import PostJobFilter from './postJob/PostJobFilter'
import filterPosts from '../modules/filterHelper'
import routes from '../routes'
import { getPostJobOfferUri } from '../router'
import filterSchema from './postJob/filterSchema'
import ControlledSavePostJobRequest from './postJob/ControlledSavePostJobRequest'

const PublicPostJobRequestListPage = ({ match, doFilter, filterValues = {}, results = [], location }) => {
  const filteredResults = filterPosts(results, filterValues, filterSchema)
  return (
    <PostJobListPage
      {...{match, title: 'Recherche de job'}}
      breadcrumbsMisc={[{ label: 'Candidatures' }]}
    >
      {!Object.keys(filterValues).length
        ? (
          <>
            <h4>Sélectionnez votre département :</h4>
            <RegionMap onClick={department => doFilter({ department })} />
          </>
        ) : (
          <>
            <PostJobFilter {...{filterSchema, filterValues}} handleSubmit={doFilter} location={location} type={'JOB_FILTER_REQUEST'} />
            <PostCounter {...{count: filteredResults.length}} />
            <PostList CardComponent={PostJobCard} ControlledSavePost={ControlledSavePostJobRequest} posts={filteredResults} location={location} getCardLinkProps={
              (post) => ({
                routeName: routes.PUBLIC_POST_REQUEST,
                to: getPostJobOfferUri(post.id)
              })
            } />
          </>
        )
      }
    </PostJobListPage>
  )
}

const mapStateToProps = (state, props) => ({
  results: makeSelectRequestCollection()(state, props),
  filterValues: queryString.parse(props.location.search.substr(1)),
})

const mapDispatchToProps = (dispatch) => ({
  doFilter: (filters) => dispatch(push({ search: queryString.stringify(filters) })),
  // handleSaveSearch: (filters, isAlert) => dispatch(postResearch(filters, 'JOB_FILTER_REQUEST', isAlert))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicPostJobRequestListPage))
