import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import routes from '../routes'

const PrivateRoute = ({ loading, user, ...props }) => {
  if (loading) {
    return 'loading...'
  }
  if (user) {
    return <Route {...props} />
  }
  return <Redirect to={routes.LOGIN} />
}

const mapStateToProps = ({ user }) => ({
  user: user.item,
  loading: user.loading,
  /*organismLoading: userOrganism.loading,
  offerLoading: userJobOffer.loading,
  requestLoading: userJobRequest.loading,
  offerSavedLoading: userJobOfferSaved.loading,
  requestSavedLoading: userJobRequestSaved.loading,
  researchLoading: userResearch.loading,
  ,
  organism: userOrganism.item*/
})

export default connect(mapStateToProps)(PrivateRoute)
