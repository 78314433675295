import React from 'react'
import { connect } from 'react-redux'
import routes from '../../routes.json'

import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import { getSectionUri } from '../../router'
import { makeSelectResourceFromSection } from '../../modules/category/selectors.js'

const SectionLink = ({ section, children, ...props }) => (
  <ControlledLoadableLink
    routeName={routes.SECTION}
    to={getSectionUri(props.category, section)}
    {...props}
  >
    {children}
  </ControlledLoadableLink>
)

const mapStateToProps = (state, props) => {
  return {
    category: makeSelectResourceFromSection()(state, props)
  }
}

export default connect(mapStateToProps)(SectionLink)
