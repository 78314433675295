import { USER_POST_JOB_OFFERS, USER_POST_JOB_OFFER } from './constants'
import { requestWithToken } from '../apiHelper'
import { toast } from 'react-toastify'
import { push } from "connected-react-router"
import { getUserPostJobOfferPreviewUri } from '../../router'
import routes from '../../routes.json'
const endpoint = 'user/offers';

export const getUserPostOffers = () =>
  (dispatch, getState) => {
    dispatch({ type: USER_POST_JOB_OFFERS.fetching })
    return requestWithToken({endpoint}, getState)
      .then(res => res.data)
      .then(({data}) => {
        dispatch({ type: USER_POST_JOB_OFFERS.fetched, data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_POST_JOB_OFFERS.fetching_error, error: error })
      })
  }

export const archivePostOffer = (post, archivingData) =>
  (dispatch, getState) => {
    dispatch({ type: USER_POST_JOB_OFFER.archiving })
    return requestWithToken({
      endpoint: `${endpoint}/${post.id}/archive`,
      method: 'PATCH',
      data: archivingData
    }, getState)
      .then(res => res.data)
      .then(({data}) => {
        toast.success(`L'annonce a été supprimée`)
        dispatch(push(routes.PROTECTED_AREA_JOB_OFFER_LIST))
        dispatch({ type: USER_POST_JOB_OFFER.archived, data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_POST_JOB_OFFER.archiving_error, error: error })
      })
  }

export const publishPostOffer = ({ id }) =>
  (dispatch, getState) => {
    dispatch({ type: USER_POST_JOB_OFFER.publishing })
    return requestWithToken({
      endpoint: `${endpoint}/${id}/publish`,
      method: 'PATCH'
    }, getState)
      .then(res => res.data)
      .then(({ data }) => {
        toast.success(`L'annonce est en attente de validation`)
        dispatch(push(routes.PROTECTED_AREA_JOB_OFFER_LIST))
        dispatch({ type: USER_POST_JOB_OFFER.published, data })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_POST_JOB_OFFER.publishing_error, error: error })
      })
  }

export const updatePostJobOffer = (post) =>
  (dispatch, getState) => {
    dispatch({ type: USER_POST_JOB_OFFER.updating })
    return requestWithToken({
      endpoint: `${endpoint}/${post.id}`,
      method: 'PUT',
      data: post
    }, getState)
      .then(res => res.data)
      .then((data) => {
        toast.success(`L'annonce a été modifiée`)
        dispatch({ type: USER_POST_JOB_OFFER.updated, data })
        // redirect to preview
        dispatch(push(getUserPostJobOfferPreviewUri(data.id)))
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_POST_JOB_OFFER.updating_error, error: error })
      })
  }

export const createPostJobOffer = (post) =>
  (dispatch, getState) => {
    dispatch({ type: USER_POST_JOB_OFFER.creating })
    return requestWithToken({
      endpoint: `${endpoint}`,
      method: 'POST',
      data: post
    }, getState)
      .then(res => res.data)
      .then((data) => {
        toast.success(`L'annonce a été crée`)
        dispatch({ type: USER_POST_JOB_OFFER.created, data })
        // redirect to preview
        dispatch(push(getUserPostJobOfferPreviewUri(data.id)))
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER_POST_JOB_OFFER.creating_error, error: error })
      })
  }