import React from 'react'

import ConfirmationModalHandler from '../utils/ConfirmationModalHandler'
import ModalPortal from '../utils/modal/ModalPortal'
import Modal from '../utils/modal/Modal'

const SetOwnerModal = ({ onConfirmation, onClose, children }) => (
  <ModalPortal>
    <Modal onClose={onClose}>
      <div className='box has-text-centered'>
        {children}
        <br />
        <div className='buttons is-centered are-medium'>
          <button
            className='button is-warning'
            type='button'
            onClick={() => {
              onConfirmation()
              onClose()
            }}
          >
            <span>Rendre propriétaire</span>
          </button>
          <button className='button' type='button' onClick={onClose}>
            <span>Annuler</span>
          </button>
        </div>
      </div>
    </Modal>
  </ModalPortal>
)

/**
 * Display multi modal if published offer, confirmation only otherwise
 */
export default ({ collaborator, handleSetOwner, className = 'tag is-danger', actionerLabel = '' }) => (
  <MyConfirmationModalHandler {...{
    collaborator,
    className,
    actionerLabel,
    onConfirmation: () => handleSetOwner(collaborator)
  }} />
)

const MyConfirmationModalHandler = ({ collaborator, onConfirmation, className = 'tag is-success', actionerLabel = '' }) => (
  <ConfirmationModalHandler
    {...{ actionerLabel, className, onConfirmation }}
    Modal={SetOwnerModal}
    icon='fas fa-gavel'
    modalChildren={(
      <>
        <h5 className='is-size-4'>
        Êtes-vous sûr de vouloir définir {collaborator.firstName} {collaborator.lastName} en tant que nouveau propriétaire ?
        Vous n'aurez alors plus ce pouvoir.
        </h5>
      </>
    )}
  />
)
