import React from 'react'

import classnames from 'classnames'
import { isObject } from 'lodash'

export default ({ className = null, value = [], choices = [], placeholder, allowEmpty = true, name, onChange, ...props }) => (
  <div className="control is-expanded">
    <div className={classnames('buttons', className)}>
      {getNormalizedChoices(choices).map(choice => {
        const isChecked = -1 !== value.indexOf(choice.value);
        return (
            <label
              key={'cbc-'+choice.label}
              htmlFor={'cbc-'+ choice.label}
              className={classnames("button", {['is-info']: isChecked})}
              style={{minWidth: '32%'}}
            >
              <input
                id={'cbc-'+choice.label}
                type="checkbox"
                checked={isChecked}
                onChange={e => handleOnChange(onChange, value, choice.value, !!e.target.checked)}
              /> <span style={{marginLeft:'4px'}}> {choice.label}</span>
            </label>
        )
      })}
    </div>
  </div>
)

const handleOnChange = (onChange, originalValue = [], name, checked = false) => {
  return onChange({target: {value: [
        ...originalValue.filter(inputValue => inputValue !== name),
        ...checked ? [name] : []
      ]}})
}

const getNormalizedChoices = choices => choices.map(choice => (!isObject(choice))
  ? {label: choice, value: choice}
  : choice
)