import React from 'react'
import classnames from 'classnames'
import routes from '../../routes.json'

import NavBar from '../NavBar'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'

export const PostJobNavBar = ({ match }) => {
  return (
    <NavBar withContents={false}>
      <li>
        <ControlledLoadableLink
          routeName={routes.JOB_FILTER_OFFER}
          to={routes.JOB_FILTER_OFFER}
          style={{backgroundColor: '#957DAD'}} // @todo
          className={classnames({
            active: match.path.substr(0, routes.JOB_FILTER_OFFER.length) === routes.JOB_FILTER_OFFER
          })}
        >
          Offres
        </ControlledLoadableLink>
      </li>
      <li>
        <ControlledLoadableLink
          routeName={routes.JOB_FILTER_REQUEST}
          to={routes.JOB_FILTER_REQUEST}
          style={{backgroundColor: '#D291BC'}} // @todo
          active={match.path.substr(0, routes.JOB_FILTER_REQUEST.length) === routes.JOB_FILTER_REQUEST}
        >
          Candidatures
        </ControlledLoadableLink>
      </li>
    </NavBar>
  )
}
export default PostJobNavBar
