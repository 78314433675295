import React from 'react'
import { connect } from 'react-redux'

import { makeSelectResource } from '../modules/publicPostJobRequests/selectors'

import PostJobItemPage from './postJob/PostJobItemPage'
import PostJobItem from './postJob/PostJobItem'
import { makeSelectPostJobRequestItemConfig } from '../modules/config/selectors'
import routes from '../routes'
import ControlledSavePostJobRequest from './postJob/ControlledSavePostJobRequest'

const PublicPostJobRequestItemPage = ({ match, location, post }) => {
  return (
    <PostJobItemPage
      {...{match, location}}
      post={post}
      breadcrumbsMisc={[
        { label: 'Candidatures', routeName: routes.JOB_FILTER_REQUEST, to: routes.JOB_FILTER_REQUEST+'?something=something' },
        { label: post.title }
      ]}
      ControlledSavePost={ControlledSavePostJobRequest}
    >
      <PostJobItem post={post} />
    </PostJobItemPage>
  );
}

const mapStateToProps = (state, props) => ({
  post: makeSelectResource()(state, props),
})

export default connect(mapStateToProps)(PublicPostJobRequestItemPage)
