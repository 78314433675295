import React from 'react'
import truncate from 'truncate-html'

import ArticleLink from './articles/ArticleLink'
import ContentLink from './contents/ContentLink'
import Image from './utils/Image'
import Slider from './utils/Slider'

export default ({ category, section, articles = [], contents = [] }) => {
  truncate.setup({ stripTags: true, length: 60, reserveLastWord: true })
  return (
    <Slider items={articles} background={section.background} renderSlide={item => (
      <ArticleLink key={item.id} article={item} category={category}>
        <h2 className='glide__title'>{item.title}</h2>
        <div className='glide__excerpt' dangerouslySetInnerHTML={{ __html: truncate(item.introduction) }} />
      </ArticleLink>
    )} />
  )
}
