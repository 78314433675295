
const env = window.env || {
  schemeProtocol: 'http://',
  mainDomainUri: ''
}

const origin = document.location.host
const isOnCurrentWebsite = (category) => category.domain
  ? origin === category.domain
  : origin === env.mainDomainUri

const getExternalCategoryUri = (category) => (env.schemeProtocol + category.domain)
const getMainSiteCategoryUri = category => '/' + category.slug
// const getMainSiteCategoryUri = category => getMainSiteUri() + '/' + category.slug

const getAbsoluteUri = (category) => category.domain ? getExternalCategoryUri(category) : getMainSiteCategoryUri(category)
const getRelativeUri = (category) => category.domain ? '' : '/' + category.slug

const getCategoryUriPart = category => isOnCurrentWebsite(category)
  ? getRelativeUri(category)
  : getAbsoluteUri(category)

export const getMainSiteUri = () => env.schemeProtocol + env.mainDomainUri

export const getCategoryUri = category => getCategoryUriPart(category) || '/'

export const getSectionUri = (category, section) => (section.type !== 'section') ? section.link : getCategoryUriPart(category) + '/' + section.slug

export const getContentUri = (category, section, content) => getCategoryUriPart(category) + '/' + section.slug + '/' + content.slug

export const getArticlesUri = category => getCategoryUriPart(category) + '/actualites'

export const getArticleUri = (category, article) => getCategoryUriPart(category) + '/actualites/' + article.slug

export const getPostJobOfferUri = (postId) => '/annonces/jobs/offres/' + postId
export const getPostJobRequestUri = (postId) => '/annonces/jobs/candidatures/' + postId

export const getUserPostJobOfferEditUri = (postId) => '/espace-perso/mes-annonces-emploi/offres/' + postId
export const getUserPostJobOfferPreviewUri = (postId) => getUserPostJobOfferEditUri(postId) +'/preview'

export const getUserArticleEditUri = (articleId) => '/espace-perso/mes-actualites/' + articleId
export const getUserArticlePreviewUri = (articleId) => getUserArticleEditUri(articleId) + '/preview'
