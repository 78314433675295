import React from 'react'
import classnames from 'classnames'

import './tabs/Tabs.scss'

export default ({ items = [] }) => (
  <div className="tabs is-centered is-large is-fullwidth">
    <ul>
      {items.map(({isActive = false, item}, k) => (
        <li key={k} className={classnames({["is-active"]: isActive})}>
          {item}
        </li>
      ))}
    </ul>
  </div>
)