import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { login } from '../modules/authentication/actions'
import Page from './Page'
import ControlledBreadcrumbs from './utils/ControlledBreadcrumbs'
import RegistrationHero from './login/RegistrationHero'

import './login/Login.scss'
import buildForm from './form/buildForm'
import Layout from './utils/Layout'
import getFormHandler from '../modules/formHelper'

const formConfig = [
  { name: 'username', placeholder: 'Identifiant' },
  { name: 'password', placeholder: 'Mot de passe', type: 'password' },
];

const LoginPage = ({ match, handleSubmit }) => {
  const formHandler = getFormHandler(formConfig);
  const [inputs, setInputs] = useState({
    // @todo empty
    username: {
      value: 'user@fixt.com'
    },
    password: {
      value: 'admin'
    }
  })
  const setInput = (name, value) => {
    formHandler.setInput(setInputs, name, value);
  }
  return (
    <Page match={match}>
      <Helmet>
        <title>Se connecter</title>
      </Helmet>

      {/* no navbar */}
      <ControlledBreadcrumbs {...{misc: [
          { label: 'Se connecter' },
        ]}} woSearchBar />

      <Layout 
        title="Vous devez vous connecter pour accéder à cet espace"
      >
        <div className="login-page columns is-multiline">
          <div className="column">
          <form className="columns is-multiline is-variable is-1">
            {buildForm(formConfig, inputs, setInput)}

            <div className="field column is-12">
              <div className="control">
                <button
                  className="button is-primary is-fullwidth"
                  onClick={e => {
                    e.preventDefault();
                    handleSubmit(inputs);
                  }}>
                  Se connecter
                </button>
              </div>
            </div>

          </form>
          </div>
          <div className="column is-12 has-text-right forgotten-box">
            <a href="#">Identifiant / Mot de passe oublié ?</a>
          </div>
          <div className="column is-12">
            <RegistrationHero />
          </div>

        </div>
      </Layout>
    </Page>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleSubmit: (inputs) => dispatch(login({ username: inputs.username.value, password: inputs.password.value }))
})

export default withRouter(connect(() => ({}), mapDispatchToProps)(LoginPage))
