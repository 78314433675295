import React from 'react'
import {
  makeSelectResource as makeSelectJobOfferSaved,
  makeSelectLoading as makeSelectJobOfferLoading,
  makeSelectSaving as makeSelectJobOfferSaving
} from '../../modules/userPostJobSavedOffers/selectors'
import { loseSavedPostJobOffer, savePostJobOffer } from '../../modules/userPostJobSavedOffers/actions'
import { push } from "connected-react-router"
import routes from '../../routes'
import { connect } from 'react-redux'
import { setRedirectTarget } from '../../modules/authenticationRedirect/actions'
import IconHeart from '../../components/Icon/IconHeart'

const Wrapper = ({children}) => (
  <div className="post-action action-mini">
    {children}
  </div>
)

const ControlledSavePostJobOffer = ({ post, saved, currentUser = null, loading = false, saving = false,
  redirectToLogin=() => {},
  toggleSave=() => {}
}) => {
  if(!currentUser) {
    return (
      <Wrapper>
          <IconHeart loading={false} liked={false} onClick={redirectToLogin} />
      </Wrapper>
    );
  }
  if(loading || saving) {
    return (
      <Wrapper>
        <span>
          <i className="fa fa-cog fa-spin fa-fw" />
        </span>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <IconHeart loading={true} liked={saved} onClick={toggleSave} />
    </Wrapper>
  );
}

const mapStateToProps = (state, props) => ({
  currentUser: state.user.item,
  saved: !! makeSelectJobOfferSaved()(state, props),
  loading: makeSelectJobOfferLoading()(state, props),
  saving: makeSelectJobOfferSaving()(state, props)
})

const mapDispatchToProps = dispatch => ({
  redirectToLogin: (pathname) => {
    dispatch(setRedirectTarget(pathname))
    dispatch(push(routes.LOGIN))
  },
  toggleSave: (id, saved = false) => dispatch(saved ? loseSavedPostJobOffer(id) : savePostJobOffer(id))
})

const mergeProps = (stateProps, {redirectToLogin, toggleSave, ...dispatchProps}, ownProps) => ({
  ...stateProps, ...dispatchProps, ...ownProps,
  redirectToLogin: () => redirectToLogin(ownProps.location.pathname + ownProps.location.search),
  toggleSave: () => toggleSave(ownProps.post.id, stateProps.saved),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ControlledSavePostJobOffer)
