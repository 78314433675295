import React from 'react'
import ProtectedAreaPage from '../protectedArea/ProtectedAreaPage'
import Layout from '../utils/Layout'

import UserPostJobTitleBar from './UserPostJobTitleBar'
import routes from '../../routes'

export default ({ match, children }) => (
  <ProtectedAreaPage
    match={match}
    title="Mes annonces job"
    breadcrumbsMisc={[
      { label: 'Mes annonces job', routeName: routes.PROTECTED_AREA_JOB_LIST, to: routes.PROTECTED_AREA_JOB_LIST },
    ]}
  >
    <Layout>
      <div className="columns is-multiline">
        <UserPostJobTitleBar match={match} />
        <div className="column">
          {children}
        </div>
      </div>
    </Layout>
  </ProtectedAreaPage>
)