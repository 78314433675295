// CATEGORY
import { createSelector } from 'reselect'

import { STATE_KEY } from './constants'
import { initialState } from './reducer'

import { makeSelectIsMain } from '../domain/selectors'

const selectNamespace = state => state[STATE_KEY] || initialState
const selectRouterMatch = (state, props) => props.match

export const makeSelectResource = () =>
  createSelector(
    [selectNamespace, selectRouterMatch, makeSelectIsMain()],
    ({ items }, { params: { category } }, isMain) => items.find(
      cat => isMain ? (cat.slug === category) : cat.domain === document.location.host)
  )

export const makeSelectCollection = () =>
  createSelector(
    [selectNamespace],
    ({ items }) => items
  )

export const makeSelectResourceFromSection = () =>
  createSelector(
    [selectNamespace, (state, { section }) => section],
    ({ items }, section) => items.find(item => item.id === section.category.id)
  )
