import React from 'react'
import * as yup from 'yup'
import LogoInput from '../../app/protectedAreaProfile/protectedAreaProfileForm/LogoInput'
import WithConsentTextField from '../../app/protectedAreaProfile/protectedAreaProfileForm/WithConsentTextField'

const withConsentValidation = {
  type: yup.object().shape({
    consent: yup.bool(),
    value: yup.string().required()
  }),
  required: true
}

export default [
  {
    group: 'default',
    label: 'Logo',
    name: 'logo',
    type: 'custom',
    Component: LogoInput,
    className: 'is-2',
    validation: {
      type: yup.object().shape({
        base64: yup.string(),
        publicPath: yup.string()
      }).nullable(true),
      required: true
    }
  },
  { group: 'default',
    label: "Nom de l'organisme (entreprise, association, etc...)",
    name: 'name',
    className: 'is-6',
    validation: { type: yup.string(), required: true }
  },
  { group: 'default',
    label: 'N°Siret / RCS',
    name: 'siret',
    className: 'is-4',
    validation: { type: yup.string(), required: true }
  },
  { group: 'default',
    label: 'Sigle',
    name: 'initials',
    validation: { type: yup.string(), required: true }
  },
  { group: 'default',
    label: "Mail de l'organisme",
    name: 'email',
    validation: { type: yup.string(), required: true }
  },
  {
    group: 'default',
    label: 'Site web',
    name: 'webSite',
    type: 'custom',
    validation: withConsentValidation,
    Component: (props) => (
      <WithConsentTextField
        {...props}
        type='email'
        consentLabel=" J'accepte de voir apparaître mon email sur le site public"
      />
    )
  },
  { group: 'coordinates',
    label: 'Adresse',
    name: 'address',
    validation: { required: true }
  },
  { group: 'coordinates',
    label: "Complément d'adress",
    name: 'additionalAddress',
    validation: { required: false }
  },
  { group: 'coordinates',
    label: 'Code postale',
    name: 'zipcode',
    className: 'is-4',
    validation: { required: true }
  },
  { group: 'coordinates',
    label: 'Ville',
    name: 'city',
    className: 'is-4',
    validation: { required: true }
  },
  { group: 'coordinates',
    label: 'Département',
    name: 'region',
    className: 'is-4',
    validation: { required: true }
  },
  { group: 'coordinates',
    label: 'Tél. principal',
    name: 'mainPhone',
    validation: { required: true }
  },
  { group: 'coordinates',
    label: 'Commentaire',
    name: 'commentMainPhone',
    placeholder: 'Exemple : joignable de 8h00 à 17h00'
  },
  { group: 'coordinates',
    label: 'Tél. secondaire',
    name: 'sparePhone'
  },
  { group: 'coordinates',
    label: 'Commentaire',
    name: 'commentSparePhone',
    placeholder: 'Exemple : joignable de 8h00 à 17h00'
  }
]
