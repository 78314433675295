import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { makeSelectUserProtectedAreaLinksConfig } from '../../modules/protectedArea/selectors'
import routes from '../../routes.json'
import NavBar from '../NavBar'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import Image from '../utils/Image'


const ProtectedAreaNavBar = ({ match, protectedAreaLinks }) => {
  return (
    <NavBar withContents={false}>
      {protectedAreaLinks.map(({ title, icon, color, routeName, args = {} }) => {
        return (
          <li key={title}>
            <ControlledLoadableLink
              style={{ backgroundColor: color }}
              routeName={routes[routeName]}
              to={routes[routeName]}
              args={args}
              className={classnames({
                active: match.path.substr(0, routes[routeName].length) === routes[routeName]
              })}
            >
              <Image
                image={icon}
               className='icon'
              />
              <h3>
                {title}
              </h3>
            </ControlledLoadableLink>
          </li>
        )
      })}
    </NavBar>
  )
}

const mapStateToProps = (state, props) => ({
  protectedAreaLinks: makeSelectUserProtectedAreaLinksConfig()(state, props)
})

export default connect(mapStateToProps)(ProtectedAreaNavBar)
