import '@babel/polyfill'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import configureStore, { history } from './configureStore'
import App from './App'

const store = configureStore(window.initialState || {})
//  (window.initialState || {}, history)
render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App history={history} />
    </ConnectedRouter>
    <div id='modal-root' />
  </Provider>,
  document.getElementById('app')
)
