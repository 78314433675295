import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ChooseAccountType from '../ChooseAccountType/ChooseAccountType'

import styles from './CreateAccount.scss'

class CreateAccount extends React.Component {
  constructor () {
    super()
    this.state = {
      accountType: null
    }
    this.handleNavigateToForm = this.handleNavigateToForm.bind(this)
  }
  componentWillMount () {
    window.scrollTo(0, 0)
    document.querySelector('title').innerHTML = this.props.isMainDomain
      ? (this.props.config.title + ' | Inscription')
      : (this.props.category.title + ' | Inscription')
    this.setState({ accountType: null })
  }
  componentDidUpdate () {
    if (this.props.user) {
      console.log("c'est push", this.props.user)
      this.props.history.push('/espace-perso')
    } else if (this.props.error) {
      console.log('invalide', this.props.error)
    }
  }
  handleNavigateToForm (accountType) {
    this.setState({ accountType: accountType })
  }

  render () {
    return (
      <div id={styles.createaccountbody}>
        <Header />
        <Layout title={'Création de votre compte'} background={{ publicPath: this.props.backgroundAccount }}>
          {
            this.state.accountType === null
              ? <ChooseAccountType click={this.handleNavigateToForm} />
              : (
                <div className={styles.accountcontainer}>
                  <div className={styles.accountbanner}>
                    <h2><i className={'fas fa-user'} />{`Je crée mon espace perso ${this.state.accountType === 'pro' ? '(compte professionnel)' : ''}`}</h2>
                  </div>
                  {
                    this.state.accountType === 'pro'
                      ? <ProfessionalAccountForm />
                      : <PersonalAccountForm />
                  }
                </div>
              )
          }
        </Layout>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const category = !state.isMainDomain && state.categories.find(c => c.domain === document.location.host)
  return {
    user: state.user,
    error: state.error,
    backgroundAccount: state.config.backgroundAccount,
    category: category,
    config: state.config,
    isMainDomain: state.isMainDomain
  }
}

export default withRouter(connect(mapStateToProps)(CreateAccount))
