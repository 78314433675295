import React from 'react'
import { Helmet } from 'react-helmet'
import ControlledBreadcrumbs from '../utils/ControlledBreadcrumbs'
import routes from '../../routes.json'
import Page from '../Page'
import PostJobNavBar from './PostJobNavBar'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import Layout from '../utils/Layout'

export default ({ match, title = 'Jobs', breadcrumbsMisc = [], children }) => (
  <Page match={match} className='page page-post'>
    <Helmet>
      <title>{title}</title>
    </Helmet>

    <PostJobNavBar match={match} />
    <ControlledBreadcrumbs {...{misc: [
        { label: 'Jobs', routeName: routes.JOB_FILTER_OFFER, to: routes.JOB_FILTER_OFFER },
        ...breadcrumbsMisc
      ]}} woSearchBar />

    <Layout title={(<>
      <span>{title}</span>
      <ControlledLoadableLink
        routeName={routes.PROTECTED_AREA_JOB_OFFER_CREATE}
        to={routes.PROTECTED_AREA_JOB_OFFER_CREATE}
        className="button is-success action"
      >
        +
        Déposer votre annonce
      </ControlledLoadableLink>
    </>)}>
      {children}
    </Layout>
  </Page>
)
