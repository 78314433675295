import React from 'react'
import { Circle, GoogleMap } from '@react-google-maps/api'

export default ({fullAddress: {coordinates: {lat, lon}, label, city}}) => {
  if(!lat || !lon) {
    return '';
  }
  const coordinates = {lat: parseFloat(lat), lng: parseFloat(lon)}

  return (
    <div id="localisation">
      <h4 className="is-size-4">Localisation</h4>
      <GoogleMap
        id='example-map'
        mapContainerStyle={{ height: '20rem', width: '100%' }}
        zoom={14}
        center={coordinates}
        options={{
          streetViewControl: false,
          zoomControl: false,
          draggable: false,
          mapTypeControl: false,
          fullscreenControl: false }}
      >
        <Circle
          center={coordinates}
          options={{
            strokeColor: '#175a9f',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#00b6bf',
            fillOpacity: 0.35,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            radius: 300,
            zIndex: 1
          }}
        />
      </GoogleMap>
      <div className="column is-12-tablet is-6-mobile">
        <div className="columns">
          <h5 className="column is-size-5">{`${label}, ${city}`}</h5>
        </div>
      </div>
    </div>
  )
}
