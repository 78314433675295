import React from 'react'

import './loadingOverlay/LoadingOverlay.scss'

export default () => (
  <div style={{
    position:'fixed',
    top: 0,
    left: 0,
    width:'100%',
    height:'100%',
    background:'rgba(33, 33, 33, 0.4)',
    zIndex:5}}
  >
    <section className="hero is-fullheight has-text-centered">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-size-1">
            <i className="fa fa-cog fa-spin fa-fw" style={{fontSize: '20vh'}}/>
            <br/>
            Chargement en cours ...
          </h1>
        </div>
      </div>
    </section>
  </div>
)