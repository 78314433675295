import React from 'react'

import routes from '../../routes.json'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'
import { getUserArticleEditUri } from '../../router'
import DeleteUserArticleProcess from './DeleteUserArticleProcess'

const StatusTag = ({ status }) => {
  switch (status) {
    case 'published':
      return <span className="tag is-success">Publiée</span>;
    case 'waiting':
      return <span className="tag is-info">En attente</span>;
  }
  return '';
}

export default ({ handleArchiveArticle, post }) => (
  <div className="actions buttons">
    <div className="tags are-medium has-addons">
      <StatusTag {...post} />
      {/* edit button */}
      {post.status === 'drafted' && (<>
        <ControlledLoadableLink
          className="tag is-warning"
          routeName={routes.PROTECTED_AREA_ARTICLE_EDIT}
          to={getUserArticleEditUri(post.id)}
        >
          <span style={{paddingRight: '0.5rem'}}>Brouillon</span>
          <i className='far fa-edit' />
        </ControlledLoadableLink>
        </>
      )}

      {/* delete modal */}
      <DeleteUserArticleProcess {...{ post, handleArchiveArticle }} />
    </div>
  </div>
)
