export const STATE_KEY = 'authentication'

export const LOCAL_STORAGE_KEY = 'authentication'

export const AUTHENTICATION = {
  fetching: `${STATE_KEY}/FETCHING`,
  fetched: `${STATE_KEY}/FETCHED`,
  fetching_error: `${STATE_KEY}/FETCHING_ERROR`,

  clean: '${STATE_KEY}/CLEAN',
  cleaned: '${STATE_KEY}/CLEANED',
};
