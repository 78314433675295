import React, { useState } from 'react'

const maxItems = 6

export default ({ posts = [], CardComponent, cardAction, getCardLinkProps = (postId) => ({}), ControlledSavePost, ...rest }) => {
  const [nbItems, setNbItems] = useState({
    max: maxItems
  })
  return (
    <div className="item-list columns is-multiline is-variable is-1">
      {posts.slice(0, nbItems.max).map(post => (
        <CardComponent key={'post'+ post.id} post={post} getLinkProps={getCardLinkProps} ControlledSavePost={ControlledSavePost} cardAction={cardAction} {...rest} />
      ))
      }

      {(nbItems.max < posts.length) && (
          <button className='button is-link is-fullwidth' onClick={() => setNbItems({ ...nbItems, max: nbItems.max + maxItems })}>
            voir plus
          </button>
      )}
    </div>
  )
}
