import { USER_ORGANISM, USER_ORGANISM_COLLABORATORS } from './constants'

// initial state
export const initialState = {
  loading: true,
  creating: false,
  deleting: false,
  invite_loading: false,
  error: false,
  item: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'NO_ORGANISM':
      return {
        ...state,
        item: null,
        loading: false
      }
    case USER_ORGANISM.fetching:
      return {
        ...state,
        loading: true
      }
    case USER_ORGANISM.fetched:
      return {
        ...state,
        item: action.data,
        loading: false
      }
    case USER_ORGANISM.fetching_error:
      return {
        ...state,
        loading: false,
        error: action.data.error
      }

    case USER_ORGANISM.creating:
      return {
        ...state,
        creating: true,
        error: false
      }
    case USER_ORGANISM.created:
      return {
        ...state,
        loading: false,
        creating: false,
        error: false,
        item: action.data
      }
    case USER_ORGANISM.creating_error:
      return {
        ...state,
        loading: false,
        creating: false,
        error: true
      }

    case USER_ORGANISM_COLLABORATORS.deleting:
      return {
        ...state,
        deleting: true,
        error: false
      }
    case USER_ORGANISM_COLLABORATORS.deleted:
      return {
        ...state,
        deleting: false,
        error: false,
        item: {
          ...state.item,
          users: state.item.users.filter(item => item.id !== action.payload)
        }
      }
    case USER_ORGANISM_COLLABORATORS.deleting_error:
      return {
        ...state,
        deleting: false,
        error: true
      }

    case USER_ORGANISM_COLLABORATORS.promoting:
      return {
        ...state,
        loading: true,
        error: false
      }
    case USER_ORGANISM_COLLABORATORS.promoted:
      return {
        ...state,
        loading: false,
        error: false,
        item: {
          ...state.item,
          users: action.data
        }
      }
    case USER_ORGANISM_COLLABORATORS.promoting_error:
      return {
        ...state,
        loading: false,
        error: true
      }

    case USER_ORGANISM_COLLABORATORS.invite_loading:
      return {
        ...state,
        invite_loading: true,
        error: false
      }
    case USER_ORGANISM_COLLABORATORS.invite_loaded:
      return {
        ...state,
        invite_loading: false,
        error: false
      }
    case USER_ORGANISM_COLLABORATORS.invite_loading_error:
      return {
        ...state,
        invite_loading: false,
        error: true
      }
  }
  return state
}
