import React from 'react'

export default ({title, item}) => (
  <div className="column is-12-tablet is-6-mobile">
    <div className="columns">
      <h5 className="column is-size-5">{title}</h5>
      <p className="column">
        {item}
      </p>
    </div>
  </div>
)