import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import Page from '../../../app/Page'
import { Helmet } from 'react-helmet'
import Layout from '../../../app/utils/Layout'
import CreateAccountForm from '../../../components/CreateAccountForm/CreateAccountForm'
import InfoUrl from '../../../components/InfoUrl/InfoUrl'
import './CreateAccount.scss'

const CreateProAccount = ({ match, info, location }) => {
  return (
    <Page match={match} className='page page-create-account'>
      <Helmet>
        <title>Création de votre compte (professionel)</title>
      </Helmet>
      <Layout title='Création de votre compte (professionel)' notification={(
        <div className="notification is-warning">
          <i className="fa fa-exclamation-triangle"/> Si vous souhaitez rejoindre un organisme, vous devez créer votre compte à partir d'un lien d'invitation.
          <br />
          <>Demandez à l'<b>administrateur</b> de votre organisme de vous envoyer une invitation sur votre adresse mail depuis <b>son</b> espace perso.</>
        </div>
      )}>
        <CreateAccountForm isProfessionalAccount location={location} />
      </Layout>
      <InfoUrl info={info} />
    </Page>
  )
}

const mapStateToProps = (state, props) => ({
  info: state.config.info
})

export default withRouter(connect(mapStateToProps)(CreateProAccount))
