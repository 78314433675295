// import reduceReducers from 'reduce-reducers'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import authentication from './modules/authentication/reducer'
import authentication_redirect from './modules/authenticationRedirect/reducer'
import category from './modules/category/reducer'
import section from './modules/section/reducer'
import content from './modules/content/reducer'
import article from './modules/article/reducer'
import user from './modules/user/reducer'
import config from './modules/config/reducer'
import domain from './modules/domain/reducer'
import partner from './modules/partner/reducer'
import forum from './modules/forum/reducer'
import post_job_categories from './modules/postJobCategories/reducer'
import public_post_job_offers from './modules/publicPostJobOffers/reducer'
import public_post_job_requests from './modules/publicPostJobRequests/reducer'
import user_post_job_offers from './modules/userPostJobOffers/reducer'
//import userJobRequest from './modules/userJobRequest/reducer'
import user_post_job_saved_offers from './modules/userPostJobSavedOffers/reducer'
import user_post_job_saved_requests from './modules/userPostJobSavedRequests/reducer'
import user_saved_searches from './modules/userSavedSearch/reducer'
import admin_post_job_offer from './modules/adminPostJobOffer/reducer'
//import userJobRequestSaved from './modules/userJobRequestSaved/reducer'
import userOrganism from './modules/userOrganism/reducer'
import user_article from './modules/userArticle/reducer'
//import userResearch from './modules/userResearch/reducer'
import query from './modules/query/reducer'
import customRouter from './modules/router/reducer'


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  authentication,
  authentication_redirect,
  category,
  section,
  content,
  article,
  user,
  config,
  domain,
  partner,
  forum,
  customRouter,
  post_job_categories,
  public_post_job_offers,
  public_post_job_requests,
  admin_post_job_offer,
  user_post_job_offers,
  //userJobRequest,
  user_post_job_saved_offers,
  user_post_job_saved_requests,
  user_saved_searches,
  //userJobRequestSaved,
  userOrganism,
  user_article,
  //userResearch,
  query
})
export default createRootReducer
