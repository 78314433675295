import React from 'react'
import { connect } from 'react-redux'

import { makeSelectResource as makeSelectCategoryResource } from '../modules/category/selectors'
import {
  makeSelectCollectionForCategory as makeSelectSectionCollectionForCategory,
  makeSelectResource as makeSelectSectionResource
} from '../modules/section/selectors'
import {
  makeSelectCollectionForSection as makeSelectContentCollectionForSection,
  makeSelectResource as makeSelectContentResource
} from '../modules/content/selectors'

import { Helmet } from 'react-helmet'
import ControlledBreadcrumbs from './utils/ControlledBreadcrumbs'

import 'moment/locale/fr'
import ControlledNavBar from './utils/ControlledNavBar'
import Page from './Page'
import Layout from './utils/Layout'
import SearchBar from './utils/SearchBar'
import ContentText from './contents/ContentText'
import ContentFlora from './contents/ContentFlora'
import InfoUrl from '../components/InfoUrl/InfoUrl'

const ContentPage = ({ match, category, section, content, location }) => (
  <Page match={match} className='page page-content'>
    <Helmet>
      <title>{`${content.title} - ${section.title} - ${category.title}`}</title>
    </Helmet>

    <ControlledNavBar selectedCategory={category} selectedSection={section} selectedContent={content} match={match} />

    <ControlledBreadcrumbs {...{ category, section, content }} />
    <SearchBar />

    <Layout title={content.title} background={content.background}>
      {content.contentBlocks.map(contentBlock => (
        <div key={contentBlock.id}>
          <ContentText {...contentBlock} />
          {contentBlock.type === 'flora'
            && <ContentFlora key={contentBlock.id +'-flora'} query={contentBlock.query} location={location} />}
        </div>
      ))}
    </Layout>
    <InfoUrl info={category.footer} />
  </Page>
)

const mapStateToProps = (state, props) => ({
  category: makeSelectCategoryResource()(state, props),
  section: makeSelectSectionResource()(state, props),
  content: makeSelectContentResource()(state, props),

  sections: makeSelectSectionCollectionForCategory()(state, props),
  contents: makeSelectContentCollectionForSection()(state, props)
})

export default connect(mapStateToProps)(ContentPage)
