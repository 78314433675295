import React from 'react'
import { connect } from 'react-redux'

import { Helmet } from 'react-helmet'
import ControlledNavBar from '../utils/ControlledNavBar'
import ControlledBreadcrumbs from '../utils/ControlledBreadcrumbs'
import { makeSelectResource as makeSelectCategoryResource } from '../../modules/category/selectors'
import Page from '../Page'
import Layout from '../utils/Layout'
import ContentText from '../contents/ContentText'
import SearchBar from '../utils/SearchBar'

const LegalNotice = ({ match, category = null, text }) => (
  <Page match={match}>
    <div className='page page-content'>
      <Helmet>
        <title>Mentions légales</title>
      </Helmet>

      {category && <ControlledNavBar selectedCategory={category} match={match} />}

      <ControlledBreadcrumbs {...{ category, misc: [{ label: 'Mentions légales' }] }} />
      <SearchBar />

      <Layout title='Mention légales'>
        <ContentText text={text} />
        {/* <div className='btnRetour'>
          <GoBack>Retour</GoBack>
        </div> */}
      </Layout>
    </div>
  </Page>
)

const mapStateToProps = (state, props) => ({
  text: state.config.legalNotice,
  category: makeSelectCategoryResource()(state, props)
})

export default connect(mapStateToProps)(LegalNotice)
