import { USER_POST_JOB_SAVED_OFFERS, USER_POST_JOB_SAVED_OFFER } from './constants'

export const initialState = {
  loading: true,
  saving: false,
  error: false,
  items: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_POST_JOB_SAVED_OFFERS.fetching:
      return {
        ...state,
        loading: true,
        error: false
      }
    case USER_POST_JOB_SAVED_OFFERS.fetched:
      return {
        ...state,
        items: action.data,
        loading: false,
        error: false
      }
    case USER_POST_JOB_SAVED_OFFERS.fetching_error:
      return {
        ...state,
        loading: false,
        error: true
      }

    case USER_POST_JOB_SAVED_OFFER.save:
      return {
        ...state,
        items: [...state.items, action.payload]
      }
    case USER_POST_JOB_SAVED_OFFER.lose:
      return {
        ...state,
        items: state.items.filter(item => item !== action.payload)
      }
    case USER_POST_JOB_SAVED_OFFER.saving:
      return {
        ...state,
        saving: true
      }
    case USER_POST_JOB_SAVED_OFFER.saved:
      return {
        ...state,
        saving: false
      }
    case USER_POST_JOB_SAVED_OFFER.saving_error:
      return {
        ...state,
        saving: false,
        error: true
      }
  }
  return state
}
