import React from 'react'
import { connect } from 'react-redux'

import { changeLocation } from '../../modules/router/actions'
import LoadableLink from './LoadableLink'

const ControlledLoadableLink = ({
  loadingUri,
  to,
  routeName,
  handleChangeLocation,
  args = {},
  className = '',
  style = {},
  ...props
}) => {
  // handle changing domain name
  if (to.substr(0, 4) === 'http') {
    return (
      <a className={className} style={style} target='_blank' href={to}>
        {props.children}
      </a>
    )
  }
  return (
    <LoadableLink
      {...props}
      className={className}
      style={style}
      to={to}
      handleClick={e => {
        e.preventDefault()
        handleChangeLocation(routeName, to, args)
      }}
      loading={to === loadingUri}
    />
  )
}

export default connect(
  ({ customRouter }) => ({
    loadingUri: customRouter.loadingUri
  }),
  dispatch => ({
    handleChangeLocation: (routeName, targetPath, args) => dispatch(changeLocation(routeName, targetPath, args))
  })
)(ControlledLoadableLink)
