import React from 'react'

export default ({title, items}) => (
  <div className="column is-12-tablet is-6-mobile">
    <div className="columns">
      <h5 className="column is-size-5">{title}</h5>
      <div className="column">
        {items.map((item, index) => (
          <span key={'i-'+index}>
            {item}
            {(index !== (items.length-1)) ? ', ':''}
          </span>
        ))}
      </div>
    </div>
  </div>
)