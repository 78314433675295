import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import routes from '../../routes.json'
import ControlledLoadableLink from '../utils/ControlledLoadableLink'

import PostCard from '../post/PostCard'
import { getArticleUri } from '../../router'
import PostArticleCardActions from '../ProtectedAreaArticle/PostArticleCardActions'

export default ({ post, handleArchiveArticle, cardAction = true}) => {
  const {title, introduction, category, section } = post;
  const parent = section || category;

  const linkProps = {
    routeName: routes.ARTICLE,
    to: getArticleUri(category, post)
  };

  return (
    <PostCard {...{
      linkProps,
      title: <>{parent.title}</>,
      titleColor: parent.color,
      thumbPicture: post.thumbnail || post.background
    }}>
      <div className="column is-12">
        <ControlledLoadableLink {...linkProps}>
          <h2>{title}</h2>
        </ControlledLoadableLink>
      </div>
      <div className="column is-paddingless">
        <p dangerouslySetInnerHTML={{ __html: introduction }} />
      </div>

      {cardAction ? <PostArticleCardActions {...{ post, handleArchiveArticle }} /> : ''}      
    </PostCard>
  );
}
