import React from 'react'

import Breadcrumbs from './Breadcrumbs'
import routes from '../../routes'

import { getCategoryUri, getContentUri, getSectionUri } from '../../router'

export default ({ category = null, section = null, content = null, misc = [], woSearchBar = false }) => {
  const items = [
    { label: 'Accueil', to: '/', routeName: (category && category.domain) ? routes.CATEGORY : routes.HOME }
  ]
  if (category && !category.domain) {
    items.push({ label: category.title, to: getCategoryUri(category), routeName: routes.CATEGORY })
  }
  if (section) {
    items.push({ label: section.title, to: getSectionUri(category, section), routeName: routes.SECTION })
  }
  if (content) {
    items.push({ label: content.title, to: getContentUri(category, section, content), routeName: routes.CONTENT })
  }

  return <Breadcrumbs {...{ items: items.concat(misc) }} woSearchBar={woSearchBar} />
}
