import { USER } from './constants'
import request, { requestWithToken } from '../apiHelper'

import { fetchSavedPostJobOffers } from '../userPostJobSavedOffers/actions'
import { fetchSavedPostJobRequests } from '../userPostJobSavedRequests/actions'
import { toast } from 'react-toastify'
import { push } from "connected-react-router"
import routes from '../../routes'
import { getUserPostOffers } from '../userPostJobOffers/actions'
import { fetchUserSearches } from '../userSavedSearch/actions'
import { getUserOrganism } from '../userOrganism/actions'
import { AUTHENTICATION } from '../authentication/constants'
import { getUserArticles } from '../userArticle/actions'

const endpoint = 'user';

export function getUser(onSuccess = () => {}, onFailure = () => {}) {
  return (dispatch, getState) => {
    dispatch({ type: USER.fetching })
    return requestWithToken({endpoint}, getState)
      .then(res => res.data)
      .then(data => {
        dispatch({ type: USER.fetched, data })
        if (data.organism) {
          dispatch(getUserOrganism(data.organism.id))
        } else {
          dispatch({ type: 'NO_ORGANISM' })
        }

        dispatch(getUserArticles())
        dispatch(getUserPostOffers())
        // dispatch(getUserPostRequests())

        dispatch(fetchSavedPostJobOffers())
        dispatch(fetchSavedPostJobRequests())

        dispatch(fetchUserSearches())

        onSuccess()
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER.fetching_error, error: error })

        onFailure()
      })
  }
}

export function createUser (user) {
  return (dispatch) => {
    dispatch({ type: USER.creating })

    return request({
      endpoint,
      method: 'POST',
      data: user
    })
      .then(res => res.data)
      .then(data => {
        toast.success(`Votre profil à été crée`)
        dispatch({ type: USER.created, data })
        dispatch(push(routes.LOGIN))
      })
      .catch(error => {
        console.log(error)
        toast.error(`Impossible de créer votre compte`)
        dispatch({ type: USER.creating_error, error: error })
      })
  }
}

export function createUserPro (user, organism) {
  user.organism = organism
  return (dispatch) => {
    dispatch({ type: USER.creating })

    return request({
      endpoint,
      method: 'POST',
      data: user
    })
      .then(res => res.data)
      .then(data => {
        toast.success(`Votre profil à été crée`)
        dispatch({ type: USER.created, data })
        dispatch(push(routes.LOGIN))
      })
      .catch(error => {
        console.log(error)
        toast.error(`Impossible de créer votre compte`)
        dispatch({ type: USER.creating_error, error: error })
      })
  }
}

export function updateUser({id, ...user}) {
  return (dispatch, getState) => {
    dispatch({ type: USER.updating })

    return requestWithToken({
        endpoint,
        method: 'PUT',
        data: user
      }, getState)
      .then(res => res.data)
      .then(data => {
        toast.success(`Votre profil à été mis à jour`)
        dispatch({ type: USER.updated, data })
        dispatch({ type: AUTHENTICATION.fetched, data })
        dispatch(push(routes.PROTECTED_AREA_PROFILE))
        return data
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER.updating_error, error: error })
      })
  }
}

export function updateUserPassword (passwordForm) {
  return (dispatch, getState) => {
    dispatch({ type: USER.updating })

    return requestWithToken({
      endpoint,
      method: 'PATCH',
      data: passwordForm
    }, getState)
      .then(res => res.data)
      .then(data => {
        toast.success(`Votre mot de passe à été mis à jour`)
        dispatch({ type: USER.updated, data })
        dispatch(push(routes.PROTECTED_AREA_PROFILE))
        return data
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: USER.updating_error, error: error })
      })
  }
}
