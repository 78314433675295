import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
//import { postPostSaved, deletePostSaved, patchPostArchived } from '../../actions'
import routes from '../../routes.json'
import ControlledLoadableLink from '../../app/utils/ControlledLoadableLink'
import classnames from 'classnames'
import { getPostJobUri } from '../../router'

import './postCard/PostCard.scss'
import backgroundImageHelper from '../utils/backgroundImageHelper'
import PostJobTitle from '../postJob/PostJobTitle'

export default ({ children, title, titleColor = null, thumbPicture = {}, linkProps = {}, className="" }) => {
  return (
    <div className="post-card column is-6-tablet is-4-desktop">
      <div className="box">
        <div className="columns is-multiline is-mobile">
          <ControlledLoadableLink {...linkProps}>
            <h4 className="tag section is-medium is-dark" style={titleColor ? { backgroundColor: titleColor } : {}}>
              {title}
            </h4>
          </ControlledLoadableLink>
          <div className="thumb">
            <ControlledLoadableLink {...linkProps}>
              <div className="image" style={backgroundImageHelper(thumbPicture)} />
            </ControlledLoadableLink>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}
/*
export default ({ post, handlePostSave, ...props }) => {
  const {id, title, category, subCategory, jobPicture, city, region, created, published} = post;
  return (
    <div className={classnames('post-card column is-6-tablet is-4-desktop')}>
      <div className="box">
        <div className="columns is-multiline is-mobile">
      <PostLink id={id}>
        <h4 className="section" style={{ backgroundColor: '#000' }}>
          {category} / {subCategory}
        </h4>
      </PostLink>
      <div className="thumb">
        <PostLink id={id}>
          <div className="image" style={backgroundImageHelper(jobPicture)} />
        </PostLink>
      </div>

          <div className="column is-12">
            <PostLink id={id}>
              <h2>{title}</h2>
            </PostLink>
          </div>
          <div className="column is-paddingless">
            <p className="location">
              <i className="fas fa-lg fa-map-marker" /> {city} {region && <span>({region})</span>}
            </p>
          </div>
          <div className="column is-paddingless">
            <p className="date">
              <i className="fas fa-lg fa-calendar" /> {moment(published || created).format('Do MMMM YYYY')}
            </p>
          </div>
          <div className="actionButtons">
            {/*!isForManage && <IconHeart liked={isSaved} toggleLike={(event) => toggleLike(event)} />}
            {isForManage &&
            <ModalHandler
              actioner={(propsModalAskForJob) => (
                <ModalHandler
                  actioner={({ onOpen }) => (
                    <Icon action={(event) => { event.stopPropagation(); onOpen() }} icon='fas fa-trash fa-2x' />
                  )}
                  modal={props => <ModalRemove {...props} onConfirmation={propsModalAskForJob.onOpen} subject='cette offre' />}
                />
              )}
              modal={props2 => <ModalAskForJob {...props2} onConfirmation={(isJobFullFilled, date = null) => { handleDeletePost(post.id, isJobFullFilled, date) }} />}
            />
            }
            {isForManage && post.status === 'draft' &&
            <ControlledLoadableLink
              routeName={routes.PROTECTED_AREA_JOB_POST_EDIT}
              to={`/espace-perso/mes-annonces-emploi/modifier-une-offre/${post.id}`}
              args={{ postId: post.id }}
            >
              <button><i className='far fa-edit fa-2x' /></button>
            </ControlledLoadableLink>
            *}
          </div>
        </div>
      </div>
    </div>
  )
}
*/