import React from 'react'
import classnames from 'classnames'

export default ({ withBreadcrumb, children, style = {} }) => (
  <li className='glide__slide' style={style}>
    <div className={classnames('glide__overlay', { withBreadcrumb })}>
      {children}
    </div>
  </li>
)
