import { USER_SAVED_SEARCH, USER_SAVED_SEARCHES } from './constants'

export const initialState = {
  loading: true,
  saving: false,
  creating: false,
  deleting: false,
  error: false,
  items: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SAVED_SEARCHES.fetching:
      return {
        ...state,
        loading: true,
        error: false
      }
    case USER_SAVED_SEARCHES.fetched:
      return {
        ...state,
        loading: false,
        error: false,
        items: action.data
      }
    case USER_SAVED_SEARCHES.fetching_error:
      return {
        ...state,
        loading: false,
        error: true
      }

    case USER_SAVED_SEARCH.creating:
      return {
        ...state,
        creating: true,
        error: false
      }
    case USER_SAVED_SEARCH.created:
      return {
        ...state,
        creating: false,
        error: false,
        items: state.items.concat([action.data])
      }
    case USER_SAVED_SEARCH.creating_error:
      return {
        ...state,
        creating: false,
        error: true
      }

    case USER_SAVED_SEARCH.updating:
      return {
        ...state,
        updating: true,
        error: false
      }
    case USER_SAVED_SEARCH.updated:
      return {
        ...state,
        updating: false,
        error: false,
        items: state.items.map(item => item.id === action.data.id
          ? action.data : item)
      }
    case USER_SAVED_SEARCH.updating_error:
      return {
        ...state,
        updating: false,
        error: true
      }

    case USER_SAVED_SEARCH.deleting:
      return {
        ...state,
        deleting: true,
        error: false
      }
    case USER_SAVED_SEARCH.deleted:
      return {
        ...state,
        deleting: false,
        error: false,
        items: state.items.filter(item => item.id !== action.payload)
      }
    case USER_SAVED_SEARCH.deleting_error:
      return {
        ...state,
        deleting: false,
        error: true
      }
  }
  return state
}
