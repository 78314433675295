import React from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import classnames from 'classnames'

export default ({className = null, allowEmpty, choices, value, ...props}) => (
  <div className="control has-icons-left">
    <input
      {...props}
      className={classnames('input', className)}
      type="date"
      value={value && moment(value).format("Y-MM-DD")}
    />
    <span className={classnames('icon is-small is-left')}>
      <i className="fas fa-calendar" />
    </span>
  </div>
)